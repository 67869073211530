import { render, staticRenderFns } from "./MvpFreeUserRegistration.vue?vue&type=template&id=63817e23&scoped=true"
import script from "./MvpFreeUserRegistration.vue?vue&type=script&lang=js"
export * from "./MvpFreeUserRegistration.vue?vue&type=script&lang=js"
import style0 from "./MvpFreeUserRegistration.vue?vue&type=style&index=0&id=63817e23&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63817e23",
  null
  
)

export default component.exports