<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <PasswordField
          @input="showConfirmBox=false"
          :field-name="$t('password.label')"
          :placeholder="$t('reset.password.complete.password.placeholder')"
          :disabled="disabled"/>
      </div>
      <div>
        <Confirmation
          v-if="showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="disabled || submitting"
          :loading="submitting">
          {{ buttonText || $t('reset.password.complete.button.text') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import PasswordField from '../elements/PasswordField';
import LoadingButton from '../elements/LoadingButton';
import Confirmation from '../elements/Confirmation';
import { ajaxForm, emailSync, autoLogin, formDescriptionHander } from '@mixins';

export default {
  name: 'ncp-password-reset',
  components: {
    PasswordField,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm, emailSync, autoLogin, formDescriptionHander],
  props: {
    description: {
      type: String,
      required: false,
      default: () => ''
    },
    buttonText: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  computed: {
    confirmMsgs: function () {
      return {
        400: {
          title: this.$t('ncp.reset.password.complete.400.title'),
          message: this.$t('ncp.reset.password.complete.400.description')
        }
      };
    },
    token: function () {
      return this.$route.params.token;
    }
  },
  async mounted() {
    await this.validateToken();
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.$t('reset.password');
      this.formSubHeading = this.$t('reset.password.complete.description');
    },
    async validateToken() {
      this.showLoader();
      try {
        let res = await this.subscriptionClient.validateToken(this.token);
        res = res.data;
        this.email = res.username;
      } catch (e) {
        console.error(e);
        this.disabled = true;
        this.success = false;
        this.showConfirmBox = true;
        this.serverResponseCode = e.response && e.response.status ? e.response.status : 500;
      }
      this.hideLoader();
    },
    async successWorkflow() {
      this.showConfirmBox = false;
      return this.autoLogin(true, true);
    },
    errorWorkflow() {
    },
    submit() {
      const app = this;
      return app.submitForm(
        () => app.subscriptionClient.resetPasswordComplete(
          this.token,
          this.password,
        ),
        true
      );
    }
  }
};
</script>
