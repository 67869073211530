<template>
  <div>
    <iframe
      id="dn-footer-iframe"
      :src="standaloneFooterUrl"
      class="footer__iframe"
      width="100%"
      seamless="seamless"
      frameborder="0"
      scrolling="no"
      height="553px"></iframe>
  </div>
</template>
<script>

export default {
  name: 'dn-footer',
  data() {
    return {
      standaloneFooterUrl: dconf.DN_STANDALONE_FOOTER_URL
    };
  },
  mounted() {
    if (appEnv !== 'dev') {
      this.standaloneFooterUrl = `${this.publication.zephrCDN}${this.standaloneFooterUrl}`;
    }
    console.log(`Footer url: ${this.standaloneFooterUrl}`);
    const app = this;
    function eventHandler(event) {
      if (event && event.data) {
        const data = event.data;
        if (data['footer-height'] !== undefined) {
          app.setHeight(data);
        }
      }
    }

    if (window.addEventListener) {
      window.addEventListener('message', eventHandler, false);
    } else {
      window.attachEvent('onmessage', eventHandler);
    }
  },
  methods: {
    setHeight(data) {
      const height = data['footer-height'];
      const footer = document.getElementById('dn-footer-iframe');
      footer.setAttribute('height', height);
    }
  }
};
</script>
