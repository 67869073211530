<template>
  <div class="sso">
    <transition
      name="expand"
      mode="out-in">
      <component
        :is="curComponent"
        @ssoLogout="curComponent=ssoLogout"
        @ssoLogin="curComponent=ssoLogin"/>
    </transition>
  </div>
</template>

<script>

import SsoLogin from '@components/sso/SSOLogin';
import SsoAutoLogin from '@components/sso/SSOAutoLogin';
import SsoLogout from '@components/sso/SSOLogout';
import SsoConcurrencyLimitExceeded from '@components/sso/SSOConcurrencyLimitExceeded';
import { formDescriptionHander, sso } from '@mixins';

export default {
  name: 'sso',
  // will use component dynamically
  // eslint-disable-next-line vue/no-unused-components
  components: { SsoLogin, SsoAutoLogin, SsoLogout, SsoConcurrencyLimitExceeded },
  mixins: [sso, formDescriptionHander],
  data() {
    return {
      ssoLogin: SsoLogin,
      ssoAutoLogin: SsoAutoLogin,
      ssoLogout: SsoLogout,
      ssoConcurrencyLimitExceeded: SsoConcurrencyLimitExceeded,
      curComponent: undefined,
      formHeading: '',
      formSubHeading: ''
    };
  },
  computed: {
    isSsoDataFetchedAndHasConcurrencyLimitExceeded() {
      return {
        isSsoDataFetched: this.isSsoDataFetched,
        hasConcurrencyLimitExceeded: this.hasConcurrencyLimitExceeded
      };
    }
  },
  watch: {
    isSsoDataFetchedAndHasConcurrencyLimitExceeded: {
      immediate: true,
      handler(val) {
        const isSsoDataFetched = val.isSsoDataFetched;
        const hasConcurrencyLimitExceeded = val.hasConcurrencyLimitExceeded;
        if (hasConcurrencyLimitExceeded) {
          this.curComponent = this.ssoConcurrencyLimitExceeded;
          return;
        }
        if (!isSsoDataFetched) {
          return;
        }
        const pubSsoStatusKey = this.ssoPopupShowedKey;
        if (this.ssoMeta[pubSsoStatusKey] !== true) {
          this.curComponent = this.ssoAutoLogin;
        } else {
          this.curComponent = this.ssoLogin;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.sso {
  & > * {
    height: 100%;
  }
  &-heading {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  &-sub-heading {
    font-size: 14px;
    line-height: 20px;
  }
}

</style>
