const adobeAnalytics = {
  methods: {
    /**
     * Helper function to trigger adobe analyics event
     *
     * @param  {object} data - Data that will be pushed to dataLayer.events
     */
    pushAdobeEvent(data) {
      // Make sure that data layer and events are present
      window.nhstDataLayer = window.nhstDataLayer || {};
      window.nhstDataLayer.events = window.nhstDataLayer.events || [];

      window.nhstDataLayer.events.push(data);
    },
  },
};

export { adobeAnalytics };
