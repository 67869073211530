<template>
  <div
    class="choices">
    <RadioButton
      v-bind="$attrs"
      v-for="choice in choices"
      :key="choice.value"
      :value="choice.value"
      :label="choice.text"
      v-model="inputVal"/>
  </div>
</template>

<script>

import RadioButton from '@components/elements/RadioButton';
export default {
  name: 'radio-buttons',
  components: { RadioButton },
  props: {
    // choices will be in form of [{text: '', value: ''}]
    choices: {
      type: Array,
      default: () => [],
      required: false
    },
    value: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@assets/scss/variables.scss";
.choices {
  margin-bottom: $input-margin-bottom;
}
.choice {
  display: block;
  position: relative;
  margin: 5px 0 0;

  input[type='radio'] {
    display: none;
  }

  label {
    font-weight: normal;
  }

  label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 4px;
    margin: 0 4px 0 0;
    width: 16px;
    height: 16px;
    border-radius: 9px;
    border: 1px solid #336633;
    background-color: transparent;
  }

  input[type=radio]:checked + label:after {
    border-radius: 9px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    left: 3px;
    content: " ";
    display: block;
    background: #336633;
  }
}

</style>
