import Vue from 'vue';
import Router from 'vue-router';
import {
  HomePage,
  LoginPage,
  LogoutPage,
  SSOPage,
  CorporateSSOLoginPage,
  RegistrationPage,
  FreeUserRegistrationPage,
  FreeUserRegistrationMvpPage,
  ExistingUserRegistrationPage,
  AcceptProductSharePage,
  CheckUsernamePage,
  VerifyUsernamePage,
  CorporateRegistrationPage,
  IpRegistrationPage,
  ActivateSubscriptionPage,
  SubscriptionOutcomePage,
  ResetPasswordPage,
  NcpPasswordResetPage,
  ZephrPasswordResetPage,
} from '@pages';
import * as routes from '@router/constants';
import TestPage from '@pages/TestPage';

Vue.use(Router);

export function createRouter() {
  return new Router({
    base: dconf.BASE_PATH,
    mode: 'history',
    scrollBehavior() {
      if (isServer) {
        return {
          x: 0,
          y: 0
        };
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            x: 0,
            y: 0
          });
        }, 100);
      });
    },
    routes: [
      {
        name: routes.HOME,
        path: routes.HOME_PATH,
        component: HomePage,
      },
      {
        name: routes.LOGIN,
        path: routes.LOGIN_PATH,
        component: LoginPage,
        props: true
      },
      {
        name: routes.LOGOUT,
        path: routes.LOGOUT_PATH,
        component: LogoutPage,
        props: true
      },
      {
        name: routes.SSO,
        path: routes.SSO_PATH,
        component: SSOPage,
        props: true
      },
      {
        name: routes.CORPORATE_SSO_LOGIN,
        path: routes.CORPORATE_SSO_LOGIN_PATH,
        component: CorporateSSOLoginPage,
        props: true
      },
      {
        name: routes.REGISTRATION,
        path: routes.REGISTRATION_PATH,
        component: RegistrationPage
      },
      {
        name: routes.FREE_USER_REGISTRATION,
        path: routes.FREE_USER_REGISTRATION_PATH,
        component: FreeUserRegistrationPage
      },
      {
        name: routes.FREE_USER_REGISTRATION_MVP,
        path: routes.FREE_USER_REGISTRATION_MVP_PATH,
        component: FreeUserRegistrationMvpPage
      },
      {
        name: routes.EXISTING_USER_REGISTRATION,
        path: routes.EXISTING_USER_REGISTRATION_PATH,
        component: ExistingUserRegistrationPage
      },
      {
        name: routes.ACCEPT_PRODUCT_SHARE,
        path: routes.ACCEPT_PRODUCT_SHARE_PATH,
        component: AcceptProductSharePage
      },
      {
        name: routes.USER_REGISTRATION_CHECK_USERNAME,
        path: routes.USER_REGISTRATION_CHECK_USERNAME_PATH,
        component: CheckUsernamePage
      },
      {
        name: routes.USER_REGISTRATION_VERIFY_USERNAME,
        path: routes.USER_REGISTRATION_VERIFY_USERNAME_PATH,
        component: VerifyUsernamePage
      },
      {
        name: routes.IP_REGISTRATION,
        path: routes.IP_REGISTRATION_PATH,
        component: IpRegistrationPage
      },
      // corporate registration path should be after ip registration path
      // otherwise corporate/:slug will get priority over corporate/ip
      {
        name: routes.CORPORATE_REGISTRATON,
        path: routes.CORPORATE_REGISTRATION_PATH,
        component: CorporateRegistrationPage
      },
      {
        name: routes.PASSWORD_RESET,
        path: routes.PASSWORD_RESET_PATH,
        component: ResetPasswordPage,
        props: true,
      },
      {
        name: routes.SET_PASSWORD,
        path: routes.SET_PASSWORD_PATH,
        component: ResetPasswordPage,
        props: true,
      },
      {
        name: routes.NCP_PASSWORD_RESET,
        path: routes.NCP_PASSWORD_RESET_PATH,
        component: NcpPasswordResetPage,
        props: true,
      },
      {
        name: routes.ZEPHR_PASSWORD_RESET,
        path: routes.ZEPHR_PASSWORD_RESET_PATH,
        component: ZephrPasswordResetPage,
        props: true,
      },
      {
        name: routes.ACTIVATE_SUBSCRIPTION,
        path: routes.ACTIVATE_SUBSCRIPTION_PATH,
        component: ActivateSubscriptionPage
      },
      {
        name: routes.SUBSCRIPTION_OUTCOME,
        path: routes.SUBSCRIPTION_OUTCOME_PATH,
        component: SubscriptionOutcomePage
      },
      {
        name: routes.TEST,
        path: routes.TEST_PATH,
        component: TestPage
      },
    ]
  });
}
