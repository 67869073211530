<template>
  <AuthContainer>
    <template #form>
      <SubscriptionOutcome/>
    </template>
  </AuthContainer>

</template>
<script>

import Page from '@pages/Page.js';
import SubscriptionOutcome from '@root/src/components/SubscriptionOutcome.vue';
import AuthContainer from '@root/src/components/AuthContainer.vue';

export default {
  name: 'subscription-complete-page',
  components: {
    AuthContainer,
    SubscriptionOutcome
  },
  extends: Page,
  methods: {
    getTitle() {
      return this.publication.title;
    }
  }
};
</script>
