import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

const corporateSSO = {
  methods: {
    async corporateSsoLogin(data = {}) {
      const app = this;
      app.$authWebapp.showLoader();
      try {
        console.log('Doing corporate sso login');
        const res = await app.authServices.corporateSsoLogin(data, app.publication.name);
        console.log('Corporate SSO Login', res);
        if (app.isMobileView) {
          await app.corporateSsoMobileFinishedWorkflow(true, '');
          console.log('Mob login done');
        } else {
          app.corporateSsoWebSuccessWorkflow();
        }
        this.$authWebapp.hideLoader();
        return res;
      } catch (e) {
        if (app.isMobileView) {
          await app.corporateSsoMobileFinishedWorkflow(false, e.cause);
          console.log('Mobile login failed');
        }
        alert('Corporate SSO Login Failed');
        console.error('Corporate SSO Error', e);
        this.$authWebapp.hideLoader();
        throw e;
      }
    },
    async corporateSsoWebSuccessWorkflow() {
      this.$postMessage.send(
        EXTERNAL_CONFIGS.EVENTS.LOGIN_SUCCESS
      );
      if (!this.isExternalView) {
        window.top.location.href = this.getTarget();
      }
    },
    async corporateSsoMobileFinishedWorkflow(success, message) {
      try {
        window.webkit.messageHandlers.corporateSsoAuth.postMessage({
          type: 'corporate_sso_login',
          message: {
            success: success,
            message: message
          }
        });
      } catch (e) {
        console.error(e);
        await this.subAuthClient.mobileSSOLoginFinishedPing(success, message);
      }
    }
  }
};

export { corporateSSO };
