<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="[
        'd-flex',
        'flex-column',
        'justify-content-between',
        disabled ? 'disabled' : '',
      ]"
      method="post">
      <div>
        <EmailField
          :rules="{
            email_required: true,
            finish_server_side_email_checking: true,
          }"
          @input="showConfirmBox = false"
          :field-name="$t('username.label')"
          :placeholder="$t('email.placeholder')"
          :disabled="disabled"
          name="username" />
        <PasswordField
          @input="showConfirmBox = false"
          :field-name="$t('password')"
          :placeholder="$t('your.password')"
          :rules="{ required: true }"
          :disabled="disabled" />
      </div>
      <div>
        <Confirmation
          v-if="!success && showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2" />
        <TermsAndConditionsView
          :show-terms-and-conditions="userLoggedInBefore === false"
          :title="$t('terms.and.conditions.accept')"
          :body="$t('terms.and.conditions.body', { pub: publication.title })"
          class="mt-0">
          <template #container>
            <LoadingButton
              :disabled="disabled || Boolean(serverSideEmailChecking)"
              :loading="submitting"
              button-class="btn-standard mb-0 mt-0">
              {{ $t('log.in') }}
            </LoadingButton>
          </template>
        </TermsAndConditionsView>
        <div class="mt-3 d-flex justify-content-between">
          <a
            @click.prevent="goToResetPasswordPage"
            v-if="!isNonNcpPublicationDomain"
            :href="passwordResetLink"
            class="link-standard">
            {{ $t('forgot.password') }}
          </a>
          <a
            @click.prevent="goToRegistrationPage"
            v-if="showRegistrationLink"
            :href="registrationLink"
            class="link-standard">
            {{ $t('new.user') }}
          </a>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import * as routes from '@router/constants';
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '@root/src/components/elements/LoadingButton.vue';
import Confirmation from '@root/src/components/elements/Confirmation.vue';
import EmailField from '@root/src/components/elements/EmailField.vue';
import TermsAndConditionsView from '@root/src/components/elements/TermsAndConditionsView';
import { ajaxForm, emailSync, login } from '@mixins';
import PasswordField from '@components/elements/PasswordField';

export default {
  name: 'nhst-login-start',
  components: {
    PasswordField,
    EmailField,
    Confirmation,
    LoadingButton,
    ValidationObserver,
    TermsAndConditionsView,
  },
  mixins: [ajaxForm, emailSync, login],
  computed: {
    passwordResetLink() {
      return this.$router.resolve({ name: routes.PASSWORD_RESET }).href;
    },
    registrationLink() {
      return this.$router.resolve({ name: routes.REGISTRATION }).href;
    },
    showingWelcomeBackTitle() {
      return Object.keys(this.redirectedFrom).length > 0;
    },
    showRegistrationLink() {
      if (isServer) {
        return true;
      }
      // this.$authWebapp is not available on the server side
      // We wont show registration link if we came from the registration page
      // Because this means that the user is already registered in the system and the user just needs to login
      if (this.showingWelcomeBackTitle) {
        return false;
      }
      return this.publication.hasFreeUserRegistration;
    },
    // overrides ajax form's confirmMsg
    confirmMsg: function () {
      return (
        this.confirmMsgs[this.serverResponseCode] || {
          title: '',
          message: this.$t('auth.error.login.unexpected'),
        }
      );
    },
    confirmMsgs: function () {
      return {
        401: {
          title: '',
          message: this.$t('wrong.credential'),
        },
        429: {
          title: '',
          message: this.$t('concurrent.session.exceeded'),
        },
        412: {
          title: this.$t('email.not.verified.title'),
          message: this.$t('email.not.verified.description'),
        },
      };
    },
  },
  watch: {
    userExists: function (newVal) {
      console.log('(Login Page) User Exists? -> ', newVal);
      if (newVal === false && this.showingWelcomeBackTitle) {
        // User changed email value after getting redirected from 'this.redirectedFrom' page.
        // So sending him back to 'this.redirectedFrom' page.
        const redirectedFrom = this.redirectedFrom;
        console.log(`Redirecting to the ${redirectedFrom.name} page.`);
        this.redirectedFrom = {};
        this.$authWebapp.goBack();
      }
    },
  },
  methods: {
    successWorkflow() {
      this.showConfirmBox = false;
      this.loginSuccessWorkflow();
    },
    errorWorkflow() {
      if (this.serverResponseCode === 300) {
        this.$emit('chooseUsername', this.email, this.getUsernameChoices());
      } else if (this.serverResponseCode === 303) {
        const choices = this.getUsernameChoices();
        if (choices && choices.length > 0) {
          this.$emit('chooseUsernameOrEnterNew', this.email, choices);
        } else {
          this.$emit('enterNewUsername', this.email);
        }
      } else {
        console.log(
          `Failed to start login => serverResponseCode: ${this.serverResponseCode}`,
        );
      }
    },
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = !!this.redirectedFrom;
    },
    updateShouldValidateUserLoggedInBeforeWithServer() {
      this.shouldValidateUserLoggedInBeforeWithServer = true;
    },
    goToResetPasswordPage() {
      this.$router.push({
        name: routes.PASSWORD_RESET,
        query: this.$route.query,
      });
    },
    goToRegistrationPage() {
      this.$router.push({
        name: routes.REGISTRATION,
        query: this.$route.query,
      });
    },
    submit() {
      const app = this;
      return app.submitForm(
        () =>
          app.authServices.login(app.email, app.password, app.publication.name),
        true,
      );
    },
    getUsernameChoices() {
      return (this.serverResponseHeaders['x-choices'] || '')
        .split(',')
        .filter((p) => p.length > 0);
    },
  },
};
</script>
