<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
      method="post">
      <div>
        <div v-show ="company.logo" class="company-info">
          <div class="company-logo">
            <img
              :src="company.logo"
              :alt="company.name">
          </div>
        </div>
        <Confirmation
          v-if="!success && showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2 mb-6"/>
        <EmailField
          :rules="loggedIn?
            {} : {email_required: true, email: true, email_available: true}"
          :field-name="$t('email.label')"
          :placeholder="$t('email.placeholder')"
          :disabled="submitting || loggedIn"
          @input="showConfirmBox = false"
          name="email"/>
        <TextField
          v-if="company.requires_registration_code"
          v-model="registration_code"
          :rules="{required: true}"
          :field-name="$t('company.reg.code')"
          :placeholder="$t('company.reg.placeholder')"
          @input="showConfirmBox = false"
          :disabled="submitting"
          name="registration_code"/>
        <ClientOnly>
          <div
            v-if="!loggedIn"
            class="note mb-4">
            {{ $t('register.page.note') }}
          </div>
        </ClientOnly>
      </div>
      <div>
        <TermsAndConditionsView
          :title="$t('terms.and.conditions.accept')"
          :body="$t('terms.and.conditions.body', { pub: publication.title })"
          :show-terms-and-conditions="!loggedIn"
          class="mt-0">
          <template #container>
            <LoadingButton
              :disabled="disabled || submitting"
              :loading="submitting"
              :button-class="buttonClass">
              {{ buttonTxt }}
            </LoadingButton>
          </template>
        </TermsAndConditionsView>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from './elements/LoadingButton';
import EmailField from './elements/EmailField';
import TextField from './elements/TextField';
import { UPDATE_COMPANY } from '@store';
import { ajaxForm, emailSync, formDescriptionHander, userExists } from '@mixins';
import Confirmation from './elements/Confirmation.vue';
import TermsAndConditionsView from '@root/src/components/elements/TermsAndConditionsView';
import { isEmpty } from 'lodash-es';
import ClientOnly from '@components/ClientOnly';

export default {
  name: 'nhst-corporate-registration',
  components: {
    ClientOnly,
    Confirmation,
    EmailField,
    TextField,
    LoadingButton,
    ValidationObserver,
    TermsAndConditionsView
  },
  mixins: [emailSync, ajaxForm, userExists, formDescriptionHander],
  data() {
    return {
      registration_code: '',
      watchForFormHeadingSubHeadingChanges: true,
      showLogoutButton: false,
      emailSuffixErrorKey: '400.email_suffix'
    };
  },
  computed: {
    buttonTxt() {
      if (this.loggedIn) {
        return this.showLogoutButton ? this.$t('nhst.id.logout') : this.$t('register.button.text.logged.in');
      } else {
        return this.$t('register.button.text');
      }
    },
    buttonClass: function () {
      return 'mt-0 ' + (this.showLogoutButton ? 'btn-red' : 'btn-standard');
    },
    confirmMsgs: function () {
      return {
        [this.emailSuffixErrorKey]: {
          message: this.loggedIn ? this.$t('corporate.logged.in.invalid.email.description') : this.$t('corporate.anonymous.invalid.email.description'),
          title: '',
        },
        '400.registration_code': {
          message: '',
          title: this.$t('corporate.invalid.reg.code')
        },
        '400.ip': {
          message: '',
          title: this.$t('corporate.invalid.ip'),
        },
        '400.number_of_seats': {
          message: this.$t('corporate.invalid.number.of.seats.description'),
          title: this.$t('corporate.invalid.number.of.seats.title'),
        }
      };
    },
    confirmMsgKey() {
      let errorMessage = this.serverResponseMessage;
      let errorKey = '';
      if (typeof errorMessage === 'object' && errorMessage.errors) {
        errorMessage = errorMessage.errors;
        // Get any of the error
        if (Object.keys(errorMessage).length) errorKey = Object.keys(errorMessage)[0];
      }
      return `${this.serverResponseCode}.${errorKey}`;
    },
    // overriding the mixin from AjaxForm as there are a lot of error messages for same error codes
    confirmMsg: function () {
      return this.confirmMsgs[this.confirmMsgKey] || { title: '', message: this.$t('auth.error.unexpected') };
    },
    company: function () {
      return this.$store.state.corporate.company;
    },
  },
  watch: {
    company: {
      deep: true,
      immediate: true,
      handler() {
        const company = this.company || {};
        this.formHeading = this.$t('corporate.page.title', { companyName: company.name || '' });
        this.formSubHeading = company.description || '';
      }
    }
  },
  created() {
    if (!isServer) {
      console.log('Fetching data');
      this.fetchData();
    }
  },
  methods: {
    // Username will be validated against server (username existence check is enabled)
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = true;
    },
    fetchData() {
      return this.subscriptionClient.getCompany(this.$route.params.slug).then((resp) => {
        return this.$store.commit(`corporate/${UPDATE_COMPANY}`, resp.data);
      }).catch((e) => {
        return this.$store.commit(`corporate/${UPDATE_COMPANY}`, {});
      });
    },
    isServerPrefetchSuccessful() {
      return !isEmpty(this.company);
    },
    successWorkflow() {
      localStorage.setItem('sub-auth-corporate-email', btoa(this.email));
      localStorage.setItem('sub-auth-reg-code', btoa(this.registration_code));
      localStorage.setItem('sub-auth-added-at', new Date().toISOString());
      const articleUrl = this.getParamValue(this.getTarget(), 'articleUrl');
      const target = articleUrl ? `${this.company.order_page}?target=${articleUrl}&articleUrl=${articleUrl}` : this.company.order_page;
      logger.info('Redirecting to target: ' + target);
      window.location.href = target;
    },
    errorWorkflow() {
      if (this.loggedIn && this.confirmMsgKey === this.emailSuffixErrorKey) {
        this.showLogoutButton = true;
      }
    },
    reloadWindow() {
      this.submitting = true;
      this.disabled = true;
      this.showConfirmBox = false;
      window.top.location.reload();
    },
    async logout() {
      await this.authServices.ssoLogout();
    },
    submit() {
      const app = this;
      if (app.showLogoutButton) {
        return app.submitFormWithHandlers(
          () => app.logout(),
          true,
          app.reloadWindow,
          app.reloadWindow,
        );
      }
      app.showLogoutButton = false;
      return app.submitForm(
        () => app.subscriptionClient.validateCompanyInput(
          this.$route.params.slug,
          this.email,
          this.registration_code,
          ''
        ), true
      );
    },
  },
};
</script>
<style lang="scss" scoped>

.company-info {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    margin: auto;
  }
}
</style>
