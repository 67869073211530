<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(confirmVerificationCode)"
      :class="['validate-code', 'd-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <Modal
          ref="resendModal"
          :title="$t('reset.password.validate.code.resend.code.title')"
          :text="$t('reset.password.validate.code.resend.code.description', {email: username})"
          :show="showModal"
          :buttons="modalButtons"
          :show-captcha="true"
          @clicked="resendVerficationCode"/>
        <div class="mb-4">
          <a
            @click.prevent="showModal = true; showConfirmBox = false"
            href="#">
            {{ $t('reset.password.resend.code') }}
          </a>
        </div>
        <TextField
          :rules="{required: true, max: 20}"
          :field-name="$t('reset.password.validate.code.label')"
          :placeholder="$t('reset.password.validate.code.placeholder')"
          :disabled="disabled"
          @input="showConfirmBox = false"
          v-model="code"
          name="code"/>
      </div>
      <div>
        <transition
          name="expand"
          mode="out-in">
          <Confirmation
            v-if="showConfirmBox"
            :success="success"
            :message="confirmMsg.message"
            :title="confirmMsg.title"
            class="pb-2"/>
        </transition>
        <LoadingButton
          :disabled="submitting || disabled"
          :loading="submitting">
          {{ $t('reset.password.validate.code.button.text') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '../elements/LoadingButton';
import Confirmation from '../elements/Confirmation';
import { ajaxForm } from '@mixins';
import TextField from '@components/elements/TextField';
import Modal from '@components/elements/Modal';
import CustomButton from '@root/src/helpers/CustomButton';

export default {
  name: 'nhst-reset-password-validate-code',
  components: {
    Modal,
    TextField,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm],
  data() {
    return {
      code: '',
      showModal: false,
    };
  },
  computed: {
    modalButtons: function () {
      return [
        new CustomButton(
          {
            text: this.$t('no'),
            value: 'no',
            class: 'btn-secondary',
            recaptchaRequired: false
          }),
        new CustomButton(
          {
            text: this.$t('yes'),
            value: 'yes',
            recaptchaRequired: true
          })
      ];
    },
    confirmMsgs: function () {
      return {
        201: {
          title: this.$t('reset.password.validate.code.201.title'),
          message: this.$t('reset.password.validate.code.201.description', { email: this.username })
        },
        404: {
          title: this.$t('reset.password.validate.code.404.title'),
          message: this.$t('reset.password.validate.code.404.description')
        },
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
  },
  methods: {
    validateCodeSuccessWorkflow() {
      this.showConfirmBox = false;
      this.$emit('success', encodeURIComponent(this.code));
    },
    validateCodeErrorWorkflow() {
    },
    resendValidateCodeSuccessWorkflow() {
      this.hideLoader();
      this.disabled = false;
      this.showConfirmBox = true;
    },
    resendValidateCodeErrorWorkflow() {
      this.hideLoader();
      this.showConfirmBox = true;
    },
    async resendVerficationCode(val) {
      const app = this;
      app.showModal = false;
      if (val === 'no') {
        return;
      }
      app.showLoader();
      return app.submitFormWithHandlers(
        () => app.subAuthClient.resetPasswordStart(this.username),
        true,
        () => app.resendValidateCodeSuccessWorkflow(),
        () => app.resendValidateCodeErrorWorkflow(),
        true
      );
    },
    confirmVerificationCode() {
      const app = this;
      return app.submitFormWithHandlers(
        () => app.zephrPublicClient.validateResetPasswordCode(
          this.username, this.code
        ),
        true,
        () => app.validateCodeSuccessWorkflow(),
        () => app.validateCodeErrorWorkflow()
      );
    },
  },
};
</script>
<style lang="scss">
.validate-code {
  .sub-heading {
    text-align: left;
    font-size: 16px;
  }
}
</style>
