<template>
  <div class="nhst-logos-short pb-3">
    <div v-if="isExternalView">
      <img
        :src="`${$cdn.resource(`nhst-logo.png`)}`"
        :alt="`nhst`">
    </div>
    <div class="mt-2 mb-4">
      <div class="heading ps-2 pe-2">
        <p v-html="$t('nhst.id.popup.description')"></p>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <div
        :key="`${pub}-monochrome-logo`"
        v-for="pub in pubs">
        <img
          :src="`${$cdn.resource(`${pub.toLowerCase()}/monochrome-logo.png`)}`"
          :alt="`${pub}`"
          class="pub-images">
      </div>
      <div v-if="!isExternalView">
        <img
          :src="`${$cdn.resource(`nhst-logo-short.png`)}`"
          :alt="`nhst`"
          class="pub-images">
      </div>
    </div>
    <div class="d-flex justify-content-between py-2">
      <div v-html="$t('nhst.id.popup.definition')"></div>
      <div v-html="$t('privacy.link')"></div>
    </div>
  </div>
</template>

<script>

import { nhstLogos } from '@mixins';

export default {
  name: 'nhst-logos-footer',
  mixins: [nhstLogos],
};
</script>

<style scoped lang="scss">

.nhst-logos-short {
  font-size: 12px;
  line-height: 14.4px;
  text-align: center;
  font-weight: normal;
  background: #F6F6F6;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.07);
  border-radius: 10px;

  img.pub-images {
    width: 90px;
    height: 45px;
    margin: 0 10px 10px 0;
  }

  .heading {
    color: #000000;
    margin: auto;
    font-size: 12px;
    font-weight: normal;
    align-content: center;

    p {
      padding: 0;
      margin: 0;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
    }
  }
}
</style>
