<template>
  <div class="loading-button">
    <button
      v-bind="$attrs"
      :disabled="disabled || loading"
      :type="buttonType"
      :class="buttonClass">
      <slot v-if="!loading">Submit</slot>
      <font-awesome-icon
        v-else
        icon="spinner"
        spin
        pulse/>
    </button>
  </div>
</template>

<script>

export default {
  name: 'loading-button',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonType: {
      type: String,
      required: false,
      default: 'submit'
    },
    buttonClass: {
      type: String,
      required: false,
      default: 'btn-standard mt-4'
    }
  },
};
</script>
<!-- Don't use scoped here -->
<style lang="scss">
.loading-button {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
}

button:disabled, .btn-standard:disabled {
  cursor: not-allowed;
}
</style>
