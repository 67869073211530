function slug() {
  return devMode ? `?${Date.now()}` : '';
}
export default {
  install(Vue, { CDN, assetsManifest }) {
    Vue.prototype.$cdn = {
      ...CDN,
      asset(name) {
        return `${CDN.baseUrl}${assetsManifest[name]}${slug()}`;
      },
      resource(filepath) {
        return `${CDN.baseUrl}/assets/resources/${filepath}${slug()}`;
      }
    };
  }
};
