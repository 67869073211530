import { createApp } from './app.csr';

const {
  app,
  router,
} = createApp();

router.onReady(() => {
  // actually mount to DOM
  app.$mount('#app');
});
