import { extend } from 'vee-validate';
import { required, email, min, max } from 'vee-validate/dist/rules.umd';
import moment from 'moment';
import Utils from '@root/src/helpers/utils';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';

const getMessage = (value) => {
  if (isServer) {
    return;
  }
  const langConfig = window.__INITIAL_STATE__.langConfig;
  const locale = langConfig.locale;
  const fallbackLocale = langConfig.fallbackLocale;
  return langConfig.messages[locale][value] || langConfig.messages[fallbackLocale][value];
};

extend('required', {
  ...required,
  message: getMessage('field.required')
});

extend('email_required', {
  ...required,
  message: getMessage('email.field.required')
});

extend('max', {
  ...max,
  message: getMessage('max.len')
});

extend('min', {
  ...min,
  message: getMessage('min.len')
});

extend('email', {
  ...email,
  message: getMessage('must.be.email')
});

const doWait = async (waitTime = 500) => {
  return new Promise(resolve => setTimeout(resolve, waitTime));
};

const serverSideEmailCheckingErrorMsg = 'Error communicating with the server. Please refresh the page and try again later.';

const isServerSideEmailChecking = () => {
  return window.$vue.$authWebapp.isEmailBeingCheckedOnServerSide();
};

extend('user_exists', {
  message: 'User does not exist',
  validate: async function () {
    const state = window.$store.state.nhstAuth;
    // to prevent race condition
    await doWait();
    while (isServerSideEmailChecking()) {
      await doWait();
    }
    const userExists = state.userExists;
    const userExistsError = state.userExistsCheckError;
    if (userExistsError) {
      return serverSideEmailCheckingErrorMsg;
    }
    if (userExists) {
      return { valid: true };
    } else {
      return this.message;
    }
  }
});

extend('in_company_email_list', {
  message: getMessage('ip.registration.corporate.invalid.email'),
  validate: async function () {
    const corporateState = window.$store.state.corporate;
    const nhstAuthState = window.$store.state.nhstAuth;
    await doWait();
    while (isServerSideEmailChecking()) {
      await doWait();
    }
    if (corporateState.validEmails.length === 0 || corporateState.validEmails.includes(nhstAuthState.email)) {
      return { valid: true };
    } else {
      return this.message;
    }
  }
});

extend('email_available', {
  message: getMessage('email.already.taken'),
  // if user does not exists, that means email is available
  validate: async function () {
    const state = window.$store.state.nhstAuth;
    // to prevent race condition
    await doWait();
    while (isServerSideEmailChecking()) {
      console.log('email available', 'waiting 500 ms');
      await doWait();
    }
    const userExists = state.userExists;
    const userExistsError = state.userExistsCheckError;
    if (userExistsError) {
      return serverSideEmailCheckingErrorMsg;
    }
    console.log('email available', state);
    console.log('email available', userExists);
    if (!userExists) {
      return { valid: true };
    } else if (userExists) {
      return this.message;
    }
  }
});

extend('finish_server_side_email_checking', {
  message: serverSideEmailCheckingErrorMsg,
  validate: async function () {
    const state = window.$store.state.nhstAuth;
    // to prevent race condition
    await doWait();
    while (isServerSideEmailChecking()) {
      await doWait();
    }
    const userExistsError = state.userExistsCheckError;
    if (userExistsError) {
      return serverSideEmailCheckingErrorMsg;
    }
    return { valid: true };
  }
});

extend('password', {
  message: getMessage('password.requirements.not.met'),
  validate: value => {
    // "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}) - 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)"
    return (new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')).test(value);
  }
});

extend('password_confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: getMessage('password.did.not.match')
});

function parseDate(value) {
  for (const format of Utils.getAllowedFormatsForDates()) {
    // Try to parse date useing moment.js.
    // The true flag at the end signifies that we should use strict match
    // to date format and not have moment try to guess date
    const date = moment(value, format, true);
    if (date.isValid()) {
      return date;
    }
  }
  return null;
}

extend('date', {
  message: getMessage('valid.date'),
  validate(value) {
    // In case no value provided, skip validation and return true
    if (value) {
      return parseDate(value) != null;
    }
    return true;
  },
});
extend('min_age', {
  params: ['min_age'],
  message: getMessage('min.age'),
  // eslint-disable-next-line camelcase
  validate(value, { min_age }) {
    const today = moment();
    const cutoffDate = today.subtract(min_age, 'years');
    const dateOfBirth = parseDate(value);

    if (dateOfBirth.isSameOrBefore(cutoffDate)) {
      return true;
    }
    return false;
  },
});
extend('reset_password_valid_code', {
  message: getMessage('reset.password.validate.code.404.description'),
  async validate(value) {
    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.RESET_PASSWORD_VALIDATE_CODE);
    try {
      await window.zephrPublicClient.validateResetPasswordCode(
        window.$store.state.nhstAuth.email,
        value
      );
      window.$vue.$authWebapp.finishServerSideCheckingForField(LOADING_TYPES.RESET_PASSWORD_VALIDATE_CODE);
      return true;
    } catch (e) {
      console.error(e);
    }
    window.$vue.$authWebapp.finishServerSideCheckingForField(LOADING_TYPES.RESET_PASSWORD_VALIDATE_CODE);
    return false;
  },
});
