<template>
  <AcceptProductShare />
</template>

<script>
import { AcceptProductShare } from '../components';
import Page from '@pages/Page.js';

export default {
  name: 'accept-product-share-page',
  components: {
    AcceptProductShare,
  },
  extends: Page,
};
</script>
