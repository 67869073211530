var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{class:[
      'd-flex',
      'flex-column',
      'justify-content-between',
      _vm.disabled ? 'disabled' : '',
    ],attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('EmailField',{attrs:{"rules":{
          email_required: true,
          finish_server_side_email_checking: true,
        },"field-name":_vm.$t('username.label'),"placeholder":_vm.$t('email.placeholder'),"disabled":_vm.disabled,"name":"username"},on:{"input":function($event){_vm.showConfirmBox = false}}}),_vm._v(" "),_c('PasswordField',{attrs:{"field-name":_vm.$t('password'),"placeholder":_vm.$t('your.password'),"rules":{ required: true },"disabled":_vm.disabled},on:{"input":function($event){_vm.showConfirmBox = false}}})],1),_vm._v(" "),_c('div',[(!_vm.success && _vm.showConfirmBox)?_c('Confirmation',{staticClass:"pb-2",attrs:{"success":_vm.success,"message":_vm.confirmMsg.message,"title":_vm.confirmMsg.title}}):_vm._e(),_vm._v(" "),_c('TermsAndConditionsView',{staticClass:"mt-0",attrs:{"show-terms-and-conditions":_vm.userLoggedInBefore === false,"title":_vm.$t('terms.and.conditions.accept'),"body":_vm.$t('terms.and.conditions.body', { pub: _vm.publication.title })},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('LoadingButton',{attrs:{"disabled":_vm.disabled || Boolean(_vm.serverSideEmailChecking),"loading":_vm.submitting,"button-class":"btn-standard mb-0 mt-0"}},[_vm._v("\n            "+_vm._s(_vm.$t('log.in'))+"\n          ")])]},proxy:true}],null,true)}),_vm._v(" "),_c('div',{staticClass:"mt-3 d-flex justify-content-between"},[(!_vm.isNonNcpPublicationDomain)?_c('a',{staticClass:"link-standard",attrs:{"href":_vm.passwordResetLink},on:{"click":function($event){$event.preventDefault();return _vm.goToResetPasswordPage.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('forgot.password'))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.showRegistrationLink)?_c('a',{staticClass:"link-standard",attrs:{"href":_vm.registrationLink},on:{"click":function($event){$event.preventDefault();return _vm.goToRegistrationPage.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('new.user'))+"\n        ")]):_vm._e()])],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }