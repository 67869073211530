import { UPDATE_SSO_STATUS_OFFLINE } from '@store';
import Utils from '@root/src/helpers/utils';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

const sso = {
  computed: {
    ssoPopupShowedKey() {
      return `${this.publication.name}-sso-popup-showed`;
    },
    ssoUser() {
      return this.$store.state.sso.user;
    },
    isSsoDataFetched() {
      return this.$store.state.sso.fetched;
    },
    isSsoEligible() {
      return this.ssoUser.status === 'known';
    },
    ssoMeta() {
      return this.ssoUser.meta || {};
    },
    ssoEmail() {
      return this.ssoMeta.email || '';
    },
    hasConcurrencyLimitExceeded() {
      return this.$store.state.sso.hasConcurrencyLimitExceeded;
    },
    shouldShowSsoCloseIcon() {
      return this.isPopupView && !this.isExternalView;
    }
  },
  methods: {
    async updateSsoMeta(ssoData = {}) {
      const data = Utils.deepMergeWithArrayOverwrite(this.ssoMeta, ssoData);
      data[this.ssoPopupShowedKey] = true;
      await this.authServices.updateSsoMeta(this.publication.name, data);
      console.log('Meta to update', data);
      try {
        // as zephr is async, it is not guaranteed that we will receive the change as soon as we have requested for it
        // so updating the status using the current data rather than retrieving it from zephr
        return await this.$store.dispatch(
          `sso/${UPDATE_SSO_STATUS_OFFLINE}`,
          Utils.deepMergeWithArrayOverwrite(this.ssoUser, { meta: data })
        );
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    sendSsoPopupCloseEvent(value) {
      this.$postMessage.send(EXTERNAL_CONFIGS.EVENTS.ZEPHR_SSO_POPUP_CLOSE, { value });
    },
    closePopupOrRedirectPrevUrl(value) {
      this.sendSsoPopupCloseEvent(value);
      if (window.top.location.pathname.includes(`${dconf.BASE_PATH}/`)) {
        window.top.location.href = this.previousPageUrl;
      }
    }
  }
};

export { sso };
