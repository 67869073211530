<template>
  <AuthContainer>
    <template #form>
      <MvpFreeUserRegistration />
    </template>
  </AuthContainer>
</template>

<script>
import {
  MvpFreeUserRegistration,
  AuthContainer
} from '../components';
import AnonymousPage from '@pages/AnonymousPage';

export default {
  name: 'free-registration-mvp-page',
  components: {
    AuthContainer,
    MvpFreeUserRegistration
  },
  extends: AnonymousPage,
  methods: {
    getTitle() {
      return `${this.$t('registration.page.title')} | ${this.publication.title}`;
    },
    getRedirectLocation() {
      throw Error('You need to implement this yourself!');
    },
  }
};

</script>
