<template>
  <form
    :class="['d-flex', 'flex-column', 'justify-content-between']"
    @submit.prevent="closePopup">
    <div>
      <button
        type="submit"
        class="btn-standard mt-0 mb-4">
        {{ $t('ok') }}
      </button>
      <div class="text-center">
        <a
          :href="$t('sso.login.concurrency.help.link')"
          class="sso-logout"
          rel="noopener"
          target="_blank">{{ $t('get.help') }}</a>
      </div>
    </div>
  </form>
</template>

<script>

import { closeIconController, formDescriptionHander, sso } from '@mixins';

export default {
  name: 'sso-concurrency-limit-exceeded',
  mixins: [sso, closeIconController, formDescriptionHander],
  methods: {
    customFormDescriptionHandler() {
      if (isServer) {
        return false;
      }
      this.formHeading = this.$t('sso.log.in.page.title', { email: this.ssoEmail });
      this.formSubHeading = this.$t('sso.login.concurrency.description');
      return true;
    },
    async closePopup() {
      await this.updateSsoMeta();
      this.closePopupOrRedirectPrevUrl(false);
    },
    shouldShowCloseIcon() {
      return this.shouldShowSsoCloseIcon;
    },
    handleCloseIconClicked() {
      this.closePopup();
    },
  }
};
</script>

<style lang="scss">
a.sso-logout {
  font-size: 18px;
}
</style>
