<template>
  <div class="corporate-sso">
    <ValidationObserver
      v-slot="{handleSubmit, invalid}"
      slim>
      <form
        @submit.prevent="handleSubmit(submit)"
        :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
        method="post">
        <div>
          <div class="company-info">
            <div class="company-logo">
              <img
                :src="companyLogo"
                :alt="companyName">
            </div>
          </div>
          <EmailField
            :rules="{email_required: true, finish_server_side_email_checking: true}"
            @input="showConfirmBox = false"
            :field-name="$t('username.label')"
            :placeholder="$t('email.placeholder')"
            :disabled="disabled"
            name="username"/>
          <SimpleMultiSelectField
            :disabled="disabled || submitting"
            v-if="!userLoggedInBefore && showCountryDropdown"
            :placeholder="$t('country')"
            :field-name="$t('country')"
            :rules="{required: true}"
            v-model="country"
            :options="getCountries()"/>
          <CheckboxField
            v-if="!userLoggedInBefore"
            :rules="{required: true}"
            :field-name="$t('terms.conditions')"
            v-model="terms_accepted"
            :text="$t('corporate.sso.terms.and.conditions', {pub: publication.title})"
            class="terms-and-conditions mt-5"/>
          <Confirmation
            v-if="!success && showConfirmBox"
            :success="success"
            :message="postMessage.message || confirmMsg.message"
            :title="postMessage.title || confirmMsg.title"
            class="pb-2 mb-6"/>
        </div>
        <div>
          <LoadingButton
            :disabled="submitting"
            :loading="submitting">
            {{ $t('corporate.sso.accept.and.login') }}
          </LoadingButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import { ajaxForm, corporateSSO, emailSync, formDescriptionHander, showPublicationLogos } from '@mixins';
import LoadingButton from '@components/elements/LoadingButton';
import Confirmation from '@components/elements/Confirmation';
import CheckboxField from '@components/elements/CheckboxField';
import { ValidationObserver } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';
import utils from '@root/src/helpers/utils';
import EmailField from '@elements/EmailField';
import SimpleMultiSelectField from '../elements/SimpleMultiSelectField.vue';
import * as routes from '@router/constants';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';
import countries from '../../server/utils/countries';

export default {
  name: 'corporate-sso-login',
  components: { EmailField, SimpleMultiSelectField, Confirmation, LoadingButton, ValidationObserver, CheckboxField },
  mixins: [ajaxForm, formDescriptionHander, corporateSSO, emailSync, showPublicationLogos],
  data() {
    const POST_MESSAGE_ACTIONS = {
      LOGIN: 'login',
      REGISTER: 'register',
      OTP: 'otp'
    };

    const POST_MESSAGE_STATUSES = {
      FAILURE: 'failure',
      SUCCESS: 'success',
      PARTIAL: 'partial'
    };
    return {
      companyName: '',
      companyLogo: '',
      country: '',
      terms_accepted: false,
      watchForFormHeadingSubHeadingChanges: true,
      zephrCompanyAccountId: '',
      POST_MESSAGE_ACTIONS,
      POST_MESSAGE_STATUSES,
      postMessage: {
        title: '',
        message: ''
      }
    };
  },
  computed: {
    confirmMsgs: function () {
      return {
        400: {
          title: this.$t('corporate.invalid.default'),
          message: this.$t('corporate.invalid.default')
        },
      };
    },
    isCorporateSsoUserAndEmail: function () {
      return {
        email: this.email,
        isCorporateSsoUser: this.isCorporateSsoUser,
        corporateSsoUser: this.corporateSsoUser,
        serverSideEmailChecking: this.serverSideEmailChecking,
      };
    },
    concurrency_error_message_from_zephr() {
      return 'To gain login access, please contact your administrator';
    },
    showCountryDropdown: function () {
      return this.publication.lang !== 'no';
    }
  },
  watch: {
    userLoggedInBefore: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.showTermsAndConditions();
        } else {
          this.hideTermsAndConditions();
        }
        this.customFormDescriptionHandler();
      }
    },
    corporateSsoUser: {
      immediate: true,
      handler(val) {
        if (val) {
          this.companyName = val.company_name;
          this.companyLogo = val.company_logo_url;
          this.zephrCompanyAccountId = val.zephr_account_id;
        }
      },
    },
    isCorporateSsoUserAndEmail: {
      immediate: true,
      async handler(val) {
        if (isServer) {
          return;
        }
        const isCorporateSsoUser = await utils.isCorporateSsoUser(val.email);
        // show full page loader during server side email checking if we are not showing the loader already
        if (val.serverSideEmailChecking) {
          if (!this.isFieldBeingCheckedOnServerSide(LOADING_TYPES.FULL_PAGE)) {
            this.showLoader();
          }
        } else {
          if (this.isFieldBeingCheckedOnServerSide(LOADING_TYPES.FULL_PAGE)) {
            this.hideLoader();
          }
        }
        if (val.email && !isCorporateSsoUser) {
          if (this.$authWebapp.hasComeBackFromAnotherPageInThisApp()) {
            this.$router.go(-1);
          } else {
            this.$router.replace({
              name: routes.LOGIN
            });
          }
        }
      }
    }
  },
  mounted: async function () {
    const app = this;
    window['X-Trace-Id'] = uuidv4();
    const messageAction = (message) => {
      return app.postMessageListener(message);
    };
    const flagName = 'corporate-sso-login-post-msg-listener';
    // we need to remove duplicate/old listener due to re-mount
    window.removeEventListener('message', window[flagName]);
    window.addEventListener('message', messageAction);

    window[flagName] = messageAction;
  },
  methods: {
    isValidPostMessageForCorporateSso(message, validKeys) {
      if (message.data && message.data.action) {
        const found = Object.keys(validKeys).filter(val => validKeys[val] === message.data.action);
        return found.length > 0;
      }
      return false;
    },
    initializeConfirmationBoxData() {
      this.showConfirmBox = false;
      this.success = true;
      this.postMessage = { title: '', message: '' };
    },
    generatePostMessageToSend(data) {
      const app = this;
      const msg = `[Corporate SSO Login Post Message] [${app.email}]
      ${JSON.stringify(data, 0, 2)}`;
      try {
        return `${msg}
      [URL: ${window.location.href}]
      [User Agent: ${app.getUserAgent()}]
      [View: ${app.isMobileView ? 'mobile' : 'web'}]
      `;
      } catch (e) {
        console.error('Error in Corporate SSO Post Message', e);
        return `${msg}
        [Error: ${e} ${e.message}]`;
      }
    },
    async postMessageListener(message) {
      const app = this;
      const ACTIONS = this.POST_MESSAGE_ACTIONS;
      if (!app.isValidPostMessageForCorporateSso(message, ACTIONS)) {
        return;
      }
      app.showLoader();
      const { data } = message;
      const { action } = data;
      console.log('Corporate SSO Login', message);
      console.log('Corporate SSO Login', data, JSON.stringify(data, 0, 2));
      data.customAttributes = { 'country-code': this.country };
      const serverMessage = app.generatePostMessageToSend(data);
      await app.authServices.logInServer('INFO', serverMessage);
      this.initializeConfirmationBoxData();
      if (action === ACTIONS.REGISTER) {
        await app.handleRegister(data);
      } else if (action === ACTIONS.LOGIN) {
        await app.handleLogin(data);
      } else if (action === ACTIONS.OTP) {
        console.log('two factor auth is not supported');
      }
      app.hideLoader();
    },
    loginSuccessWorkflow() {
      this.showConfirmBox = false;
    },
    loginErrorWorkflow() {

    },
    registrationSuccessWorkflow() {
      this.showConfirmBox = false;
    },
    registrationErrorWorkflow() {

    },
    async handleLogin(data) {
      const app = this;
      if (app.POST_MESSAGE_STATUSES.FAILURE === data.status) {
        app.showConfirmBox = true;
        app.success = false;
        app.postMessage = { title: '', message: app.$t('auth.error.unexpected') };
        if (data.message && data.message.includes(this.concurrency_error_message_from_zephr)) {
          app.postMessage.message = this.$t('concurrent.session.exceeded');
        }
        if (app.isMobileView) {
          await app.corporateSsoMobileFinishedWorkflow(false, data.message | '');
          console.log('Corporate sso mobile login failed');
        }
        return;
      }
      await app.submitFormWithHandlers(
        () => app.corporateSsoLogin(data),
        false,
        app.loginSuccessWorkflow,
        app.loginErrorWorkflow
      );
    },
    handleRegister: async function (data) {
      const app = this;
      const authResponse = {
        current: null,
      };
      authResponse.current = {
        identifier: data.identifier,
        stateKey: data.stateKey,
      };
      let updatedAttributes = {};
      if (data.userAttributes) {
        updatedAttributes = { ...data.userAttributes };
      }
      const isComplete = Object.values(updatedAttributes).every((val) => val);
      if (!isComplete) {
        app.authServices.logInServer(
          'ERROR',
          `SSO Registration Failed for ${app.email}, blaize session: ${utils.getCookie('blaize_session')}`
        );
        await app.corporateSsoMobileFinishedWorkflow(false, data.message | '');
        console.log('sso register failed');
        return;
      }
      const transformedAtributtes = {
        'first-name': updatedAttributes['first-name'],
        'last-name': updatedAttributes['last-name']
      };

      const registerData = {
        identifiers: {
          email_address: authResponse.current.identifier,
        },
        validators: {},
        attributes: { ...transformedAtributtes },
      };
      if (authResponse.current && authResponse.current.stateKey) {
        delete registerData.identifiers;
        registerData.validators.token_exchange = authResponse.current.stateKey;
      }
      const clientSubmitFunction = async () => {
        try {
          const res = await app.zephrPublicClient.register(registerData);
          await app.corporateSsoLogin(data);
          console.log(res);
          return res;
        } catch (e) {
          const res = e.response;
          if (res.status === 403 && res.data && res.data.message && res.data.message.includes('The account has no free seats')) {
            app.showConfirmBox = true;
            app.success = false;
            app.postMessage = { title: '', message: app.$t('sso.seat.limit.exceeded') };
          }
          throw e;
        }
      };
      await app.submitFormWithHandlers(
        clientSubmitFunction,
        false,
        app.registrationSuccessWorkflow,
        app.registrationErrorWorkflow
      );
    },
    popupWindow: function (url, title, w, h) {
      const y = window.outerHeight / 2 + window.screenY - (h / 2);
      const x = window.outerWidth / 2 + window.screenX - (w / 2);
      return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
    },
    openPopup: function () {
      this.initializeConfirmationBoxData();
      const zephrOidcUrl = `/zephr/media/oauth/oidc?account=${this.zephrCompanyAccountId}`;
      console.log('Zephr oidc url', zephrOidcUrl);
      this.popupWindow(zephrOidcUrl, '', 500, 600);
    },
    customFormDescriptionHandler() {
      this.formHeading = this.$t('corporate.sso.heading');
      if (this.userLoggedInBefore === true) {
        this.formSubHeading = '';
      } else {
        this.formSubHeading = this.$t('corporate.sso.description');
      }
    },
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = false;
    },
    updateShouldValidateUserLoggedInBeforeWithServer() {
      this.shouldValidateUserLoggedInBeforeWithServer = true;
    },
    async submit() {
      this.authServices.deleteUnusedSessions(this.username, this.isMobileView ? 'app' : 'web');
      console.log('Opening popup ...');
      if (!this.zephrCompanyAccountId) {
        this.showConfirmBox = true;
        this.success = false;
      }
      this.openPopup();
    },
    getCountries() {
      const countriesList = countries.map((country) => ({
        text: country.name,
        value: country.code
      }));
      return countriesList;
    }
  }
};
</script>

<style lang="scss" scoped>

.company-info {
  padding-bottom: 40px;

  img {
    max-width: 250px;
    margin: auto;
  }
}

.terms-and-conditions {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #232528;
}

</style>
