<template>
  <ValidationProvider
    :name="fieldName"
    v-slot="{ errors }"
    :rules="rules"
    slim>
    <div>
      <div
        v-bind="$attrs"
        class="d-flex justify-content-start checkbox-wrapper">
        <div>
          <InlineSvg
            @click="handleClick"
            :src="$cdn.resource('checkbox.svg')"
            :class="[
              'checkbox',
              inputVal ? 'active' : 'inactive',
              $attrs.disabled ? 'disabled': '',
              errors.length > 0 ? 'error-border': ''
            ]"
            alt="checkbox"/>
          <input
            v-show="false"
            v-model="inputVal"
            class="checkbox"
            type="checkbox">
        </div>
        <div>
          <div
            v-html="text"
            class="ps-3"></div>
        </div>
      </div>
      <transition
        name="expand"
        mode="out-in">
        <p
          v-show="errors.length > 0"
          v-html="errors[0]"
          class="error-msg ps-6"></p>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'checkbox-field',
  components: {
    ValidationProvider,
    InlineSvg
  },
  props: {
    fieldName: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    rules: {
      default: () => {
      },
      type: Object,
      required: false
    }
  },
  computed: {
    inputVal: {
      get() {
        // False is treated as valid value in vee validate
        return this.value ? true : '';
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleClick() {
      if (this.$attrs.disabled) {
        return;
      }
      this.inputVal = !this.inputVal;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@assets/scss/variables.scss";

.checkbox-wrapper {
  margin-bottom: $input-margin-bottom;
}

</style>
