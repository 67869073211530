<template>
  <div class="privacy" @click="expand" >
    <div>
      <h3 v-if="!expanded">{{ $t('free.registration.privacy.title') }}</h3>
      <span :class="[ 'icon', { 'expanded-icon': expanded }]">
        <InlineSvg
          @click.stop="expand"
          :src="$cdn.resource('chevron-down.svg')"
          alt="chevron"/>
      </span>
    </div>
    <div
      v-if="expanded"
      class="privacy-description">
      <div>
        <div>
          <p v-html="FreeRegistrationPrivacyText"> </p>
          <br>
          <p> {{$t('free.user.registration.id.popup.description')}} <span v-html="$t('nhst.id.popup.definition')"></span> </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'dn-free-registration-privacy',
  components: { InlineSvg },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    FreeRegistrationPrivacyText() {
      return this.$t('free.registration.terms.and.conditions.body', { pub: this.publication.title });
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    }
  },
};
</script>

<style scoped lang="scss">
  .privacy {
    background-color: rgba(237, 241, 248, 1);
    cursor: pointer;
    margin-top: 2.4rem;
    padding: 10px 16px;
    font-size: 12px;
    h3{
      font-size: 12px;
      font-weight: 400;
      margin:0;
    }
    span {
      &.icon {
        float: right;
        position: relative;
        top: -15px;
      }

      &.expanded-icon {
        top: 0;
        transform: rotate(180deg);
      }
    }
    .privacy-description{
      padding: 8px 16px;
    }
}
</style>
