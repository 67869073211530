<template>
  <AuthContainer>
    <template #form>
      <NhstLogin
        :title="title"
        :sub-title="subTitle"
        :auto-login="autoLogin"
        :redirected-from="redirectedFrom"/>
    </template>
  </AuthContainer>
</template>

<script>
import {
  NhstLogin,
  AuthContainer
} from '../components';
import AnonymousPage from '@pages/AnonymousPage';

export default {
  name: 'login-page',
  components: {
    AuthContainer,
    NhstLogin
  },
  extends: AnonymousPage,
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    subTitle: {
      type: String,
      default: '',
      required: false
    },
    autoLogin: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  mounted() {
    this.addZissonChatBot();
  },
  methods: {
    getTitle() {
      return `${this.$t('login.page.title')} | ${this.publication.title}`;
    },
    getRedirectLocation() {
      return '/';
    },
    addZissonChatBot() {
      if (!this.publication.hasZissonChatbot) {
        return;
      }
      if (this.loggedIn) {
        return;
      }
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('data-jwt', this.publication.zissonChatbotConfig.token);
      script.setAttribute('src', 'https://chat2.zisson.com/bootstrapper.js');
      document.body.appendChild(script);
    }
  }
};
</script>
