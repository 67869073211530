<template>
  <div class="test">
    <h1>Hello</h1>
  </div>

</template>
<script>

// import Page from '@pages/Page.js';

export default {
  name: 'subscription-complete-page',

  mounted() {
    const popupWrapper = document.createElement('div');
    popupWrapper.id = 'sub-auth-popup-wrapper';
    popupWrapper.style.position = 'absolute';
    popupWrapper.style.height = '100vh';
    popupWrapper.style.width = '100%';
    popupWrapper.style.top = '0';
    popupWrapper.style.left = '0';
    popupWrapper.style.zIndex = 2000;
    popupWrapper.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
    const popup = document.createElement('iframe');
    popup.id = 'sub-auth-popup';
    popup.src = '/sso?mode=popup';
    popup.height = '100%';
    popup.width = '100%';
    popup.scrolling = 'no';
    popup.seamless = 'seamless';
    popupWrapper.append(popup);
    document.body.prepend(popupWrapper);
  },
  methods: {
    getTitle() {
      return this.publication.title;
    }
  }
};
</script>
<style lang="scss">
.test {
  background-color: lightblue;
  height: 50vh;
}
</style>
