const localEnvSetup = {
  data() {
    return {
      appEnv
    };
  },
  methods: {
    async getBlaizeAccount() {
      try {
        await this.zephrPublicClient.account();
        return true;
      } catch (e) {
        return false;
      }
    },
    async updateCookies() {
      await this.subAuthClient.deleteCookies();
      await this.subAuthClient.setAnonymousCookies();
    }
  },
  async mounted() {
    if (this.appEnv !== 'dev') return;
    console.log('Starting local env setup');
    if (!this.$cookies.get('blaize_session')) {
      await this.updateCookies();
      return;
    }
    const oneidInfo = this.$cookies.get('oneid_info');
    if (oneidInfo) {
      if (!await this.getBlaizeAccount()) {
        await this.updateCookies();
      }
    }
  }
};

export { localEnvSetup };
