import * as routes from '@router/constants';

const userExists = {
  watch: {
    userExists: function (newVal) {
      if (this.loggedIn) return;
      console.log('User Exists? -> ', newVal, this.userExists);
      if (
        newVal === true
      ) {
        console.log('User exists. Redirecting to the login page.');
        this.redirectedFrom = this.$route;
        this.$router.push({
          name: routes.LOGIN,
          params: {
            title: this.$t('register.user.exists.title'),
            subTitle: this.$t('register.user.exists.description'),
          }
        });
      }
    }
  },
  mounted() {
    this.email = this.email || this.getLoggedInUserEmail();
  },
  methods: {
    // Username will be validated against server (username existence check is enabled)
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = true;
    },
  }
};

export { userExists };
