<script>
export default {
  name: 'invalid-share-id',
  computed: {
    customerServiceUrl() {
      return 'https://www.dn.no/kundeservice';
    },
  },
};
</script>

<template>
  <div class="page-content">
    <div class="text-content">
      <h1>Lenken er utgått</h1>
      <p>
        Lenken du klikket på er ikke lenger gyldig. Det kan være at den som
        inviterte deg til å dele abonnementet har slettet invitasjonen, eller
        sendt en ny lenke til deg eller noen andre.
        <br />
        Ta gjerne kontakt med oss om tilgang dersom du ikke finner ut av det.
      </p>
      <p>
        <a
          :href="customerServiceUrl"
          rel="noopener">
          Kontakt kundeservice
        </a>
      </p>
    </div>
  </div>
</template>
