var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('form',{class:[
      'd-flex',
      'flex-column',
      'justify-content-between',
      _vm.submitting || _vm.disabled ? 'disabled' : '',
    ],attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('EmailField',{attrs:{"rules":_vm.loggedIn
            ? {}
            : { email_required: true, email: true, email_available: true },"field-name":_vm.$t('email.label'),"placeholder":_vm.$t('email.placeholder'),"disabled":_vm.submitting || _vm.disabled || _vm.loggedIn,"name":"email"},on:{"input":function($event){_vm.showConfirmBox = false}}})],1),_vm._v(" "),_c('div',[(_vm.showConfirmBox)?_c('Confirmation',{staticClass:"pb-2",attrs:{"success":_vm.success,"message":_vm.confirmMsg.message,"title":_vm.confirmMsg.title}}):_vm._e(),_vm._v(" "),_c('TermsAndConditionsView',{staticClass:"mt-0",attrs:{"show-terms-and-conditions":!_vm.loggedIn,"title":_vm.$t('terms.and.conditions.accept'),"body":_vm.$t('terms.and.conditions.body', { pub: _vm.publication.title })},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('LoadingButton',{attrs:{"disabled":_vm.submitting || _vm.disabled,"loading":_vm.submitting,"button-class":"btn-standard mt-0"}},[_vm._v("\n            "+_vm._s(_vm.registerButtonText)+"\n          ")]),_vm._v(" "),(_vm.showBackButton)?_c('button',{staticClass:"btn-secondary mt-3",attrs:{"disabled":_vm.disabled || _vm.submitting,"type":"button"},on:{"click":_vm.handleBack}},[_vm._v("\n            "+_vm._s(_vm.$t('go.back'))+"\n          ")]):_vm._e()]},proxy:true}],null,true)})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }