<template>
  <div>
    <DNHeader v-if="isDN" />
    <GlobalHeader v-else />
  </div>
</template>
<script>

import { GlobalHeader, DNHeader } from '@components';
export default {
  name: 'header-bar',
  components: { GlobalHeader, DNHeader },
};
</script>
