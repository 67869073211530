<template>
  <div class="error-page d-flex flex-column justify-space-between">
    <div>
      <button
        @click="gotoFrontPage"
        type="button"
        class="btn-standard mt-4">
        {{ $t('go.to.dn') }}
      </button>
    </div>
  </div>
</template>

<script>

import { formDescriptionHander } from '@mixins';

export default {
  name: 'confirmation-page',
  mixins: [formDescriptionHander],
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    isSuccess: {
      type: Boolean,
      // default needs to be false so that we dont unnecessary call refresh-user endpoint
      default: false,
      required: false
    }
  },
  data() {
    return {
      formHeading: '',
      formSubHeading: ''
    };
  },
  mounted() {
    this.callRefreshUser();
  },
  activated() {
    this.callRefreshUser();
  },
  methods: {
    gotoFrontPage() {
      this.$authWebapp.gotoFrontPage();
    },
    customFormDescriptionHandler() {
      this.formHeading = this.title;
      this.formSubHeading = this.description;
    },
    async callRefreshUser() {
      if (!this.isSuccess) {
        return;
      }
      this.showLoader();
      await this.authServices.refreshUser();
      this.hideLoader();
    },
  }
}
;
</script>

<style scoped>
.error-page {
  height: 100%;
}
</style>
