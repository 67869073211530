<template>
  <div>
    <ValidationProvider
      :rules="rules"
      :name="fieldName"
      v-slot="{ errors }"
      :debounce="1000"
      slim>
      <GeneralTextField
        ref="dobInput"
        :field-name="fieldName"
        :value="date"
        :error="errors.length > 0"
        :disabled="$attrs.disabled"
        :placeholder="placeholder"
        @input="updateDate"/>
      <transition name="expand">
        <p
          v-show="errors.length > 0"
          v-html="errors[0]"
          class="error-msg"></p>
      </transition>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import GeneralTextField from '@elements/GeneralTextField';
import moment from 'moment';

export default {
  name: 'date-of-birth-field',
  components: {
    GeneralTextField,
    ValidationProvider,
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Object,
      default: () => ({
        required: true,
        date: true,
      }),
    },
    placeholder: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    format: {
      type: String,
      required: false,
      default: 'DD.MM.YYYY'
    },
    displayFormat: {
      type: String,
      required: false,
      default: 'DD.MM.YYYY'
    },
  },
  computed: {
    date: {
      get() {
        if (!this.value) {
          return '';
        }
        if (!this.isValidDate(this.value, this.format)) {
          return this.value;
        }
        return moment(this.value, this.format).format(this.displayFormat);
      },
      set(value) {
        if (!this.isValidDate(value, this.displayFormat)) {
          this.$emit('input', value);
          return;
        }
        // Emit date in 'YYYY-MM-DD' format
        const formattedDate = moment(value, this.displayFormat).format(this.format);
        this.$emit('input', formattedDate);
      },
    },
  },
  mounted() {
    this.initInputMask();
  },
  methods: {
    updateDate(val) {
      this.date = val;
    },
    isValidDate(date, format) {
      return moment(date, format, true).isValid();
    },
    initInputMask() {
      const currentDate = moment().format('DD.MM.YYYY');
      const maskOptions = {
        alias: 'datetime',
        inputFormat: this.displayFormat.toLowerCase(),
        placeholder: this.displayFormat,
        max: currentDate
      };

      window.Inputmask(maskOptions).mask(
        this.$refs.dobInput.$el.querySelector('input')
      );
    },
  },
};
</script>
