<template>
  <div class="nhst-validate-code-and-change-password d-flex flex-column">
    <ValidationObserver
      v-slot="{handleSubmit, invalid}"
      slim>
      <form
        @submit.prevent="handleSubmit(confirmVerificationCode)"
        :class="['validate-code-reset-password', 'd-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
        method="post">
        <div>
          <Modal
            ref="resendModal"
            :title="$t('reset.password.validate.code.resend.code.title')"
            :text="$t('reset.password.validate.code.resend.code.description', {email: username})"
            :show="showModal"
            :buttons="modalButtons"
            :show-captcha="true"
            @clicked="resendVerficationCode"/>
          <TextField
            :rules="{required: true, min: 6}"
            :field-name="$t('reset.password.validate.code.label')"
            :placeholder="$t('reset.password.validate.code.placeholder')"
            :disabled="disabled"
            @input="showConfirmBox = false"
            v-model="code"
            name="code">
          </TextField>
          <div class="resend-code">
            <a
              @click.prevent="showModal = true; showConfirmBox = false"
              href="#">
              {{ $t('reset.password.resend.code') }}
            </a>
          </div>
          <transition
            name="expand"
            mode="out-in">
            <PasswordField
              :field-name="$t('password.label')"
              :placeholder="$t('reset.password.complete.password.placeholder')"
              :disabled="disabled"
              @input="showConfirmBox=false"/>
          </transition>
        </div>
        <div>
          <transition
            name="expand"
            mode="out-in">
            <Confirmation
              v-if="showConfirmBox"
              :success="success"
              :message="confirmMsg.message"
              :title="confirmMsg.title"
              class="pb-2"/>
          </transition>
          <LoadingButton
            :disabled="submitting || disabled"
            :loading="submitting">
            {{ $t('reset.password.validate.code.button.text') }}
          </LoadingButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '../elements/LoadingButton';
import Confirmation from '../elements/Confirmation';
import { ajaxForm, autoLogin, emailSync, formDescriptionHander } from '@mixins';
import TextField from '@components/elements/TextField';
import Modal from '@components/elements/Modal';
import CustomButton from '@root/src/helpers/CustomButton';
import PasswordField from '@components/elements/PasswordField';

export default {
  name: 'nhst-validate-code-and-change-password',
  components: {
    PasswordField,
    Modal,
    TextField,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm, emailSync, autoLogin, formDescriptionHander],
  props: {
    description: {
      type: String,
      required: false,
      default: function () {
        return this.$t(
          'reset.password.validate.code.activate.subscription.description',
          { email: this.username }
        );
      }
    },
    title: {
      type: String,
      default: function () {
        return this.$t('reset.password.validate.code.activate.subscription.title');
      },
      required: false
    },
    shouldRedirectAfterSuccessfulLogin: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      code: '',
      showModal: false,
      watchForFormHeadingSubHeadingChanges: true,
    };
  },
  computed: {
    modalButtons: function () {
      return [
        new CustomButton(
          {
            text: this.$t('no'),
            value: 'no',
            class: 'btn-secondary',
            recaptchaRequired: false
          }),
        new CustomButton(
          {
            text: this.$t('yes'),
            value: 'yes',
            recaptchaRequired: true
          })
      ];
    },
    confirmMsgs: function () {
      return {
        201: {
          title: this.$t('reset.password.validate.code.201.title'),
          message: this.$t('reset.password.validate.code.201.description', { email: this.username })
        },
        404: {
          title: this.$t('reset.password.validate.code.404.title'),
          message: this.$t('reset.password.validate.code.404.description')
        },
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
    heading() {
      return this.title || '';
    },
  },
  watch: {
    description: {
      immediate: true,
      handler(val) {
        this.customFormDescriptionHandler();
      }
    },
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.heading;
      this.formSubHeading = this.description;
    },
    async resetPasswordCompleteSuccessWorkflow() {
      this.showConfirmBox = false;
      this.$emit('passwordResetSuccess');
      const loggedIn = await this.autoLogin(this.shouldRedirectAfterSuccessfulLogin);
      if (loggedIn) {
        this.$emit('loggedInSuccess');
      }
    },
    resetPasswordCompleteErrorWorkflow() {
    },
    resendValidateCodeSuccessWorkflow() {
      this.hideLoader();
      this.disabled = false;
      this.showConfirmBox = true;
    },
    resendValidateCodeErrorWorkflow() {
      this.hideLoader();
      this.showConfirmBox = true;
    },
    async resendVerficationCode(val) {
      const app = this;
      app.showModal = false;
      if (val === 'no') {
        return;
      }
      app.showLoader();
      return app.submitFormWithHandlers(
        () => app.subAuthClient.resetPasswordStart(this.username),
        true,
        () => app.resendValidateCodeSuccessWorkflow(),
        () => app.resendValidateCodeErrorWorkflow(),
        true
      );
    },
    confirmVerificationCode() {
      const app = this;
      return app.submitFormWithHandlers(
        () => app.authServices.resetPasswordComplete(
          this.username,
          this.password,
          this.code
        ),
        true,
        () => app.resetPasswordCompleteSuccessWorkflow(),
        () => app.resetPasswordCompleteErrorWorkflow()
      );
    },
  },
};
</script>
<style lang="scss">
.nhst-validate-code-and-change-password {
  height: 100%;

  .validate-code-reset-password {
    height: 100%;

    .resend-code {
      margin-top: -15px;
      margin-bottom: 30px;
    }

  }
}
</style>
