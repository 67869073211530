<script>
import LandingPage from './LandingPage';
import * as routes from '@router/constants';

export default {
  name: 'incorrect-user',
  components: {
    LandingPage,
  },
  methods: {
    logoutUser() {
      this.$router.push({
        name: routes.LOGOUT,
        query: {
          target: window.location.href,
        },
      });
    },
  },
};
</script>

<template>
  <LandingPage>
    <template #text>
      <p>
        Det ser ut som du er logget inn med en annen epostadresse enn den
        invitasjonen er sendt til. Du må logge deg ut av dn.no før du
        fortsetter. Vi har kun mulighet til å gi deg delt tilgang på den
        epostadressen invitasjonen er sendt til.
      </p>
      <p>
        <button
          @click.prevent="logoutUser"
          type="submit"
          class="btn-standard">
          Logg ut
        </button>
      </p>
    </template>
  </LandingPage>
</template>
