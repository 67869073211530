<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <div class="prev-username-outer">
          <TextField
            :value="prevUsername"
            :rules="{required: true}"
            :field-name="$t('username.label')"
            :placeholder="$t('username.placeholder')"
            disabled="true"
            name="prevUsername"/>
        </div>
        <div class="choose-username-outer mb-5">
          <div class="choose-username">
            <div class="title mb-3">
              {{ $t('choose.username.or.enter.new.title') }}
            </div>
            <div class="mb-4">
              {{ $t('choose.username.or.enter.new.instruction.1') }}
            </div>
            <div class="mb-4">
              <RadioButton
                v-for="choice in choices"
                :key="choice"
                :value="choice"
                :label="choice"
                v-model="chooseEmail"
                v-on:change="onChooseEmail"
                name="chooseEmail"/>
              <RadioButton
                :value="ENTER_NEW_USERNAME"
                :label="$t('enter.new.username')"
                v-model="chooseEmail"
                v-on:change="onChooseEmail"
                name="chooseEmail"/>
              <div
                class="mt-5">
                <EmailField
                  :rules="{required: chooseEmail === 'ENTER_NEW_USERNAME', email: true, email_available: true}"
                  @input="showConfirmBox = false"
                  :field-name="$t('email.label')"
                  :placeholder="$t('email.placeholder')"
                  :disabled="disabled"
                  v-show="chooseEmail === 'ENTER_NEW_USERNAME'"
                  name="email"/>
              </div>
            </div>
          </div>
        </div>
        <a
          :href="customerSupportPageLink"
          target="_blank">{{ $t('enter.new.username.instruction.2') }}</a>
      </div>
      <div>
        <Confirmation
          v-if="!success && showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="invalid || disabled"
          :loading="submitting">
          {{ $t('log.in') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>

import { ValidationObserver } from 'vee-validate';

import LoadingButton from '@root/src/components/elements/LoadingButton.vue';
import Confirmation from '@root/src/components/elements/Confirmation.vue';
import TextField from '@root/src/components/elements/TextField.vue';
import EmailField from '@root/src/components/elements/EmailField.vue';
import RadioButton from '@root/src/components/elements/RadioButton.vue';

import { ajaxForm, emailSync } from '@mixins';

export default {
  name: 'nhst-login-choose-username-or-enter-new',
  components: {
    TextField,
    EmailField,
    RadioButton,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm, emailSync],
  props: {
    prevUsername: {
      type: String,
      default: '',
      required: true
    },
    choices: {
      type: Array,
      default: () => [],
      required: true
    },
  },
  data() {
    return {
      ENTER_NEW_USERNAME: 'ENTER_NEW_USERNAME',
      customerSupportPageLink: '/contactus',
      chooseEmail: ''
    };
  },
  computed: {
    confirmMsgs: function () {
      return {
        401: {
          title: '',
          message: this.$t('wrong.credential')
        },
        409: {
          title: '',
          message: this.$t('reset.username.409')
        },
      };
    },
  },
  watch: {
    userExists: function (newVal) {
      console.log('(NHST Login - Choose Username Or Enter New) User Exists? -> ', newVal);
      if (newVal) {
        this.success = false;
        this.serverResponseCode = 409;
        this.showConfirmBox = true;
      }
    }
  },
  created() {
    this.email = '';
    this.chooseEmail = this.choices[0];
  },
  methods: {
    successWorkflow() {
      const app = this;
      app.email = app.chooseEmail === app.ENTER_NEW_USERNAME ? app.email : app.chooseEmail;
      app.$nextTick(() => app.$emit('success', app.email));
    },
    errorWorkflow() {
      console.log(`Failed to reset username => serverResponseCode: ${this.serverResponseCode}`);
    },
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = true;
    },
    onChooseEmail() {
      this.showConfirmBox = false;
      this.email = '';
      this.invalid = false;
    },
    submit() {
      const app = this;
      return app.submitForm(
        () => app.nhstAuthClient.resetUsername(
          this.prevUsername,
          this.chooseEmail === this.ENTER_NEW_USERNAME ? this.email : this.chooseEmail,
          this.password
        ),
        true
      );
    }
  },
};

</script>
<style lang="scss">
.choose-username {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;

  .title {
    font-weight: 650;
  }
}

</style>
