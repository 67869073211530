<template>
  <ClientOnly>
    <transition
      name="expand"
      mode="out-in">
      <div
        v-show="show"
        class="modal-overlay">
        <div class="pt-6 pb-2 mb-4 modal-container">
          <div class="grid mb-0">
            <div class="col-12 text">
              <h3 v-if="title"> {{ title }}</h3>
              <p
                v-if="text"
                class="text text-left">{{ text }}</p>
            </div>
          </div>
          <GoogleRecaptcha
            ref="grecaptcha"
            :show-captcha="showCaptcha"
            v-slot:default="{props}">
            <div class="container pb-3">
              <div class="row">
                <div
                  v-for="btn in buttons"
                  :class="['col-sm-' + parseInt(12/buttons.length), 'col-12', 'pb-3', 'd-flex', 'justify-content-center']">
                  <button
                    :class="[btn.class || 'btn-standard' ]"
                    :type="btn.type || 'button'"
                    :disabled="showCaptcha && btn.recaptchaRequired ? !props.recaptchaOk : btn.disabled || false"
                    @click="emitValue(btn.value)">
                    {{ btn.text }}
                  </button>
                </div>
              </div>
            </div>
          </GoogleRecaptcha>
        </div>
      </div>
    </transition>
  </ClientOnly>
</template>

<script>
import GoogleRecaptcha from '@components/GoogleRecaptcha';
import ClientOnly from '@components/ClientOnly';

export default {
  name: 'modal',
  components: {
    ClientOnly,
    GoogleRecaptcha,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    // array of CustomButton objects mentioned in helpers dir
    buttons: {
      type: Array,
      required: true
    },
    showCaptcha: {
      type: Boolean,
      required: false,
      default: false
    },
    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    emitValue(val) {
      this.$emit(val);
      this.$emit('clicked', val);
      if (this.showCaptcha) {
        this.$refs.grecaptcha.expiredCallback();
      }
    }
  },
};
</script>

<style lang="scss">

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(231, 231, 231, 0.9);
  border: #979797;
  z-index: 1002;

  .modal-container {
    z-index: 1004; // more than modal-overlay
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100%;
    max-width: 450px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 0 0 #F6F6F6;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    //@media screen and (max-width: @breakpointSmall) {
    //  width: 100%;
    //}
    button {
      max-width: 200px;
    }

    .text {
      text-align: justify;
      color: rgba(0, 0, 0, 0.8);

      &-left {
        text-align: left;
      }

      h3 {
        color: #323232;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

</style>
