export const EXTERNAL_CONFIGS = {
  STORAGE_KEYS: {
    ORDERFLOW_PURCHASED_PRODUCT: 'nhst-auth-purchased-product',
    VERIFIED_USERNAME: 'nhst-auth-verified-username',
    TARGET: 'target',
    PAYWALL_VARIATION: 'nhst-auth-paywall-variation',
  },
  EVENTS: {
    EMAIL_VERIFIED: 'nhst-auth-email-verified',
    GO_BACK: 'nhst-auth-go-back',
    RELOAD_PAGE: 'nhst-auth-reload-page',
    LOGIN_SUCCESS: 'nhst-auth-login-success',
    ALL_PUB_LOGOUT_SUCCESS: 'nhst-auth-all-pub-logout-success',
    CLOSE_POPUP: 'nhst-auth-close-popup',
    ZEPHR_SSO_POPUP_CLOSE: 'zephr-sso-popup-close',
    ZEPHR_SSO_STATUS: 'zephr-sso-status',
    SIZE: 'nhst-auth-size',
  },
};

export const ANALYTICS_CONFIGS = {
  EVENTS: {
    FREE_USER_REGISTRATION: 'FREE_USER_REGISTRATION',
    EMAIL_VERIFIED: 'EMAIL_VERIFIED'
  }
};
