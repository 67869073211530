<template>
  <div class="free-registration">
    <transition
      name="expand"
      mode="out-in">
      <component
        :title="title"
        :description="description"
        :shouldRedirectAfterSuccessfulLogin="false"
        :is="curComponent"
        @success="goForward"
        :showBackButton="false"
        :registerButtonText="registerButtonText"
        @loggedInSuccess="loggedInSuccessAction"
        @back="goBack" />
    </transition>
  </div>
</template>

<script>

import RegisterEmail from '@components/elements/RegisterEmail';
import AddUserInfo from '@components/elements/AddUserInfo';
import { NhstValidateCodeAndChangePassword } from '@components';
import { workFlowHelper } from '@mixins';

export default {
  name: 'nhst-free-user-registration',
  // will use component dynamically
  // eslint-disable-next-line vue/no-unused-components
  components: { RegisterEmail, AddUserInfo, NhstValidateCodeAndChangePassword },
  mixins: [workFlowHelper],
  data() {
    return {
      registerEmail: RegisterEmail,
      addUserInfo: AddUserInfo,
      nhstValidateCodeAndChangePassword: NhstValidateCodeAndChangePassword,
      steps: [RegisterEmail, AddUserInfo, NhstValidateCodeAndChangePassword],
      stepsVerbalName: [
        'Register Email',
        'Add User Info',
        'Validate Code And Change Password',
      ],
      pushAnalyticsEvents: true,
      title: this.$t('free.user.register.page.title'),
      description: '',
      componentVerbalName: 'Free User Registration',
      analyticsData: {},
      registerButtonText: this.$t('free.user.register.button.text')
    };
  },
  mounted() {
    if (this.isLoggedIn()) {
      window.top.location.href = this.getTarget();
    }
    this.analyticsData = {
      paywallVariation: this.$route.query.paywall_variation_type || '',
      flowSourceUrl: this.getTarget(''),
      registrationService: this.$authWebapp.getSource(),
      event: 'REGISTRATION_UPGRADE_ORDER_FLOW',
    };
  },
  methods: {
    analyticsEventHandler(eventName) {
      const app = this;
      const stepEventName = eventName || `${app.stepsVerbalName[app.curStep]}`;
      const event = {
        eventName: `${app.componentVerbalName} | ${stepEventName}`,
        ...this.analyticsData,
      };
      app.$authWebapp.pushAnalyticsEvent(event);
    },
    loggedInSuccessAction() {
      this.analyticsEventHandler('Email Verified');
      if (!this.isExternalView) {
        window.top.location.href = this.getPreviousPageUrl();
      }
    },
    doForwardWorkflow() {
      this.$store.commit('UPDATE_CURRENT_STEP', this.curStep);
      if (this.curComponent === this.nhstValidateCodeAndChangePassword) {
        this.title = this.$t('reset.password.validate.code.free.user.title');
        this.description = this.$t(
          'reset.password.validate.code.free.user.description',
          { email: this.username },
        );
      }
    },
    doBackWorkflow() {
      this.$store.commit('UPDATE_CURRENT_STEP', this.curStep);
    },
  },
};
</script>
<style scoped lang="scss">
.free-registration {
  &,
  & > * {
    height: 100%;
  }
}
</style>
