import { sso } from '@mixins';
import * as routes from '@router/constants';
import { FORCE_REDIRECT_TO_TARGET } from '@root/src/helpers/AppConfigs';

const ssoEligiblePage = {
  mixins: [sso],
  watch: {
    isSsoEligible: {
      immediate: true,
      handler(val) {
        if (val) {
          const query = {
            target: window.top.location.href,
            [FORCE_REDIRECT_TO_TARGET]: 'true'
          };
          if (this.$route.name !== routes.SSO) {
            if (this.$route.name === routes.LOGIN) {
              query.target = this.getTarget();
              this.$router.replace({ name: routes.SSO, query });
            } else {
              this.redirectedFrom = this.$route;
              this.$router.push({ name: routes.SSO, query });
            }
          }
        }
      }
    },
  }
};

export { ssoEligiblePage };
