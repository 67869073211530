<template>
  <AuthContainer>
    <template #form>
      <NhstCorporateRegistration v-if="!isDN" />
      <NhstIpRegistration v-else />
    </template>
  </AuthContainer>
</template>

<script>
import {
  NhstCorporateRegistration,
  AuthContainer,
  NhstIpRegistration
} from '../components';
import Page from '@pages/Page.js';

export default {
  name: 'corporate-registration-page',
  components: {
    NhstIpRegistration,
    AuthContainer,
    NhstCorporateRegistration
  },
  extends: Page,
  methods: {
    getTitle() {
      return `${this.$store.state.corporate.company.name || 'Corporate Page'} | ${this.publication.title}`;
    }
  }
};

</script>
