import {
  UPDATE_EMAIL,
  UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER,
  UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER
} from '@store';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';
import Utils from '@root/src/helpers/utils';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

const emailSync = {
  data() {
    return {
      // it will be true for email field and few other components, as that should not override anything
      avoidRunningMethodsOnMountedHook: false,
    };
  },
  methods: {
    // If turned off, then username existence check will be disabled with server for the component
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = false;
    },
    updateShouldValidateUserLoggedInBeforeWithServer() {
      this.shouldValidateUserLoggedInBeforeWithServer = false;
    },
  },
  computed: {
    email: {
      get: function () {
        return this.$store.state.nhstAuth.email;
      },
      set: function (val) {
        return this.$store.commit(`nhstAuth/${ UPDATE_EMAIL }`, (val || '').trim().toLowerCase());
      }
    },
    userExists: function () {
      return this.$store.state.nhstAuth.userExists;
    },
    serverSideCheckingFailed: function () {
      return this.$store.state.nhstAuth.userExistsCheckError;
    },
    serverSideChecking: function () {
      return this.$store.state.nhstAuth.serverSideChecking;
    },
    serverSideEmailChecking: function () {
      return this.isFieldBeingCheckedOnServerSide(LOADING_TYPES.EMAIL);
    },
    userLoggedInBefore: function () {
      return this.$store.state.nhstAuth.userLoggedInBefore;
    },
    corporateSsoUser: function () {
      return this.$store.state.nhstAuth.corporateSsoUser || {};
    },
    isCorporateSsoUser: function () {
      return Object.keys(this.corporateSsoUser || {}).length > 0;
    },
    shouldValidateWithServer: {
      get: function () {
        return this.$store.state.nhstAuth.shouldValidateWithServer;
      },
      set: function (val) {
        return this.$store.commit(`nhstAuth/${ UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER }`, val);
      }
    },
    shouldValidateUserLoggedInBeforeWithServer: {
      get: function () {
        return this.$store.state.nhstAuth.shouldValidateUserLoggedInBeforeWithServer;
      },
      set: function (val) {
        return this.$store.commit(`nhstAuth/${ UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER }`, val);
      }
    }
  },
  mounted() {
    this.email = this.email || this.$route.query.username || this.$route.query.email || Utils.readFromLocalStorage(
      EXTERNAL_CONFIGS.STORAGE_KEYS.VERIFIED_USERNAME
    );
    if (this.avoidRunningMethodsOnMountedHook) {
      return;
    }
    this.updateShouldValidateUsernameWithServer();
    this.updateShouldValidateUserLoggedInBeforeWithServer();
  }
};

export { emailSync };
