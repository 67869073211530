import { UPDATE_SHOW_CLOSE_ICON } from '@store';

const closeIconController = {
  created() {
    this.$store.commit(UPDATE_SHOW_CLOSE_ICON, this.shouldShowCloseIcon());
  },
  computed: {
    showCloseIcon() {
      return this.$store.state.showCloseIcon;
    }
  },
  methods: {
    shouldShowCloseIcon() {
      return false;
    },
    clickedCloseIcon() {
      this.$store.commit(UPDATE_SHOW_CLOSE_ICON, false);
    },
    handleCloseIconClicked() {
      throw Error('handleCloseIconClicked needs to be implemented!!');
    }
  },
  watch: {
    showCloseIcon: {
      immediate: true,
      handler(newVal, oldVal) {
        // oldVal=true and newVal=false -> close icon was clicked
        // as we are setting showCloseIcon to false when someone clicks it
        if (!newVal && oldVal) {
          this.handleCloseIconClicked();
        }
      }
    }
  }
};

export { closeIconController };
