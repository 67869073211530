<template>
  <form
    :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
    @submit.prevent="ssoLogout">
    <div class="text-center">
      <Confirmation
        v-if="showConfirmBox"
        :success="success"
        :message="confirmMsg.message"
        :title="confirmMsg.title"
        class="pb-2"/>
      <LoadingButton
        :disabled="submitting || disabled"
        :loading="submitting"
        button-class="btn-red mt-0"> {{ $t('logout') }}
      </LoadingButton>
      <button
        @click.prevent="$emit('ssoLogin')"
        type="button"
        class="btn-secondary mt-5">
        {{ $t('cancel') }}
      </button>
    </div>
  </form>
</template>

<script>

import { ajaxForm, closeIconController, formDescriptionHander } from '@mixins';
import LoadingButton from '@components/elements/LoadingButton';
import Confirmation from '@components/elements/Confirmation';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

export default {
  name: 'sso-logout',
  components: {
    LoadingButton,
    Confirmation
  },
  mixins: [ajaxForm, closeIconController, formDescriptionHander],
  methods: {
    handleCloseIconClicked() {
      this.sendSsoPopupCloseEvent(false);
    },
    customFormDescriptionHandler() {
      this.formHeading = this.$t('are.you.sure');
      this.formSubHeading = this.$t('nhst.id.logout.description');
      return true;
    },
    shouldShowCloseIcon() {
      return this.shouldShowSsoCloseIcon;
    },
    ssoLogoutSuccessHandler() {
      this.showConfirmBox = false;
      this.$postMessage.send(
        EXTERNAL_CONFIGS.EVENTS.ALL_PUB_LOGOUT_SUCCESS
      );
      this.closePopupOrRedirectPrevUrl(true);
    },
    ssoLogoutErrorHandler() {

    },
    ssoLogout() {
      const app = this;
      app.submitFormWithHandlers(
        () => app.authServices.logoutFromThisBrowserWhenSsoStatusIsKnown(),
        true,
        app.ssoLogoutSuccessHandler,
        app.ssoLogoutErrorHandler,
        false
      );
    }
  }
};
</script>
