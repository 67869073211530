const ajaxForm = {
  data: function () {
    return {
      disabled: false,
      success: false,
      submitting: false,
      showConfirmBox: false,
      serverResponseCode: 500,
      serverResponseMessage: '',
      serverResponseHeaders: new Map(),
    };
  },
  computed: {
    confirmMsgs: function () {
      // needs to implemented by components that uses this mixins
      return {
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
    confirmMsg: function () {
      return this.confirmMsgs[this.serverResponseCode] || { title: '', message: this.$t('auth.error.unexpected') };
    }
  },
  methods: {
    getConfirmMsg(code) {
      return this.confirmMsgs[code] || { title: '', message: this.$t('auth.error.unexpected') };
    },
    successWorkflow() {
      throw new Error('You need to implement this yourself!');
    },
    errorWorkflow() {
      throw new Error('You need to implement this yourself!');
    },
    async submitFormWithHandlers(
      clientSubmitFunction,
      disableFormOnSuccess = false,
      successHandler = () => {
      },
      errorHandler = () => {
      },
      useGoogleRecaptcha = false
    ) {
      const app = this;
      app.submitting = true;
      app.showConfirmBox = false;
      app.disabled = true;
      return clientSubmitFunction().then(resp => {
        app.success = true;
        app.serverResponseCode = resp.status;
        app.disabled = disableFormOnSuccess;
        app.serverResponseMessage = resp.data || {};
        app.serverResponseHeaders = resp.headers || new Map();
      }).catch((err) => {
        app.success = false;
        if (err.response) {
          app.serverResponseCode = err.response.status;
          app.serverResponseMessage = err.response.data ? err.response.data : '';
          app.serverResponseHeaders = err.response.headers || new Map();
        }
        app.disabled = false;
      }).finally(() => {
        app.submitting = false;
        app.showConfirmBox = true;
        // handers should be after all default code so that the handlers can override the default behavior
        if (app.success) {
          successHandler();
        } else {
          errorHandler();
        }
        if (useGoogleRecaptcha) {
          window.gRToken = '';
          if (app.$refs && app.$refs.grecaptcha) {
            app.$refs.grecaptcha.expiredCallback();
          }
        }
      });
    },
    submitForm(clientSubmitFunction, disableFormOnSuccess = false, useRecaptcha = false) {
      return this.submitFormWithHandlers(
        clientSubmitFunction,
        disableFormOnSuccess,
        this.successWorkflow,
        this.errorWorkflow,
        useRecaptcha
      );
    },
  }
};

export { ajaxForm };
