import { UPDATE_SHOW_PUBLICATION_LOGOS } from '@store';

const showPublicationLogos = {
  data() {
    return {
      showingPublicationLogosInThisComponent: false
    };
  },
  mounted() {
    this.showTermsAndConditions();
  },
  beforeDestroy() {
    this.hideTermsAndConditions();
  },
  methods: {
    showTermsAndConditions() {
      if (this.showingPublicationLogosInThisComponent) {
        return;
      }
      this.showingPublicationLogosInThisComponent = true;
      this.$store.commit(UPDATE_SHOW_PUBLICATION_LOGOS, 1);
    },
    hideTermsAndConditions() {
      if (!this.showingPublicationLogosInThisComponent) {
        return;
      }
      this.showingPublicationLogosInThisComponent = false;
      this.$store.commit(UPDATE_SHOW_PUBLICATION_LOGOS, -1);
    },
    getRedirectLocation() {
      throw Error('You need to implement this yourself!');
    },
    getScript() {
      // add script in server side. This will be executed in client side.
      // dont use cookies to take
      return `<script>
const cookies = document.cookie;
const oneid_info = document.cookie.match(/(^| )oneid_info=(?<oneid_info>[^;]+);?/);
if (oneid_info && oneid_info.groups.oneid_info) {
  window.location.href = "${this.getRedirectLocation()}";
}
</script>
`;
    }
  }
};

export { showPublicationLogos };
