<template>
  <ClientOnly>
    <div
      class="terms-and-conditions">
      <CheckboxField
        v-if="showTermsAndConditions"
        :rules="{required: true}"
        :field-name="$t('terms.conditions')"
        v-model="termsAccepted"
        :text="title"
        class="mt-0 mb-0"/>
      <slot name="container"></slot>
      <FreeRegistrationPrivacy v-if="isFreeUserRegForm"></FreeRegistrationPrivacy>
      <div v-else>
      <div
        v-if="showTermsAndConditions"
        class="privacy-description mt-5 p-4 pt-5">
        <div class="row">
          <div class="col-1 small-device-p-0">
            <InlineSvg
              :src="$cdn.resource('lock.svg')"
              alt="lock"/>
          </div>
          <div class="col-10 col-md-11 privacy-note">
            <p><b>{{ $t('privacy.text') | capitalize }}</b></p>
            <div v-html="body"></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </ClientOnly>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import ClientOnly from '@components/ClientOnly';
import CheckboxField from '@elements/CheckboxField';
import { UPDATE_SHOW_PUBLICATION_LOGOS } from '@store';
import FreeRegistrationPrivacy from '@components/elements/FreeRegistrationPrivacy';
export default {
  name: 'terms-and-conditions',
  components: { CheckboxField, ClientOnly, InlineSvg, FreeRegistrationPrivacy },
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('terms.and.conditions.accept');
      },
      required: true
    },
    body: {
      type: String,
      default: function () {
        return this.$t('terms.and.conditions.body', { pub: this.publication.title });
      },
      required: true
    },
    showTermsAndConditions: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      showSection: false,
      termsAccepted: false,
    };
  },
  watch: {
    showTermsAndConditions: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$store.commit(UPDATE_SHOW_PUBLICATION_LOGOS, val ? 1 : -1);
      }
    }
  },
  beforeDestroy() {
    if (this.showTermsAndConditions) {
      this.$store.commit(UPDATE_SHOW_PUBLICATION_LOGOS, -1);
    }
  },

  methods: {
    toggle() {
      this.showSection = !this.showSection;
    },
  }
};
</script>

<style scoped lang="scss">
@import "@assets/scss/variables.scss";

$privacy-body-color: #F1F3F6;

.terms-and-conditions {
  .privacy-description {
    background-color: $privacy-body-color;

    .privacy-note {
      padding-left: 20px;
    }
  }

  .small-device-p-0 {
    @media screen and (max-width: $sm) {
      padding: 0 !important;
    }
  }
}

</style>
