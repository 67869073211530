<template>
  <ValidationObserver
    v-slot="{handleSubmit}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
      method="post">
      <div>
        <TextField
          v-model="user.name"
          :placeholder="$t('name.placeholder')"
          :field-name="$t('name.label')"
          :disabled="disabled"
          :rules="{required: true}"/>
        <DateOfBirthField
          v-if="isDN"
          v-model="user.birth_date"
          :rules="{ required: true, date: true,min_age:16, }"
          :field-name="$t('birth.date')"
          :disabled="disabled"
          :format="serverAcceptedFormat"/>
        <CheckboxField
          :rules="{required: true}"
          :field-name="$t('terms.conditions')"
          v-model="user.terms_accepted"
          :disabled="disabled"
          :text="$t('free.registration.terms.conditions')"/>
      </div>
      <div>
        <!--    should not be v-if -->
        <Confirmation
          v-if="showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="submitting || disabled"
          :loading="submitting"
          button-class="mt-0 btn-standard">
          {{ $t('continue') }}
        </LoadingButton>
        <button
          @click="$emit('back')"
          :disabled="disabled || submitting"
          type="button"
          class="btn-secondary mt-3">
          {{ $t('go.back') }}
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '@elements/LoadingButton';
import { ajaxForm, formDescriptionHander } from '@mixins';
import Confirmation from '@elements/Confirmation';
import TextField from '@elements/TextField';
import Utils from '@root/src/helpers/utils';
import CheckboxField from '@elements/CheckboxField';
import DateOfBirthField from '@elements/DobField';

export default {
  name: 'add-user-info',
  components: {
    CheckboxField,
    TextField,
    Confirmation,
    LoadingButton,
    ValidationObserver,
    DateOfBirthField,
  },
  mixins: [ajaxForm, formDescriptionHander],
  data() {
    return {
      user: {
        name: '',
        birth_date: '',
        terms_accepted: false
      },
      source: this.$authWebapp.getSource(),
      dateFormat: Utils.getDefaultDateFormat(),
      serverAcceptedFormat: 'YYYY-MM-DD',
    };
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.$t('free.user.register.step.2.page.title');
    },
    successWorkflow() {
      this.$emit('success');
    },
    errorWorkflow() {
    },
    submit() {
      const app = this;
      const articleId = this.$route.query.article_id ||
        this.$authWebapp.getArticleId(this.getTarget('/')) ||
        '';
      const data = {
        username: this.username,
        article_id: articleId,
        user_tag: 'free-user',
        ...this.user,
      };
      app.submitForm(
        () => app.nhstAuthClient.registerFreeUser(data, app.source),
        true
      );
    },
  },
};
</script>
