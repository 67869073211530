<template>
  <div class="free-registration">
    <transition
      name="expand"
      mode="out-in">
      <component
        :is="curComponent"
        :title="title"
        :description="description"
        @success="goForward"
        @back="goBack"
        register-for-mvp/>
    </transition>
  </div>
</template>

<script>

import RegisterEmail from '@elements/RegisterEmail';
import { NhstValidateCodeAndChangePassword } from '@components';
import { workFlowHelper } from '@mixins';

export default {
  name: 'nhst-free-user-registration',
  // will use component dynamically
  // eslint-disable-next-line vue/no-unused-components
  components: { RegisterEmail, NhstValidateCodeAndChangePassword },
  mixins: [workFlowHelper],
  data() {
    return {
      registerEmail: RegisterEmail,
      nhstValidateCodeAndChangePassword: NhstValidateCodeAndChangePassword,
      steps: [RegisterEmail, NhstValidateCodeAndChangePassword],
      pushAnalyticsEvents: true,
      title: '',
      description: ''
    };
  },
  methods: {
    doForwardWorkflow() {
      if (this.curComponent === this.nhstValidateCodeAndChangePassword) {
        this.title = this.$t('reset.password.validate.code.free.user.title');
        this.description = this.$t('reset.password.validate.code.free.user.description', { email: this.username });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.free-registration {
  &, & > * {
    height: 100%;
  }

}
</style>
