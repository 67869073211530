<template>
  <div class="form-steps">
    <span
      v-for="(step, idx) in steps"
      :class="['step', {active: (idx +1) == active }]">{{ idx + 1 }}. {{ step }}</span>
  </div>
</template>

<script>
export default {
  name: 'form-steps',
  props: {
    active: {
      type: Number,
      default: 0
    },
  },
  computed: {
    steps() {
      return [this.$t('form.step.1'),
        this.$t('form.step.2'),
        this.$t('form.step.3')];
    },
  }
};
</script>

<style scoped lang="scss">
.form-steps {
  padding: 0 40px 20px;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
  margin: 20px 0;
  border-bottom: .5px solid rgba(55, 78, 121, .2);
  white-space: nowrap;

  .step {
    display: inline-block;
    margin-right: 20px;
    opacity: 0.5;
    color: #00335A;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
    .active {
      opacity: 1;
      font-weight: 700;
    }
}
.hydrogen{
  .form-steps{
    .active{
      color:#638977;
    }
  }
}

</style>
