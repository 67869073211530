import {
  UPDATE_HAS_CONCURRENCY_LIMIT_EXCEEDED,
  UPDATE_SSO_DATA_FETCHED,
  UPDATE_SSO_META,
  UPDATE_SSO_STATUS
} from '@store/mutation-types';
import { DISPATCH_HAS_CHECK_CONCURRENCY_EXCEEDED, RETRIEVE_SSO_STATUS, UPDATE_SSO_STATUS_OFFLINE } from '@store';
import Utils from '@root/src/helpers/utils';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';

const mutations = {
  [UPDATE_SSO_STATUS](state, status) {
    state.user = status;
  },
  [UPDATE_SSO_META](state, meta) {
    state.user = Utils.deepMergeWithArrayOverwrite(state.user || {}, { meta: meta });
  },
  [UPDATE_SSO_DATA_FETCHED](state, val) {
    state.fetched = val;
  },
  [UPDATE_HAS_CONCURRENCY_LIMIT_EXCEEDED](state, val) {
    state.hasConcurrencyLimitExceeded = val;
  }
};

const updateSsoStatusInLocalStorage = (value, publication) => {
  if (!isServer) {
    const message = {
      type: EXTERNAL_CONFIGS.EVENTS.ZEPHR_SSO_STATUS,
      ssoUser: value,
      publication: publication
    };
    localStorage.setItem('sso-status', JSON.stringify(message));
    try {
      if (!window.top.location.pathname.includes(`${dconf.BASE_PATH}/`)) {
        window.top.postMessage(message, window.top.location.origin);
      }
    } catch (e) {
      logger.error(`[sso status post message sending] ${e}`);
    }
  }
};

const updateHasConcurrencyLimitExceededInLocalStorate = (value) => {
  if (!isServer) {
    const message = {
      type: 'zephr-concurrency-exceeded',
      value: value
    };
    localStorage.setItem('concurrency-exceeded', JSON.stringify(message));
  }
};

const fetchSsoStatus = async () => {
  let ssoStatus = {};
  try {
    let res = await zephrPublicClient.getSsoStatus();
    res = res.data;
    ssoStatus = res || {};
  } catch (e) {
    logger.error(`[sso status retrieve err] ${e}`);
  }
  return ssoStatus;
};

const fetchHasConcurrencyLimitExceededStatus = async () => {
  let hasConcurrencyExceeded = false;
  try {
    let res = await nhstAuthClient.hasConcurrencyLimitExceeded();
    res = res.data;
    hasConcurrencyExceeded = (res.message === 'true');
  } catch (e) {
    logger.error(`[fetch concurrency limit exceed error] ${e}`);
  }
  return hasConcurrencyExceeded;
};

const actions = {
  async [UPDATE_SSO_STATUS_OFFLINE]({ commit, rootState }, ssoStatus) {
    commit(UPDATE_SSO_STATUS, ssoStatus);
    updateSsoStatusInLocalStorage(ssoStatus, rootState.publication.name);
    return ssoStatus;
  },
  async [RETRIEVE_SSO_STATUS]({ commit, rootState }) {
    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.FULL_PAGE);

    commit(UPDATE_SSO_DATA_FETCHED, false);
    const ssoStatus = await fetchSsoStatus();
    commit(UPDATE_SSO_STATUS, ssoStatus);

    if (ssoStatus.status === 'known') {
      const hasConcurrencyExceeded = await fetchHasConcurrencyLimitExceededStatus();
      commit(UPDATE_HAS_CONCURRENCY_LIMIT_EXCEEDED, hasConcurrencyExceeded);
      updateHasConcurrencyLimitExceededInLocalStorate(hasConcurrencyExceeded);
    }

    commit(UPDATE_SSO_DATA_FETCHED, true);
    updateSsoStatusInLocalStorage(ssoStatus, rootState.publication.name);

    window.$vue.$authWebapp.finishServerSideCheckingForField(LOADING_TYPES.FULL_PAGE);
  },
  async [DISPATCH_HAS_CHECK_CONCURRENCY_EXCEEDED]({ commit }) {
    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.FULL_PAGE);

    const hasConcurrencyExceeded = await fetchHasConcurrencyLimitExceededStatus();
    commit(UPDATE_HAS_CONCURRENCY_LIMIT_EXCEEDED, hasConcurrencyExceeded);
    updateHasConcurrencyLimitExceededInLocalStorate(hasConcurrencyExceeded);

    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.FULL_PAGE);
  }
};

const store = {
  namespaced: true,
  state: {
    user: {},
    hasConcurrencyLimitExceeded: false,
    // whether data has been fetched
    fetched: false
  },
  mutations,
  actions,
};

export { store as ssoStore };
