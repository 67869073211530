<template>
  <div
    :class="[
      'general-textfield',
      error ? 'error-border' : '',
      $attrs.disabled ? 'disabled' : '',
    ]">
    <div class="label-wrapper">
      <label>{{ fieldName }}</label>
    </div>
    <div class="input-field-wrapper d-flex">
      <input
        ref="input"
        v-model="inputVal"
        :class="{ 'chrome-auto-fill': chromeAutoFill }"
        :placeholder="($attrs.placeholder || '') | capitalize"
        v-bind="$attrs"
        :autocomplete="autocomplete"
        @keydown="handleKeyPress"
        @keyup="handleKeyPress" />
      <slot name="append-icon"></slot>
    </div>
  </div>
</template>

<script>
import { UPDATE_AUTOCOMPLETE } from '@store';
const AUTCOMPLETE_OFF_VAL = 'one-time-code';
const AUTCOMPLETE_ON_VAL = '';

export default {
  name: 'general-text-field',
  props: {
    fieldName: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: String,
      default: '',
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableAutocomplete: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      chromeAutoFill: false,
      backspace: false,
    };
  },
  computed: {
    autocomplete: {
      get() {
        if (this.disableAutocomplete) {
          return this.$store.state.autocomplete;
        }

        return '';
      },
      set(val) {
        this.$store.commit(UPDATE_AUTOCOMPLETE, val);
      },
    },
    inputVal: {
      get() {
        return this.value;
      },
      /**
       * Sets input value by propagating it to the parent component
       *
       * Due to strange behavior of chrome password autofill resetting
       * username and password whenever we select something from the dropdown
       * We need special handling underneath
       *
       * @param {string} val - New value for the input
       */
      set(val) {
        const app = this;

        if (app.disableAutocomplete && app.chromeAutoFill) {
          app.autocomplete = AUTCOMPLETE_OFF_VAL;
          setTimeout(() => {
            if (!app.backspace) {
              app.autocomplete = AUTCOMPLETE_ON_VAL;
            }
          }, 2000);
        }

        if (app.disableAutocomplete) {
          // Set unnoticeable timeout so that autocomplete will be picked up
          setTimeout(() => {
            app.$emit('input', val);
          }, 1);
        } else {
          app.$emit('input', val);
        }
      },
    },
  },
  /**
   * When mounted we want to add event listener to the input element for dummy
   * animations. Since chrome autofill doesn't don't always send change event, we
   * use CSS and animation to detect opening and closing of the autofill.
   */
  mounted() {
    const app = this;

    app.$refs.input.addEventListener &&
      app.$refs.input.addEventListener('animationstart', (e) => {
        // This animation will be started whenever selection is changed
        if (e.animationName.indexOf('onAutoFillStart') !== -1) {
          app.chromeAutoFill = true;
        } else {
          app.chromeAutoFill = false;
        }
      });

    setTimeout(() => {
      app.autocomplete = AUTCOMPLETE_ON_VAL;
    }, 1500);
  },
  methods: {
    handleKeyPress(event) {
      if (this.disableAutocomplete) {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          this.backspace = true;
          this.autocomplete = AUTCOMPLETE_OFF_VAL;
        } else {
          this.backspace = false;
          this.autocomplete = AUTCOMPLETE_ON_VAL;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';

/*
Animations for detecting webkit autofill shouldn't be noticeable for the user.
Added changing opacity for a small value that because if left empty it will
be removed by optimizer.
*/
@keyframes onAutoFillStart {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.999;
  }
}

@keyframes onAutoFillCancel {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.999;
  }
}

.general-textfield {
  &.error-border {
    .input-field-wrapper {
      border: 1px solid $error-msg-color !important;
    }
  }
  &.disabled {
    opacity: $disabled-opacity;
  }
  font-weight: 400;
  .label-wrapper {
    label {
      text-transform: uppercase;
      font-size: $label-font-size;
      line-height: $label-line-height;
    }
    margin-bottom: $label-margin-bottom;
    @media screen and (min-width: $sm) {
      margin-bottom: $label-margin-bottom-md;
    }
  }
  .input-field-wrapper {
    border-radius: 4px;
    margin-bottom: $input-margin-bottom;

    input {
      font-size: $input-font-size;
      outline: none;
      border: 0;
      border-radius: 4px;
      padding: $input-padding;
      color: $input-color;
      transition: 0.1s ease-out;

      &::placeholder {
        font-size: $input-font-size;
      }

      &:-webkit-autofill {
        // Expose a hook for JavaScript when auto fill is shown.
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillStart;

        transition: opacity 500000s ease-in-out 0s;
      }

      &:not(:-webkit-autofill) {
        // Expose a hook for JS onAutoFillCancel
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillCancel;
      }
    }
  }
}

/*
When we don't want to allow user to change content of the field but still
want content to be visible
 */
.disabled-field .general-textfield.disabled input {
  opacity: 1;
}
</style>
