<template>
  <ValidationProvider
    ref="email"
    :name="fieldName"
    :rules="rules"
    :immediate="immediate"
    v-slot="{ errors }"
    :mode="mode"
    slim>
    <GeneralTextField
      v-model="email"
      :error="errors.length > 0 && !Boolean(serverSideEmailChecking)"
      @input="update"
      :field-name="fieldName"
      v-bind="$attrs"
      :class="['email-field']"
      disable-autocomplete>
      <template #append-icon>
        <transition
          name="expand"
          mode="out-in">
          <TextlFieldLoader v-show="serverSideEmailChecking" />
        </transition>
      </template>
    </GeneralTextField>
    <transition
      name="expand"
      mode="out-in">
      <p
        v-show="errors.length > 0 && !Boolean(serverSideEmailChecking)"
        v-html="errors[0]"
        class="error-msg"></p>
    </transition>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { emailSync } from '@mixins';
import GeneralTextField from '@elements/GeneralTextField';
import TextlFieldLoader from '@elements/TextlFieldLoader';

export default {
  name: 'email-field',
  components: {
    TextlFieldLoader,
    GeneralTextField,
    ValidationProvider,
  },
  mixins: [emailSync],
  props: {
    fieldName: {
      type: String,
      default: '',
      required: false,
    },
    rules: {
      default: () => {},
      type: Object,
      required: false,
    },
    mode: {
      default: 'eager',
      required: false,
      type: String,
    },
  },
  data() {
    return {
      immediate: false,
      avoidRunningMethodsOnMountedHook: true,
    };
  },
  mounted() {
    this.immediate = Boolean(this.email);
  },
  methods: {
    update: function () {
      this.$emit('input', this.email);
    },
  },
};
</script>
<style lang="scss">
.email-field {
  input {
    background-color: #f7f7f7;
  }
}
</style>
