<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <PasswordField
          :field-name="$t('password.label')"
          :placeholder="$t('reset.password.complete.password.placeholder')"
          @input="showConfirmBox=false"/>
      </div>
      <div>
        <Confirmation
          v-if="showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="submitting || disabled"
          :loading="submitting">
          {{ buttonText || $t('reset.password.complete.button.text') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import PasswordField from '../elements/PasswordField';
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '../elements/LoadingButton';
import Confirmation from '../elements/Confirmation';
import { ajaxForm, autoLogin } from '@mixins';

export default {
  name: 'nhst-reset-password-complete',
  components: {
    Confirmation,
    LoadingButton,
    PasswordField,
    ValidationObserver
  },
  mixins: [ajaxForm, autoLogin],
  props: {
    code: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  computed: {
    confirmMsgs: function () {
      return {
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
  },
  methods: {
    async successWorkflow() {
      this.showConfirmBox = false;
      if (!this.isMobileView) {
        const type = this.$route.query.type || '';
        await this.autoLogin(true, type.toLowerCase().includes('activation'));
      }
      this.$emit('success');
    },
    errorWorkflow() {
    },
    submit() {
      const app = this;
      return app.submitForm(
        () => app.authServices.resetPasswordComplete(
          this.username,
          this.password,
          this.code
        ),
        true
      );
    }
  },
};
</script>
