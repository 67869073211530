<template>
  <form
    :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
    @submit.prevent="ssoLogin">
    <div>
      <Confirmation
        v-if="showConfirmBox"
        :success="success"
        :message="confirmMsg.message"
        :title="confirmMsg.title"
        class="pb-2"/>
      <LoadingButton
        :disabled="submitting || disabled"
        :loading="submitting"
        button-class="btn-standard mt-0">
        {{ $t('continue') }}
      </LoadingButton>
      <div class="text-center">
        <button
          @click.prevent="closePopup"
          class="sso-logout btn-secondary">{{ $t('logout') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import { ajaxForm, closeIconController, formDescriptionHander, showPublicationLogos, sso } from '@mixins';
import LoadingButton from '@components/elements/LoadingButton';
import Confirmation from '@components/elements/Confirmation';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

export default {
  name: 'sso-auto-login',
  components: { Confirmation, LoadingButton },
  mixins: [ajaxForm, sso, closeIconController, formDescriptionHander, showPublicationLogos],
  computed: {
    confirmMsgs: function () {
      return {
        429: {
          title: '',
          message: this.$t('concurrent.session.exceeded')
        }
      };
    },
  },
  methods: {
    shouldShowCloseIcon() {
      return this.shouldShowSsoCloseIcon;
    },
    customFormDescriptionHandler() {
      if (isServer) {
        return false;
      }
      this.formHeading = this.$t('sso.auto.login.title', { email: this.ssoEmail });
      this.formSubHeading = this.$t('sso.auto.login.description', { pub: this.publication.title });
      return true;
    },
    async ssoSuccessHandler() {
      this.showConfirmBox = false;
      this.$postMessage.send(
        EXTERNAL_CONFIGS.EVENTS.LOGIN_SUCCESS
      );
      this.closePopupOrRedirectPrevUrl(true);
    },
    async closePopup() {
      await this.updateSsoMeta();
      this.closePopupOrRedirectPrevUrl(false);
    },
    ssoErrorHandler() {
      this.updateSsoMeta();
    },
    // when user clicks close icon we should auto login the user as we said that in the message/UI
    handleCloseIconClicked() {
      this.ssoLogin();
    },
    ssoLogin() {
      const app = this;
      this.submitFormWithHandlers(
        () => app.authServices.ssoLogin(
          this.publication.name,
          this.ssoMeta
        ),
        true,
        app.ssoSuccessHandler,
        app.ssoErrorHandler,
        false,
      );
    }
  }
};
</script>

<style lang="scss">
a.sso-logout {
  font-size: 18px;
}
</style>
