<template>
  <AuthContainer>
    <template #form>
      <SSO/>
    </template>
  </AuthContainer>
</template>

<script>
import * as routes from '@router/constants';
import AnonymousPage from '@pages/AnonymousPage';
import { AuthContainer, SSO } from '@components';
import { FORCE_REDIRECT_TO_TARGET } from '@root/src/helpers/AppConfigs';

export default {
  name: 'sso-page',
  components: {
    AuthContainer,
    SSO,
  },
  extends: AnonymousPage,
  watch: {
    isSsoDataFetched: {
      immediate: true,
      handler(val) {
        if (val && !this.isSsoEligible) {
          if (this.isLoggedIn() && this.$route.query[FORCE_REDIRECT_TO_TARGET] === 'true') {
            window.top.location.href = this.getTarget('/');
            return;
          }
          this.$router.push({ name: routes.LOGIN });
        }
      }
    }
  },
  methods: {
    getTitle() {
      return `SSO | ${this.publication.title}`;
    },
    getRedirectLocation() {
      return '/';
    }
  }

};
</script>
