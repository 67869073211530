<template>
  <div>
    <!--    should be rendered only in client side-->
    <ClientOnly>
      <div
        v-if="showCaptcha"
        :id="containerId"
        class="g-recaptcha mt-4 mb-4"></div>
    </ClientOnly>
    <slot :props="{recaptchaOk: recaptchaClicked, expiredCallback: expiredCallback}"></slot>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ClientOnly from '@components/ClientOnly';

export default {
  name: 'google-recaptcha',
  components: { ClientOnly },
  props: {
    showCaptcha: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    const containerId = `g-recaptcha-${uuidv4()}`;
    return {
      widgetId: '',
      containerId,
      googleRecaptcha: '',
      recaptchaClicked: false,
    };
  },
  watch: {
    showCaptcha: {
      // immediate is true in order to render during mount
      immediate: true,
      handler(newValue, oldValue) {
        if (!isServer && newValue && !oldValue) {
          this.render();
        }
      }
    }
  },
  mounted() {
    this.addRecaptchaScript();
  },
  methods: {
    addRecaptchaScript() {
      if (!dconf.ENABLE_RECAPTCHA) {
        this.recaptchaClicked = true;
        return;
      }
      const recaptchaScriptId = 'g-recaptcha-script';
      if (document.getElementById(recaptchaScriptId)) {
        return;
      }
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=explicit&hl=${this.publication.lang}`;
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      recaptchaScript.id = recaptchaScriptId;
      document.getElementsByTagName('head')[0].appendChild(recaptchaScript);
    },
    async render() {
      const app = this;
      while (!window.grecaptcha) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      app.googleRecaptcha = window.grecaptcha.enterprise;
      await new Promise((resolve) => {
        app.googleRecaptcha.ready(async () => {
          app.widgetId = await app.googleRecaptcha.render(app.containerId, {
            sitekey: window.dconf.recaptchaSiteKey,
            callback: app.handleRecaptcha,
            'expired-callback': app.expiredCallback,
          });
          resolve();
        });
      });
    },
    async handleRecaptcha(response) {
      this.recaptchaClicked = true;
      window.gRToken = response;
    },
    async expiredCallback() {
      this.googleRecaptcha.reset(this.widgetId);
      this.recaptchaClicked = false;
    }
  }
};
</script>
<style lang="scss">
.g-recaptcha {
  div {
    width: 100% !important;

    div {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
