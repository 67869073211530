<template>
  <AuthContainer>
    <template #form>
      <NhstExistingUserRegistration/>
    </template>
  </AuthContainer>
</template>

<script>
import {
  AuthContainer,
  NhstExistingUserRegistration
} from '../components';

import AnonymousPage from '@pages/AnonymousPage';

export default {
  name: 'existing-user-registration-page',
  components: {
    NhstExistingUserRegistration,
    AuthContainer,
  },
  extends: AnonymousPage,
  methods: {
    getTitle() {
      return `${this.$t('registration.page.title')} | ${this.publication.title}`;
    },
    getRedirectLocation() {
      return '/';
    },
  }
};

</script>
