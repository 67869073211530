<template>
  <div class="reset-password d-flex flex-column">
    <div class="component flex-grow-1">
      <transition
        name="expand"
        mode="out-in">
        <NhstResetPasswordStart
          ref="reset_password_start"
          v-if="steps[curStep] === START_RESET_PASSWORD"
          @success="gotoValidateCodeWorkflow"
          :forCampaign="forCampaign"
          class="form-component"/>
      </transition>
      <transition
        name="expand"
        mode="out-in">
        <NhstResetPasswordValidateCode
          ref="validate_code"
          v-if="steps[curStep] === VALIDATE_CODE"
          @success="validateCodeSuccessWorkflow"
          class="form-component"/>
      </transition>
      <transition
        name="expand"
        mode="out-in">
        <NhstResetPasswordComplete
          ref="reset_password_complete"
          v-if="steps[curStep] === COMPLETE_RESET_PASSWORD"
          :code="code"
          :button-text="resetPasswordCompleteButtonText"
          class="form-component"/>
      </transition>
    </div>
  </div>
</template>

<script>
import NhstResetPasswordStart from '@components/reset-password/NhstResetPasswordStart';
import NhstResetPasswordComplete from '@components/reset-password/NhstResetPasswordComplete';
import NhstResetPasswordValidateCode from '@components/reset-password/NhstResetPasswordValidateCode';
import { emailSync, autoLogin, formDescriptionHander } from '@mixins';
import Utils from '@root/src/helpers/utils';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

export default {
  name: 'nhst-reset-password',
  components: {
    NhstResetPasswordValidateCode,
    NhstResetPasswordComplete,
    NhstResetPasswordStart
  },
  mixins: [emailSync, autoLogin, formDescriptionHander],
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    step: {
      type: Number,
      required: false,
      default: 0
    },
    type: {
      type: String,
      required: '',
      default: ''
    }
  },
  data() {
    const START_RESET_PASSWORD = 'start_reset_password';
    const VALIDATE_CODE = 'validate_code';
    const COMPLETE_RESET_PASSWORD = 'complete_reset_password';
    return {
      START_RESET_PASSWORD,
      VALIDATE_CODE,
      COMPLETE_RESET_PASSWORD,
      steps: [START_RESET_PASSWORD, VALIDATE_CODE, COMPLETE_RESET_PASSWORD],
      defaultDescriptions: [
        'reset.password.start.description',
        'reset.password.validate.code.description',
        'reset.password.complete.description'
      ],
      curStep: 0,
      code: '',
      watchForFormHeadingSubHeadingChanges: true,
      formSubHeading: '',
      resetPasswordCompleteButtonText: '',
      // whether it is being used for creating password for new user
      forSubscriptionActivation: false,
      formHeading: this.title,
      avoidRunningMethodsOnMountedHook: true,
      forCampaign: false
    };
  },
  watch: {
    curStep(step) {
      this.updatePageTitle(step);
    }
  },
  mounted() {
    if (this.$route.path.includes('setpsw')) {
      this.forCampaign = true;
    }
    const q = this.$route.query;
    const username = q.username || Utils.readFromLocalStorage(
      EXTERNAL_CONFIGS.STORAGE_KEYS.VERIFIED_USERNAME
    );
    const type = this.type || q.type;
    this.curStep = parseInt(this.step || q.step || this.curStep || 0);
    this.email = this.email || username || '';
    this.formSubHeading = this.$t(this.defaultDescriptions[this.curStep]);
    if (type && type.includes('activation')) {
      this.forSubscriptionActivation = true;
      if (!this.isDN) {
        this.$authWebapp.pushAnalyticsTrackingEventForGlobalOrderflow();
      }
      this.gotoValidateCodeWorkflow();
    }
    if (this.forCampaign === true) {
      this.formHeading = this.$t('reset.password.campaign.start.title');
      this.formSubHeading = this.$t('reset.password.campaign.start.description');
    }
  },
  methods: {
    // (issue:HI-114) update page title for validate code step if its coming from subscription activation
    updatePageTitle(step) {
      if (this.steps[step] === this.VALIDATE_CODE && this.forSubscriptionActivation) {
        document.title = `${this.$t('validate.code.page.title')} | ${this.publication.title}`;
      }
    },
    gotoValidateCodeWorkflow() {
      this.formSubHeading = this.$t('reset.password.validate.code.description', { email: this.username });
      if (this.forSubscriptionActivation) {
        this.formHeading = this.$t('reset.password.validate.code.activate.subscription.title');
        this.formSubHeading = this.$t(
          'reset.password.validate.code.activate.subscription.description',
          { email: this.username }
        );
      }
      this.curStep = 1;
    },
    validateCodeSuccessWorkflow(code) {
      this.code = code;
      this.formSubHeading = '';
      if (this.forSubscriptionActivation) {
        this.formHeading = this.$t('reset.password.complete.activate.subscription.title');
        this.formSubHeading = this.$t('reset.password.complete.activate.subscription.description');
        this.resetPasswordCompleteButtonText = this.$t('reset.password.complete.button.activate.subscription.text');
      }
      this.curStep = 2;
    },
  }
};
</script>

<style lang="scss">
.reset-password {

  .like-img {
    position: relative;
    top: -2px;
  }

  .form-component {
    height: 100%;
  }
}
</style>
