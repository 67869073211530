<template>
  <div
    :class="['radio-btn', $attrs.disabled ? 'disabled': '']">
    <input
      :id="value"
      :value="value"
      :checked="isChecked"
      v-bind="$attrs"
      v-on:change="$emit('change', $event.target.value)"
      type="radio">
    <label
      :for="value">
      {{ label }}
    </label>
  </div>
</template>

<script>

export default {
  name: 'radio-button',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: undefined,
      required: false
    },
    label: {
      type: String,
      default: '',
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@assets/scss/variables.scss";
@import "@assets/scss/colors.scss";

.radio-btn {
  display: block;
  position: relative;
  margin: 5px 0 0;

  input[type='radio'] {
    display: none;
  }

  label {
    font-weight: normal;
    color: $general-color;
  }

  label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 4px;
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
    border-radius: 100px;
  }

  input[type=radio]:checked + label:after {
    border-radius: 9px;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 12px;
    left: 8.2px;
    content: " ";
    display: block;
  }

  &.disabled {
    label:before {
      border: 1px solid $disabled-color;
    }

    input[type=radio]:checked + label:after {
      border: 1px solid $white;
      background: $white;
    }

    input[type=radio]:checked + label:before {
      background: $disabled-color;
    }
  }
}

</style>
