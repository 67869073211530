<template>
  <div>
    <h3>Home</h3>
    <p>
      This is homepage for kubernetes to fetch for readiness probe
    </p>
  </div>
</template>
<script>

import Page from '@pages/Page.js';

export default {
  name: 'home-page',
  extends: Page,
  methods: {
    getTitle() {
      return 'Home';
    }
  }
};
</script>
