<template>
  <div class="verify-email">
    <RegisterEmail v-if="isDN" />
     <NhstRegistration v-else />
  </div>
</template>

<script>

import RegisterEmail from '@elements/RegisterEmail';
import NhstRegistration from './NhstRegistration.vue';
export default {
  name: 'nhst-check-username',
  components: { RegisterEmail, NhstRegistration },
};
</script>
