<template>
  <ValidationProvider
    :name="fieldName"
    v-slot="{ errors }"
    :rules="rules"
    slim>
    <div :class="['simple-multi-select', $attrs.disabled ? 'disabled' : '']">
      <label v-if="fieldName">{{ fieldName }}</label>
      <Multiselect
        :class="[errors.length > 0 ? 'error-border' : '']"
        v-model="inputVal"
        :close-on-select="singleSelect"
        :placeholder="($attrs.placeholder || '') | capitalize"
        v-bind="$attrs"
        :options="options"
        :track-by="trackBy"
        :deselect-group-label="''"
        :deselect-label="''"
        :select-group-label="''"
        :select-label="''"
        :selected-label="''"
        :custom-label="customLabel">
      </Multiselect>
      <transition
        name="expand"
        mode="out-in">
        <p
          v-show="errors.length > 0"
          v-html="errors[0]"
          class="error-msg"></p>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'simple-multi-select-field',
  components: {
    Multiselect,
    ValidationProvider
  },
  props: {
    singleSelect: {
      type: Boolean,
      default: true,
      required: false,
    },
    // source: https://vue-multiselect.js.org/#sub-single-select
    trackBy: {
      type: String,
      default: 'value',
      required: false,
    },
    customLabel: {
      type: Function,
      default: (obj) => `${obj.text}`,
      required: false,
    },
    fieldName: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: [String, Boolean],
      default: '',
      required: false
    },
    // example: [{text: 'A', value: 'a'}, {'text': 'B', value: 'b'}]
    options: {
      type: Array,
      default: () => [],
      required: false
    },
    rules: {
      default: () => {
      },
      type: Object,
      required: false
    }
  },
  computed: {
    inputVal: {
      get() {
        const val = this.options.filter(opt => opt.value === this.value);
        if (val.length) {
          return val[0];
        }
        return null;
      },
      set(val) {
        this.$emit('input', val.value);
      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
@import "@assets/scss/variables.scss";

label {
  text-transform: uppercase;
  font-size: $label-font-size;
  line-height: $label-line-height;
  margin-bottom: $label-margin-bottom;
  @media screen and (min-width: $sm) {
    margin-bottom: $label-margin-bottom-md;
  }
}

.simple-multi-select {
  margin-bottom: $input-margin-bottom;
  &.disabled {
    opacity: $disabled-opacity;
  }
}
</style>
