import { FETCH_USER } from '@store/action-types';
import deepmerge from 'deepmerge';
import moment from 'moment';

const userInfo = {
  data() {
    return {
      userFetched: false,
      user: {},
      // This could be overridden by objects that uses this mixin
      // Birthdate format that was returned from server
      serverBirthdateFormat: 'YYYY-MM-DD',
      // birthdate format that will be sent to server
      serverAcceptedFormat: 'DD-MM-YYYY'
    };
  },
  computed: {
    fetchedUser() {
      return this.$store.state.user.data;
    }
  },
  async mounted() {
    if (!this.isLoggedIn()) {
      this.userFetched = true;
      return;
    }
    this.showLoader();
    await this.$store.dispatch(`user/${FETCH_USER}`);
    this.hideLoader();
    this.userFetched = true;
    const fetchedUser = this.fetchedUser;
    this.user = deepmerge(this.user, {
      name: fetchedUser.full_name,
      mobile_phone: fetchedUser.mobile_phone,
      department: fetchedUser.department,
      date_of_birth: fetchedUser.date_of_birth
        ? moment(fetchedUser.date_of_birth, this.serverBirthdateFormat).format(this.serverAcceptedFormat) : null
    });
  }
};

export { userInfo };
