import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import defaultStore from '../default';
import deepmerge from 'deepmerge';

Vue.use(Vuex);

export function createStore() {
  const serverStore = {
    state: {},
    actions,
    mutations,
    getters,
    modules: {},
  };
  const store = deepmerge(defaultStore, serverStore);
  return new Vuex.Store(store);
}

export default createStore;
