import {
  UPDATE_FAMILY_SHARING_ID,
  UPDATE_INVITE_DETAILS,
} from '@store/mutation-types';
import { FETCH_INVITE_DETAILS } from '@store/action-types';

const mutations = {
  [UPDATE_FAMILY_SHARING_ID](state, id) {
    state.id = id;
  },
  [UPDATE_INVITE_DETAILS](state, details) {
    state.details = details;
  },
};

const actions = {
  async [FETCH_INVITE_DETAILS](context) {
    try {
      const shareId = context.state.id;
      const detailsResponse = await window.authServices.getProductInviteData(
        shareId
      );
      context.commit(UPDATE_INVITE_DETAILS, detailsResponse.data);
    } catch (e) {
      console.error(`[Failed to get invite details]`, e);
      context.commit(FETCH_INVITE_DETAILS, {});
    }
  },
};

const store = {
  namespaced: true,
  state: {
    id: '',
    details: {},
  },
  mutations,
  actions,
};

export { store as familySharingStore };
