<template>
  <AuthContainer>
    <template #form>
      <NhstCheckUsername />
    </template>
  </AuthContainer>
</template>

<script>
import {
  AuthContainer
} from '../components';
import Page from '@pages/Page.js';
import { NhstCheckUsername } from '@components';

export default {
  name: 'check-username-page',
  components: {
    NhstCheckUsername,
    AuthContainer,
  },
  extends: Page,
  methods: {
    getTitle() {
      return `${this.$t('registration.page.title')} | ${this.publication.title}`;
    }
  }
};

</script>
