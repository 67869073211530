const nhstLogos = {
  data: function () {
    const pubConfigs = {
      dn: { img: { width: 134, height: 17 } },
      fiskeribladet: { img: { width: 97, height: 26, marginTop: '-10px' } },
      tradewinds: { img: { width: 107, height: 14 } },
      upstream: { img: { width: 92, height: 20 } },
      intrafish_com: { img: { width: 89, height: 14 } },
      europower: { img: { width: 114, height: 13 } },
      recharge: { img: { width: 94, height: 13 } },
      hydrogen: { img: { width: 126, height: 17 } },
      kysten: { img: { width: 150, height: 17 } },
    };
    return {
      pubs: Object.keys(pubConfigs),
      pubConfigs,
    };
  },
  computed: {
    // adds empty pubs for better rendering
    renderPubs() {
      let cnt = 1;
      const pubs = [...this.pubs, '', ''];
      return pubs.map((pub) => {
        return {
          name: pub,
          key: pub || ++cnt,
          ...(this.pubConfigs[pub] || {})
        };
      });
    },
    showPublicationLogos() {
      return this.$store.state.showPublicationLogos > 0;
    }
  }
};

export { nhstLogos };
