<template>
  <div class="existing-user-registration">
    <ValidationObserver
      v-slot="{handleSubmit}"
      slim>
      <form
        @submit.prevent="handleSubmit(submit)"
        :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
        method="post">
        <div>
          <TextField
            v-model="user.customer_number"
            :rules="{required: true}"
            :field-name="$t('customer.number.label')"
            :placeholder="$t('customer.number.placeholder')"
            :disabled="submitting || disabled || loggedIn"
            @input="showConfirmBox = false"
            name="customer_number"/>
          <div class="d-flex">
            <TextField
              :rules="{required: true, min: 4, max: 5}"
              v-model="user.zip_code"
              :field-name="$t('zip.code.label')"
              :placeholder="$t('zip.code.placeholder')"
              :disabled="submitting || disabled || loggedIn"
              @input="showConfirmBox = false"
              class="col-4"
              name="zip_code"/>
          </div>
          <EmailField
            :rules="loggedIn?
              {} : {email_required: true, email: true, email_available: true}"
            :field-name="$t('email.label')"
            :placeholder="$t('email.placeholder')"
            :disabled="submitting || disabled || loggedIn"
            @input="showConfirmBox = false"
            name="email"/>
          <PasswordField
            @input="showConfirmBox=false"
            :field-name="$t('password.new.label')"
            :placeholder="$t('password.new.placeholder')"
            :disabled="disabled"/>
        </div>
        <div>
          <!--    should not be v-if -->
          <Confirmation
            v-if="showConfirmBox"
            :success="success"
            :message="confirmMsg.message"
            :title="confirmMsg.title"
            class="pb-2"/>
          <TermsAndConditionsView
            :title="$t('existing.customer.terms.and.conditions.accept')"
            :body="$t('terms.and.conditions.body', { pub: publication.title })"
            :show-terms-and-conditions="!loggedIn">
            <template #container>
              <LoadingButton
                :disabled="submitting || disabled"
                :loading="submitting"
                button-class="btn-standard mt-0">
                {{ $t('continue') }}
              </LoadingButton>
            </template>
          </TermsAndConditionsView>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { ajaxForm, autoLogin, emailSync, formDescriptionHander, userExists } from '@mixins';
import EmailField from '@elements/EmailField';
import TextField from '@elements/TextField';
import PasswordField from '@elements/PasswordField';
import LoadingButton from '@elements/LoadingButton';
import Confirmation from '@elements/Confirmation';
import TermsAndConditionsView from '@elements/TermsAndConditionsView';
import deepmerge from 'deepmerge';

export default {
  name: 'nhst-existing-user-registration',
  components: {
    ValidationObserver,
    Confirmation,
    LoadingButton,
    PasswordField,
    TextField,
    EmailField,
    TermsAndConditionsView
  },
  mixins: [ajaxForm, emailSync, userExists, autoLogin, formDescriptionHander],
  data() {
    return {
      user: {
        customer_number: '',
        zip_code: '',
        username: '',
        password: '',
        terms_accepted: false,
      },
    };
  },
  computed: {
    confirmMsgs: function () {
      return {
        '400.NOT_FOUND': {
          title: '',
          message: this.$t('existing.user.subscription.number.not.found')
        }
      };
    },
    confirmMsg: function () {
      const serverResponseMessage = typeof this.serverResponseMessage === 'object' ? this.serverResponseMessage : {};
      const key = `${this.serverResponseCode}.${serverResponseMessage.message}`;
      return this.confirmMsgs[key] || { title: '', message: this.$t('auth.error.unexpected') };
    }
  },
  mounted() {
    const q = this.$route.query;
    this.email = this.email || q.username || q.email || '';
    this.user.customer_number = q.customernumber || '';
    this.user.zip_code = q.zip || '';
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.$t('existing.customer.register.page.title', { pubName: this.publication.title });
      this.formSubHeading = this.$t('existing.customer.register.page.description');
    },
    successWorkflow() {
      this.showConfirmBox = false;
      return this.autoLogin(true, true);
    },
    errorWorkflow() {

    },
    submit() {
      const app = this;
      const user = deepmerge({}, app.user);
      user.password = app.password;
      user.username = app.username;
      delete user.terms_accepted;
      return app.submitForm(
        () => nhstAuthClient.registerExistingUser(user, 'PAPER_TO_DIGITAL'),
        true
      );
    }
  }
};
</script>
<style scoped lang="scss">
.existing-user-registration {
  &, & > * {
    height: 100%;
  }

}
</style>
