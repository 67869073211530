<template>
  <form
    @submit.prevent="gotoTarget"
    :class="['d-flex', 'flex-column', 'justify-content-between', 'subscription-outcome']">
    <div
      v-if="loggedIn">
      <LoadingButton>
        {{ $t('subscription.outcome.success.button', { pub: publication.title }) }}
      </LoadingButton>
    </div>
  </form>
</template>

<script>
import LoadingButton from '@elements/LoadingButton.vue';
import { formDescriptionHander } from '@mixins';

export default {
  name: 'subscription-outcome',
  components: { LoadingButton },
  mixins: [formDescriptionHander],
  data() {
    return {
      stepsVerbalName: [
        'Validate Code And Change Password',
        'Confirmation'
      ],
    };
  },
  computed: {
    status: function () {
      return this.$route.params.result;
    },
    heading() {
      return this.$t('subscription.outcome.success.title', { pub: this.publication.title });
    },
    subHeading() {
      return this.loggedIn ? this.$t('subscription.outcome.success.old.user.description')
        : this.$t('subscription.outcome.success.new.user.description');
    },
    subHeadingEmphasized() {
      if (this.loggedIn) return '';
      return this.$t('subscription.outcome.success.description.emphasized');
    }
  },
  created() {
    this.showLoader();
  },
  async mounted() {
    // hide loader that is called on created hook
    // for logged in user the text changes and thus
    // we show loader during that transition
    this.hideLoader();
    if (!this.isDN) {
      this.$authWebapp.pushAnalyticsTrackingEventForGlobalOrderflow();
    }
    if (this.loggedIn) {
      console.log('Refreshing the user!');
      this.showLoader();
      await this.refreshUser();
      this.hideLoader();
    }
  },
  methods: {
    gotoTarget() {
      window.location.href = this.getTarget();
    },
    customFormDescriptionHandler() {
      this.formHeading = this.heading;
      let subHeading = `<p>${this.subHeading}</p>`;
      if (this.subHeadingEmphasized) {
        subHeading += `<p><b>${this.subHeadingEmphasized}</b></p>`;
      }
      this.formSubHeading = subHeading;
    },
  }
};
</script>
