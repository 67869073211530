import {
  REDIRECTED_FROM,
  UPDATE_PASSWORD,
} from '@store';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';

const general = {
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    publication: function () {
      return this.$store.state.publication;
    },
    isHydrogen: function () {
      return this.publication.name === 'hydrogen';
    },
    isDN: function () {
      return this.publication.name === 'dn';
    },
    isFreeUserRegForm: function () {
      if (!this.isDN && !this.isHydrogen) {
        return false;
      }
      return this.$route.name === 'FreeUserRegistration' || this.$route.name === 'Registration';
    },
    isNonNcpPublicationDomain: function () {
      return dconf.NON_PUBLICATION_NAMES.includes(this.publication.name);
    },
    username() {
      return this.$store.state.nhstAuth.email;
    },
    subscriptionClient() {
      if (this.isDN) {
        return dnSubscriptionClient;
      }
      return subscriptionClient;
    },
    nhstAuthClient() {
      return nhstAuthClient;
    },
    nhstAuthClientIdDomain() {
      return nhstAuthClientIdDomain;
    },
    subAuthClient() {
      return subAuthClient;
    },
    zephrPublicClient() {
      return zephrPublicClient;
    },
    authServices() {
      // currently it will only be called in client side
      if (isServer) {
        return {};
      }
      return authServices;
    },
    route() {
      return this.$store.state.route;
    },
    serverSideChecking: function () {
      return this.isFieldBeingCheckedOnServerSide(LOADING_TYPES.FULL_PAGE);
    },
    serverSideEmailChecking: function () {
      return this.isFieldBeingCheckedOnServerSide(LOADING_TYPES.EMAIL);
    },
    loggedIn() {
      // We should check this only after component is mounted otherwise it will cause hydration error in prod
      return this.isMounted && this.isLoggedIn();
    },
    isMobileView() {
      return this.$store.state.isMobileView;
    },
    isExternalView() {
      return this.$store.state.isExternalView;
    },
    isPopupView() {
      return this.$store.state.isPopupView;
    },
    password: {
      get() {
        return this.$store.state.nhstAuth.password;
      },
      set(val) {
        this.$store.commit(`nhstAuth/${UPDATE_PASSWORD}`, val);
      }
    },
    redirectedFrom: {
      get() {
        return this.$store.state.redirectedFrom;
      },
      set(val) {
        this.$store.commit(REDIRECTED_FROM, val);
      }
    },
    previousPageUrl() {
      return this.getPreviousPageUrl();
    },
  },
  // Note that "this" does not work with filters
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toUppercase: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.toUpperCase();
    },
    capitalizeEveryWord: function (value) {
      if (!value) return '';
      value = value.toString();
      value = value.split(' ');
      value = value.map(val => this.capitalize(val));
      return value.join(' ');
    }
  },
  methods: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeEveryWord: function (value) {
      if (!value) return '';
      value = value.toString();
      value = value.split(' ');
      value = value.map(val => this.capitalize(val));
      return value.join(' ');
    },
    getTarget(defaultTarget = '/') {
      return this.$authWebapp.getTarget(defaultTarget);
    },
    getCookie(cookieName) {
      let cookie = '';
      if (isServer) {
        cookie = this.$ssrContext.req.cookies[cookieName];
      } else {
        cookie = this.$cookies.get(cookieName);
      }
      return cookie || '';
    },
    isLoggedIn() {
      return this.$authWebapp.isLoggedIn();
    },
    getLoggedInUserEmail() {
      if (this.loggedIn) {
        const oneidInfo = atob(this.getCookie('oneid_info'));
        return oneidInfo.split('|')[3];
      }
      return '';
    },
    showLoader() {
      this.$authWebapp.showLoader();
    },
    hideLoader() {
      this.$authWebapp.hideLoader();
    },
    constructUrl(url) {
      try {
        return new URL(url);
      } catch (e) {
        // not full url, only path was given. convert it to a url
        return new URL(`${window.location.origin}${url}`);
      }
    },
    getParamValue(url, param) {
      try {
        const curUrl = this.constructUrl(url);
        return curUrl.searchParams.get(param) || '';
      } catch (e) {
      }
      return '';
    },
    triggerLoginFailEvent(code, cause) {
      this.authServices.triggerLoginFailEvent(code, cause);
    },
    async refreshUser() {
      console.log('Refreshing user');
      try {
        await this.nhstAuthClient.refreshUser();
        console.log(`[Refresh user success]`);
      } catch (e) {
        console.error(`[Refresh user error] ${e}`);
      }
    },
    resolveRoute(route) {
      if (Object.keys(route).length <= 0) {
        return '';
      }
      return this.$router.resolve(route).href;
    },
    getPreviousPageUrl() {
      return this.resolveRoute(this.redirectedFrom || {}) || this.getTarget();
    },
    getUserAgent() {
      try {
        return navigator.userAgent || navigator.vendor || window.opera;
      } catch (e) {
        console.error('[Could not determine User Agent]', e);
      }
      return '';
    },
    getMobileOperatingSystem() {
      const userAgent = this.getUserAgent();

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }

      return 'unknown';
    },
    isFieldBeingCheckedOnServerSide(fieldName) {
      if (!this.isMounted) {
        return false;
      }
      return this.$authWebapp.isFieldBeingCheckedOnServerSide(fieldName);
    },
  },

};

export { general };
