<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
      method="post">
      <div>
        <EmailField
          :rules="loggedIn?
            {} : {email_required: true, email: true, email_available: true}"
          :field-name="$t('email.label')"
          :placeholder="$t('email.placeholder')"
          :disabled="submitting || disabled || loggedIn"
          @input="showConfirmBox = false"
          name="email"/>
        <ClientOnly>
          <div
            v-if="!loggedIn"
            class="note mb-4">
            {{ $t('register.page.note') }}
          </div>
        </ClientOnly>
      </div>
      <div>
        <!--    should not be v-if -->
        <Confirmation
          v-if="isCorporate"
          :success="success"
          :message="confirmMsgs[200].message"
          :title="confirmMsgs[200].title"
          image="latest-news.png"
          class="pb-2"/>
        <TermsAndConditionsView
          :title="$t('terms.and.conditions.accept')"
          :body="$t('terms.and.conditions.body', { pub: publication.title })"
          :show-terms-and-conditions="!loggedIn"
          class="mt-0">
          <template #container>
            <LoadingButton
              :disabled="submitting || disabled"
              :loading="submitting"
              button-class="btn-standard mt-0">
              {{ loggedIn ? $t('register.button.text.logged.in') : $t('register.button.text') }}
            </LoadingButton>
          </template>
        </TermsAndConditionsView>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from './elements/LoadingButton';
import EmailField from './elements/EmailField';
import { ajaxForm, emailSync, formDescriptionHander, userExists } from '@mixins';
import Confirmation from '@root/src/components/elements/Confirmation';
import TermsAndConditionsView from '@root/src/components/elements/TermsAndConditionsView';
import { UPDATE_COMPANY } from '@store';
import ClientOnly from '@components/ClientOnly';

export default {
  name: 'nhst-registration',
  components: {
    ClientOnly,
    Confirmation,
    EmailField,
    LoadingButton,
    ValidationObserver,
    TermsAndConditionsView
  },
  mixins: [ajaxForm, emailSync, userExists, formDescriptionHander],
  data() {
    return {
      submitting: false,
      isCorporate: false,
    };
  },
  computed: {
    confirmMsgs: function () {
      return {
        200: {
          message: this.$t('corporate.registration.notice.description'),
          title: this.$t('corporate.registration.notice.title'),
        },
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
    company() {
      return this.$store.state.corporate.company;
    }
  },
  methods: {
    // Username will be validated against server (username existence check is enabled)
    updateShouldValidateUsernameWithServer() {
      this.shouldValidateWithServer = true;
    },
    customFormDescriptionHandler() {
      this.formHeading = this.$t('register.page.title', { pubName: this.publication.title });
      return true;
    },
    attachEventListenerInConfirmationLink() {
      const app = this;
      // coded in locales
      document.getElementById('gotoCorporateAccount').addEventListener('click', (e) => {
        e.preventDefault();
        app.gotoCorporateAccount();
      });
    },
    gotoCorporateAccount() {
      this.$router.push({
        ...this.$route,
        name: 'CorporateRegistration',
        ...{ params: { slug: this.company.slug } }
      });
    },
    successWorkflow() {
      this.$store.commit(`corporate/${UPDATE_COMPANY}`, this.serverResponseMessage);
      // If company has enabled registration code and if a user arrives at this page, then treat the user
      // as a regular subscriber
      if (this.$store.state.corporate.company.requires_registration_code) {
        this.regularSubscriptionWorkflow();
        return;
      }

      this.isCorporate = true;
      this.$nextTick(() => {
        this.attachEventListenerInConfirmationLink();
        const route = this.$router.resolve({
          name: 'CorporateRegistration',
          params: {
            slug: this.company.slug
          }
        });
        document.getElementById('gotoCorporateAccount').setAttribute('href', route.href);
      });
    },
    errorWorkflow() {
      this.regularSubscriptionWorkflow();
    },
    regularSubscriptionWorkflow() {
      // Not a company account, so redirecting
      localStorage.setItem('sub-auth-user-email', btoa(this.email));
      localStorage.setItem('sub-auth-added-at', new Date().toISOString());
      // End slash is important otherwise it will redirect to give end slash
      const target = this.getTarget('/subscription/');
      console.log('Redirecting to target: ' + target);
      // Because of using proxy in dev env it may not work as expected in dev
      window.location.href = target;
    },
    submit() {
      this.submitting = true;
      return this.submitForm(
        () => this.subscriptionClient.getCompany('', this.email.split('@')[1]),
        true
      );
    },
  },
};
</script>
