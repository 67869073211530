<template>
  <TextField
    v-model="inputVal"
    :rules="rules"
    :field-name="fieldName"
    :placeholder="placeholder"
    v-bind="$attrs"
    :type="inputType"
    name="password"
    disable-autocomplete>
    <template #append-icon>
      <ClientOnly>
        <div class="d-flex">
          <InlineSvg
            @click="showPassword = !showPassword"
            v-show="!showPassword"
            :src="$cdn.resource('eye-active-dark.svg')"
            class="password-icon"
            alt="hide password" />
          <InlineSvg
            @click="showPassword = !showPassword"
            v-show="showPassword"
            :src="$cdn.resource('eye-inactive-dark.svg')"
            class="password-icon"
            alt="show password" />
        </div>
      </ClientOnly>
    </template>
  </TextField>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

import TextField from './TextField';
import ClientOnly from '@components/ClientOnly';

export default {
  name: 'password-field',
  components: {
    InlineSvg,
    ClientOnly,
    TextField,
  },
  props: {
    fieldName: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: String,
      default: '',
      required: '',
    },
    placeholder: {
      type: String,
      default: ' ',
      required: false,
    },
    usePasswordState: {
      type: Boolean,
      default: true,
      required: false,
    },
    rules: {
      default: () => {
        return { required: true, password: true, max: 20 };
      },
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    icon() {
      return this.showPassword ? 'eye-active.svg' : 'eye-inactive.svg';
    },
    inputType() {
      return this.showPassword ? 'text' : 'password';
    },
    inputVal: {
      get() {
        return this.usePasswordState ? this.password : this.value;
      },
      set(val) {
        if (this.usePasswordState) {
          this.password = val;
        }
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.password-icon {
  height: 25px !important;
  width: 25px !important;
  margin: 12px;
}
</style>
