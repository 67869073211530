const title = {
  created() {
    if (isServer) {
      try {
        this.$ssrContext.title = this.getTitle();
      } catch (e) {
        logger.error(`[Error resolving title] ${e}`);
        this.$ssrContext.title = this.defaultTitle();
      }
    }
  },
  mounted() {
    try {
      document.title = this.getTitle();
    } catch (e) {
      logger.error(`[Error resolving title] ${e}`);
      document.title = this.defaultTitle();
    }
  },
  methods: {
    getTitle() {
      throw Error('You need to implement this!');
    },
    defaultTitle() {
      return this.publication.title;
    }
  }
};

export { title };
