import { validate } from 'vee-validate';
import {
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_USER_EXISTS_CHECK_ERROR,
  UPDATE_USER_EXISTS,
  UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER,
  UPDATE_USER_LOGGED_IN_BEFORE,
  UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER,
  UPDATE_CORPORATE_SSO_CONFIG,
  UPDATE_CORPORATE_SSO_USER,
} from '@store/mutation-types';

import {
  DISPATCH_CHECK_USER_EXISTS,
  DISPATCH_CHECK_USER_LOGGED_IN_BEFORE,
  DISPATCH_CHECK_CORPORATE_SSO_LOGIN_FOR_USERNAME,
} from '@store/action-types';
import { debounce } from 'lodash-es';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';
import * as routes from '@router/constants';
import Utils from '@root/src/helpers/utils';

const mutations = {
  [UPDATE_EMAIL](state, email) {
    state.email = email;
  },
  [UPDATE_PASSWORD](state, password) {
    state.password = password;
  },
  [UPDATE_USER_EXISTS_CHECK_ERROR](state, val) {
    state.userExistsCheckError = val;
  },
  [UPDATE_USER_EXISTS](state, val) {
    state.userExists = val;
  },
  [UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER](state, val) {
    state.shouldValidateUserWithServer = val;
  },
  [UPDATE_USER_LOGGED_IN_BEFORE](state, val) {
    state.userLoggedInBefore = val;
  },
  [UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER](state, val) {
    state.shouldValidateUserLoggedInBeforeWithServer = val;
  },
  [UPDATE_CORPORATE_SSO_CONFIG](state, val) {
    state.corporateSsoConfig = val;
  },
  [UPDATE_CORPORATE_SSO_USER](state, val) {
    state.corporateSsoUser = val;
  }
};

const actions = {
  [DISPATCH_CHECK_USER_EXISTS]: debounce(async function ({ commit, state }) {
    console.log('Checking user_exists: ', state.email);
    const emailValid = await validate(state.email, 'required|email');
    if (!emailValid.valid) {
      return false;
    }

    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.EMAIL);
    commit(UPDATE_USER_EXISTS, null);
    commit(UPDATE_USER_EXISTS_CHECK_ERROR, true);

    let res = {};
    try {
      res = await nhstAuthClient.checkUserExists(state.email);
    } catch (err) {
    }

    res = res.data ? res.data.message : '';
    console.log('IsExist: ', res);
    if (res === 'true' || res === 'false') {
      commit(UPDATE_USER_EXISTS_CHECK_ERROR, false);
      commit(UPDATE_USER_EXISTS, res === 'true');
    }
    window.$vue.$authWebapp.finishServerSideCheckingForField(LOADING_TYPES.EMAIL);
    return res;
  }, 500),

  [DISPATCH_CHECK_USER_LOGGED_IN_BEFORE]: debounce(async function ({ commit, state }) {
    console.log('Checking user_logged_in_before: ', state.email);
    commit(UPDATE_USER_LOGGED_IN_BEFORE, null);
    if (state.email.trim() === '') {
      return false;
    }

    const emailValid = await validate(state.email, 'required|email');
    if (!emailValid.valid) {
      commit(UPDATE_USER_LOGGED_IN_BEFORE, false);
      return false;
    }
    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.EMAIL);
    let res = {};
    try {
      res = await nhstAuthClient.checkUserLoggedInBefore(state.email);
    } catch (err) {
    }

    res = res.data ? res.data.message : '';
    console.log('IsLoggedInBefore: ', res);
    if (res === 'true' || res === 'false') {
      commit(UPDATE_USER_LOGGED_IN_BEFORE, res === 'true');
    }
    window.$vue.$authWebapp.finishServerSideCheckingForField(LOADING_TYPES.EMAIL);
    return res;
  }, 500),
  [DISPATCH_CHECK_CORPORATE_SSO_LOGIN_FOR_USERNAME]: debounce(async function ({ commit, state }) {
    console.log('Checking if user should do corporate-SSO login: ', state.email);
    const emailValid = await validate(state.email, 'required|email');
    if (!emailValid.valid) {
      return false;
    }
    window.$vue.$authWebapp.startServerSideCheckingForField(LOADING_TYPES.EMAIL);
    const corporateSso = await Utils.getCorporateSsoConfigForUsername(state.email) || {};
    commit(UPDATE_CORPORATE_SSO_USER, corporateSso);
    window.$vue.$authWebapp.finishServerSideCheckingForField(LOADING_TYPES.EMAIL);
    if (
      !window.$vue.$authWebapp.isLoggedIn() &&
      Object.keys(corporateSso).length &&
      window.$vue.$route.name !== routes.CORPORATE_SSO_LOGIN
    ) {
      window.$vue.$router.push({
        name: routes.CORPORATE_SSO_LOGIN
      });
    }
  }, 500),
};

const store = {
  namespaced: true,
  state: {
    email: '',
    // ensure that password is never stored in any place
    password: '',
    // here userExists value can be either true, false or null. null means something went wrong in server side
    userExists: null,
    userExistsCheckError: false,
    shouldValidateUserWithServer: true,
    // here userLoggedInBefore value can be either true, false or null. null means something went wrong in server side or empty email set
    userLoggedInBefore: null,
    shouldValidateUserLoggedInBeforeWithServer: true,
    corporateSsoConfig: null,
    corporateSsoUser: {}
  },
  mutations,
  actions,
};

const plugin = store => {
  store.subscribe(async (mutation) => {
    const { type } = mutation;
    if ([`nhstAuth/${UPDATE_EMAIL}`, `nhstAuth/${UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER}`]
      .includes(type)) {
      if (store.state.nhstAuth.shouldValidateUserWithServer) {
        await store.dispatch(`nhstAuth/${DISPATCH_CHECK_USER_EXISTS}`);
      }
    }
    if ([`nhstAuth/${UPDATE_EMAIL}`, `nhstAuth/${UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER}`]
      .includes(type)) {
      if (store.state.nhstAuth.shouldValidateUserLoggedInBeforeWithServer) {
        await store.dispatch(`nhstAuth/${DISPATCH_CHECK_USER_LOGGED_IN_BEFORE}`);
      }
    }
    if ([`nhstAuth/${UPDATE_EMAIL}`]
      .includes(type)) {
      await store.dispatch(`nhstAuth/${DISPATCH_CHECK_CORPORATE_SSO_LOGIN_FOR_USERNAME}`);
    }
  });
};

export { store as nhstAuthStore };
export { plugin as nhstAuthPlugin };
