<template>
  <div
    class="text-field-loader">
    <font-awesome-icon
      icon="spinner"
      spin
      pulse
      size="1x" />
  </div>
</template>

<script>

export default {
  name: 'text-field-loader',
};
</script>

<style scoped lang="scss">
.text-field-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

</style>
