<template>
  <div class="long-height"></div>
</template>
<script>

import Page from '@pages/Page.js';
import { ajaxForm } from '@mixins';

export default {
  name: 'logout-page',
  extends: Page,
  mixins: [ajaxForm],
  data() {
    return {
      logoutType: this.$route.query.is_nhst_logout && this.$route.query.is_nhst_logout === 'true'
        ? 'THIS_DEVICE' : 'JUST_HERE'
    };
  },
  mounted() {
    this.showLoader();
    this.submit();
  },
  methods: {
    getTitle() {
      return 'Logout';
    },
    successWorkflow() {
      const target = this.getTarget();
      logger.info('Redirecting to target after logout success: ' + target);
      window.location.href = target;
      this.hideLoader();
    },
    errorWorkflow() {
      const target = this.getTarget();
      logger.info('Redirecting to target after logout failed: ' + target);
      window.location.href = target;
      this.hideLoader();
    },
    submit() {
      const app = this;
      return app.submitForm(
        () => app.authServices.logout(
          app.logoutType
        ), true
      );
    },
  }
};
</script>
<style lang="scss">
.long-height {
  height: 50vh;
}
</style>
