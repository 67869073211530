import { UPDATE_FORM_DESCRIPTION, UPDATE_SHOW_COMPONENT } from '@store';

const formDescriptionHander = {
  data() {
    return {
      formHeading: '',
      formSubHeading: '',
      // this will have no effect for standalone header and footer (and it is designed based on that)
      showPageWhenFormDescriptionIsAvailable: true,
      watchForFormHeadingSubHeadingChanges: false,
      skipFormDescriptionChanges: false,
      alwaysShowCurrentComponent: false,
    };
  },
  created() {
    this.handleFormDescription();
  },
  mounted() {
    this.handleFormDescription();
  },
  activated() {
    this.handleFormDescription();
  },
  methods: {
    updateFormDescription(heading, subHeading) {
      this.formDescription = {
        heading,
        subHeading
      };
    },
    handleFormDescription() {
      if (this.skipFormDescriptionChanges) {
        return;
      }
      this.customFormDescriptionHandler();
      this.updateFormDescription(this.formHeading, this.formSubHeading);
    },
    customFormDescriptionHandler() {
      return false;
    },
  },
  watch: {
    formHeadingSubHeading: {
      immediate: true,
      handler() {
        if (this.watchForFormHeadingSubHeadingChanges) {
          this.handleFormDescription();
        }
      }
    }
  },
  computed: {
    formHeadingSubHeading() {
      return {
        heading: this.formHeading,
        subHeading: this.formSubHeading
      };
    },
    shouldShowFormDescriptionComponent() {
      return Boolean(this.formDescription.heading || this.formDescription.subHeading);
    },
    formDescription: {
      get() {
        return this.$store.state.formDescription || {};
      },
      set(val) {
        const app = this;
        app.$store.commit(UPDATE_FORM_DESCRIPTION, val);
        // we will show the component only when form description is available
        // to avoid showing only half component at the beginning
        // If form description is not available for a component, we will then
        // show the component. In that case, we will set alwaysShowCurrentComponent is true
        // on the component that will use this mixin
        let showComponent = true;
        if (app.showPageWhenFormDescriptionIsAvailable) {
          showComponent = app.shouldShowFormDescriptionComponent;
        }
        showComponent = showComponent || app.alwaysShowCurrentComponent;
        app.$store.commit(UPDATE_SHOW_COMPONENT, showComponent);
        if (isServer) {
          app.$ssrContext.state.showComponent = showComponent;
        }
      }
    },
  }
};

export { formDescriptionHander };
