export const HOME = 'Home';
export const HOME_PATH = '/home';

export const LOGIN = 'Login';
export const LOGIN_PATH = '/login';

export const LOGOUT = 'Logout';
export const LOGOUT_PATH = '/logout';

export const SSO = 'SSO';
export const SSO_PATH = '/sso';

export const CORPORATE_SSO_LOGIN = 'CorporateSSOLogin';
export const CORPORATE_SSO_LOGIN_PATH = '/corporatesso/login';

export const REGISTRATION = 'Registration';
export const REGISTRATION_PATH = '/register';

export const FREE_USER_REGISTRATION = 'FreeUserRegistration';
export const FREE_USER_REGISTRATION_PATH = '/register/free';

export const FREE_USER_REGISTRATION_MVP = 'FreeUserRegistrationMvp';
export const FREE_USER_REGISTRATION_MVP_PATH = '/register/mvp';

export const EXISTING_USER_REGISTRATION = 'ExistingUserRegistration';
export const EXISTING_USER_REGISTRATION_PATH = '/register/existing-user';

export const ACCEPT_PRODUCT_SHARE = 'AcceptProductShare';
export const ACCEPT_PRODUCT_SHARE_PATH = '/product-shares/accept';

export const USER_REGISTRATION_CHECK_USERNAME = 'UserRegistrationCheckUsername';
export const USER_REGISTRATION_CHECK_USERNAME_PATH = '/register/check-username';

export const USER_REGISTRATION_VERIFY_USERNAME = 'UserRegistrationVerifyUsername';
export const USER_REGISTRATION_VERIFY_USERNAME_PATH = '/register/verify-username';

export const CORPORATE_REGISTRATON = 'CorporateRegistration';
export const CORPORATE_REGISTRATION_PATH = '/corporate/:slug';

export const IP_REGISTRATION = 'IpRegistration';
export const IP_REGISTRATION_PATH = '/corporate/ip';

export const PASSWORD_RESET = 'ResetPassword';
export const PASSWORD_RESET_PATH = '/password/reset';

export const SET_PASSWORD = 'SetPassword';
export const SET_PASSWORD_PATH = '/password/setpsw';

export const NCP_PASSWORD_RESET = 'NcpPasswordReset';
export const NCP_PASSWORD_RESET_PATH = '/password/reset/:token/ncp';

export const ZEPHR_PASSWORD_RESET = 'ZephrPasswordReset';
export const ZEPHR_PASSWORD_RESET_PATH = '/password/reset/:token/zephr';

export const ACTIVATE_SUBSCRIPTION = 'ActivateSubscription';
export const ACTIVATE_SUBSCRIPTION_PATH = '/subscribe/activate/:token';

export const SUBSCRIPTION_OUTCOME = 'SubscriptionOutcome';
export const SUBSCRIPTION_OUTCOME_PATH = '/subscribe/:result';

export const TEST = 'Test';
export const TEST_PATH = '/test';
