<template>
  <div>
    <DNFooter v-if="isDN" />
    <GlobalFooter v-else />
  </div>
</template>
<script>

import { GlobalFooter, DNFooter } from '@components';
export default {
  name: 'footer-bar',
  components: { GlobalFooter, DNFooter },
};
</script>
