<template>
  <div class="family-sharing">
    <div :class="{ loading: !loaded }">
      <InvalidShareId v-if="!sharingIdCorrect" />
      <IncorrectUser v-else-if="incorrectUser" />
      <LoggedInUser v-else-if="loggedIn" />
      <AnonUser v-else />
    </div>
  </div>
</template>

<script>
import { UPDATE_FAMILY_SHARING_ID } from '@store/mutation-types';
import { FETCH_INVITE_DETAILS } from '@store/action-types';
import {
  InvalidShareId,
  IncorrectUser,
  LoggedInUser,
  AnonUser,
} from '@components/family-sharing';
import { emailSync, familySharing, adobeAnalytics } from '@mixins';

export default {
  name: 'sharing-user-registration',
  components: {
    InvalidShareId,
    IncorrectUser,
    LoggedInUser,
    AnonUser,
  },
  mixins: [emailSync, familySharing, adobeAnalytics],
  data: function () {
    return {
      loaded: false,
      btnText: 'Accept',
    };
  },
  computed: {
    /**
     * Checks if user is sent bach from login or registration flow and if it
     * is true we will check if user is logged in with correct user and accept
     * family share invite if yes. If user is incorrect then we will remove
     * query param and show page.
     *
     * @return {boolean} Returns true if user is just returned from auth flow
     */
    authCompleted() {
      const authComplete = this.$route.query.auth_complete || '';
      return !!authComplete;
    },
    /**
     * Whether user should be redirected to onboarding page or not.
     *
     * @return {boolean} Returns true if user should be redirected else false
     */
    redirectToOnboardingPage() {
      if (this.authCompleted && this.sharingIdCorrect && !this.incorrectUser) {
        return true;
      }

      return false;
    },
  },
  watch: {
    userExists: {
      immediate: true,
      handler(val) {
        if (!this.authCompleted) {
          window.localStorage.setItem('userExistsFS', val);
        }
      },
    },
  },
  async mounted() {
    this.showLoader();

    const sharingId = this.$route.query.sharing_id || '';
    if (sharingId) {
      this.$store.commit(
        `familySharing/${UPDATE_FAMILY_SHARING_ID}`,
        sharingId
      );
      await this.$store.dispatch(`familySharing/${FETCH_INVITE_DETAILS}`);
    }

    if (this.redirectToOnboardingPage) {
      const app = this;
      await app.acceptShareInvite();
      app.pushAdobeEvent({
        event: 'MY_PAGE',
        eventName: 'Auth Webapp Product Share | Invite accepted',
        eventInfo: {
          context1: app.userExistsFS ? 'existing' : 'new',
          context2: '',
        },
      });
      // Add a slight delay to make sure that event is picked up by
      // adobe
      setTimeout(() => {
        window.top.location.href = app.fsOnboardingUrl;
      }, 1000);
      return;
    }

    if (sharingId) {
      this.email = this.recepientEmail;
      window.localStorage.setItem('userExistsFS', !!this.userExists);
    }

    if (this.authCompleted) {
      delete this.$route.query.auth_complete;
      this.$router.replace({
        name: this.$route.name,
      });
    }
    if (!this.loggedIn) {
      this.btnText = 'Login or Register';
    }

    this.loaded = true;
    this.hideLoader();
  },
};
</script>

<style lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/colors.scss';

$pageWidth: 92rem;
$pagePadding: 1.6rem;
$pagePaddingDesktop: 1.6rem 0;
$pageMargin: 0 auto 5rem;

$textContentWidth: 71rem;
$textContentPadding: 0;
$textContentPaddingDesktop: 0 1.6rem;
$textContentMargin: 4rem auto 5.6rem;
$textContentMarginDesktop: 7.2rem auto 11rem;

$buttonPadding: 2rem 4rem;

.family-sharing {
  background-color: $white;
  margin-top: -6px;

  & > * {
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
  .loading {
    opacity: 0;
  }

  .page-content {
    max-width: $pageWidth;
    margin: $pageMargin;
    padding: $pagePadding;

    img {
      max-width: 100%;
      display: block;
    }

    h1 {
      font-size: 3.2rem;
      line-height: 1.25em;
      margin-bottom: 1em;

      @media screen and (min-width: $md) {
        font-size: 4rem;
        line-height: 1.3em;
      }
    }

    h2 {
      font-size: 2.4rem;
      line-height: 1.4em;
      margin-bottom: 0.666666667em;
      font-weight: 400;

      @media screen and (min-width: $md) {
        font-size: 3.2rem;
        line-height: 1.625em;
        margin-bottom: 1.25em;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 1.5em;
      margin-bottom: 1em;

      @media screen and (min-width: $md) {
        font-size: 2rem;
        line-height: 1.6em;
        margin-bottom: 1.3em;
      }
    }

    .loading-button {
      text-align: left;
    }

    button {
      height: auto;
      padding: $buttonPadding;
      font-size: 1.6rem;
      line-height: 1em;
      font-weight: 400;
      margin-top: 4.8rem;

      @media screen and (min-width: $md) {
        width: auto;
        margin-top: 6.4rem;
      }
    }

    .text-content {
      max-width: $textContentWidth;
      padding: $textContentPadding;
      margin: $textContentMargin;

      @media screen and (min-width: $md) {
        margin: $textContentMarginDesktop;
        padding: $textContentPaddingDesktop;
      }
    }
  }
}
</style>
