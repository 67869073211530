<!--Currently this is only for global-->
<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <div class="heading mb-6">
          {{ $t('activate.subscription.title') }}
        </div>
        <div class="sub-heading mb-6">
          {{ $t('activate.subscription.description', { username: emailInUrl }) }}
        </div>
        <PasswordField
          :field-name="$t('password.label')"
          :placeholder="$t('reset.password.complete.password.placeholder')"
          :disabled="disabled"
          @input="showConfirmBox=false"/>
      </div>
      <div>
        <Confirmation
          v-if="showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="invalid || disabled"
          :loading="submitting">
          {{ $t('activate.subscription.button.text') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from './elements/LoadingButton';
import Confirmation from './elements/Confirmation';
import { ajaxForm, emailSync, autoLogin } from '@mixins';
import PasswordField from '@components/elements/PasswordField';

export default {
  name: 'activate-subscription',
  components: {
    PasswordField,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm, emailSync, autoLogin],
  data() {
    return {
      hash: '',
    };
  },
  computed: {
    token: function () {
      return this.$route.params.token;
    },
    emailInUrl: function () {
      return this.$route.query.email;
    },
    confirmMsgs: function () {
      return {
        200: {
          title: this.$t('activate.subscription.200.title'),
          message: this.$t('activate.subscription.200.description')
        },
        400: {
          title: this.$t('activate.subscription.400.title'),
          message: this.$t('activate.subscription.400.description')
        },
      };
    },
  },
  mounted() {
    const app = this;
    app.showLoader();
    if (this.emailInUrl) {
      this.email = this.emailInUrl;
    }
    return app.submitFormWithHandlers(
      () => app.subscriptionClient.validateActivationToken(this.token, this.emailInUrl),
      false,
      app.tokenValidationSuccessWorkFlow,
      app.tokenValidationErrorWorkFlow
    );
  },
  methods: {
    tokenValidationSuccessWorkFlow() {
      this.hash = this.serverResponseMessage.generated_hash;
      this.showConfirmBox = false;
      this.hideLoader();
    },
    tokenValidationErrorWorkFlow() {
      const app = this;
      app.disabled = true;
      app.hideLoader();
      console.log('show confirm box', this.showConfirmBox);
      this.$nextTick(() => {
        document.getElementById('gotoResetPasswordPage')
          .addEventListener('click', (e) => {
            e.preventDefault();
            app.goToResetPasswordPage();
          });
      });
    },
    activateSubscriptionSuccessWorkflow() {
      console.log('Logging the user');
      this.showConfirmBox = false;
      return this.autoLogin(true, true);
    },
    activateSubscriptionErrorWorkflow() {

    },
    goToResetPasswordPage() {
      this.$router.push({
        name: 'ResetPassword',
        query: this.$route.query,
        params: {
          title: this.$t('activate.subscription.title'),
        }
      });
    },
    submit() {
      const app = this;
      return app.submitFormWithHandlers(
        () => app.subscriptionClient.activateSubscription(
          this.emailInUrl, this.password, this.hash, this.token
        ),
        true,
        app.activateSubscriptionSuccessWorkflow,
        app.activateSubscriptionErrorWorkflow
      );
    },
  },
};
</script>
