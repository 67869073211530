<template>
  <div class="form-description">
    <div
      v-if="formDescription.heading"
      v-html="formDescription.heading"
      class="heading"></div>
    <div
      v-if="formDescription.subHeading"
      v-html="formDescription.subHeading"
      class="sub-heading"></div>
  </div>
</template>

<script>
import { formDescriptionHander } from '@mixins';

export default {
  name: 'form-description',
  mixins: [formDescriptionHander],
  data() {
    return {
      skipFormDescriptionChanges: true
    };
  }
};
</script>

<style lang="scss" scoped>
.form-description {
  .heading {
    font-size: 24px;
    line-height: 37px;
    font-weight: bold;
  }
  .sub-heading {
    font-size: 14px;
    line-height: 23px;
    margin-top: 8px;
  }
}
</style>
