// static class
import moment from 'moment';
import { Utils as NhstUtils } from '@nhst/nhst-sub-auth-services';

class Utils extends NhstUtils {
  static getAllowedFormatsForDates() {
    return [
      'DD.MM.YYYY',
      'YYYY-MM-DD',
      'DD-MM-YYYY'
    ];
  }

  static getDefaultDateFormat() {
    return 'DD.MM.YYYY';
  }

  static formatDate(date, format = '') {
    return moment(date).format(format || Utils.getAllowedFormatsForDates()[0]);
  }

  static async isCorporateSsoUser(username) {
    const res = await Utils.getCorporateSsoConfigForUsername(username);
    return Object.keys(res || {}).length > 1;
  }

  static async getCorporateSsoConfigForUsername(username) {
    if (isServer) {
      return {};
    }
    const state = window.$store.state.nhstAuth;
    const tries = 10;
    let tried = 0;
    while (!state.corporateSsoConfig && tried < tries) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      tried += 1;
    }
    const corporateSsoConfig = state.corporateSsoConfig || {};
    const companies = corporateSsoConfig.companies || [];
    let corporateSso = {};
    companies.every((company) => {
      if (company.sso_usernames.includes(username)) {
        corporateSso = company;
        return false;
      }
      const domain = state.email.split('@')[1];
      if (company.sso_enabled && company.sso_domains.includes(domain)) {
        corporateSso = company;
        return false;
      }
      return true;
    });
    return corporateSso;
  }
}

export default Utils;
