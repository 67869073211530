<template>
  <ValidationObserver
    v-slot="{ handleSubmit, invalid }"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="[
        'd-flex',
        'flex-column',
        'justify-content-between',
        submitting || disabled ? 'disabled' : '',
      ]"
      method="post">
      <div>
        <EmailField
          :rules="
            loggedIn
              ? {}
              : { email_required: true, email: true, email_available: true }
          "
          :field-name="$t('email.label')"
          :placeholder="$t('email.placeholder')"
          :disabled="submitting || disabled || loggedIn"
          @input="showConfirmBox = false"
          name="email" />
      </div>
      <div>
        <!--    should not be v-if -->
        <Confirmation
          v-if="showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2" />
        <TermsAndConditionsView
          :show-terms-and-conditions="!loggedIn"
          :title="$t('terms.and.conditions.accept')"
          :body="$t('terms.and.conditions.body', { pub: publication.title })"
          class="mt-0">
          <template #container>
            <LoadingButton
              :disabled="submitting || disabled"
              :loading="submitting"
              button-class="btn-standard mt-0">
              {{
                registerButtonText
              }}
            </LoadingButton>
            <button
              v-if="showBackButton"
              @click="handleBack"
              :disabled="disabled || submitting"
              type="button"
              class="btn-secondary mt-3">
              {{ $t('go.back') }}
            </button>
          </template>
        </TermsAndConditionsView>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '@elements/LoadingButton';
import EmailField from '@elements/EmailField';
import {
  ajaxForm,
  emailSync,
  formDescriptionHander,
  userExists,
} from '@mixins';
import Confirmation from '@elements/Confirmation';
import TermsAndConditionsView from '@elements/TermsAndConditionsView';
import Utils from '@root/src/helpers/utils';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

export default {
  name: 'register-email',
  components: {
    Confirmation,
    EmailField,
    LoadingButton,
    ValidationObserver,
    TermsAndConditionsView,
  },
  mixins: [ajaxForm, emailSync, userExists, formDescriptionHander],
  props: {
    registerForMvp: {
      type: Boolean,
      default: false,
      required: false,
    },
    showBackButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: function () {
        return this.$t('user.register.page.title');
      }
    },
    description: {
      type: String,
      required: false,
      default: function () {
        return this.$t('register.page.description');
      }
    },
    registerButtonText: {
      type: String,
      required: false,
      default: function () {
        return this.loggedIn ? this.$t('register.button.text.logged.in') : this.$t('register.button.text');
      }
    }

  },

  mounted() {
    const app = this;
    app.email =
      app.email ||
      this.getLoggedInUserEmail() ||
      Utils.readFromLocalStorage(
        EXTERNAL_CONFIGS.STORAGE_KEYS.VERIFIED_USERNAME,
      ) ||
      '';
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.title;
      this.formSubHeading = this.description;
      if (!this.loggedIn && !this.formHeading) {
        this.formSubHeading = `${this.formSubHeading} ${this.$t(
          'register.page.note',
        )}`;
      }
    },
    handleBack() {
      this.$emit('back');
      this.$postMessage.send(EXTERNAL_CONFIGS.EVENTS.GO_BACK);
    },
    successWorkflow() {
      Utils.storeInLocalStorage(
        EXTERNAL_CONFIGS.STORAGE_KEYS.VERIFIED_USERNAME,
        this.email,
        '10',
        'minutes',
      );
      this.$postMessage.send(EXTERNAL_CONFIGS.EVENTS.EMAIL_VERIFIED, {
        username: this.email,
      });
      this.$emit('success');
    },
    errorWorkflow() {},
    submit() {
      const app = this;
      if (this.registerForMvp) {
        app.submitForm(() =>
          app.nhstAuthClient.registerFreeUserMvp({ username: app.email }),
        );
        return;
      }
      this.successWorkflow();
    },
  },
};
</script>
