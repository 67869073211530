<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <PasswordField
          @input="showConfirmBox=false"
          :field-name="$t('password.new.label')"
          :placeholder="$t('password.new.placeholder')"
          :disabled="disabled"/>
        <PasswordField
          @input="showConfirmBox=false"
          :field-name="$t('password.repeat.label')"
          :placeholder="$t('password.repeat.placeholder')"
          :use-password-state="false"
          v-model="passwordConfirm"
          :rules="{required: true, password_confirm: password}"
          :disabled="disabled"
          type="password"/>
      </div>
      <div>
        <Confirmation
          v-if="showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="submitting || disabled"
          :loading="submitting">
          {{ buttonText || $t('reset.password.complete.button.text') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import PasswordField from '../elements/PasswordField';
import LoadingButton from '../elements/LoadingButton';
import Confirmation from '../elements/Confirmation';
import { ajaxForm, emailSync, autoLogin, formDescriptionHander } from '@mixins';
import { LOGIN } from '@router/constants';

export default {
  name: 'zephr-password-reset',
  components: {
    PasswordField,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm, emailSync, autoLogin, formDescriptionHander],
  props: {
    description: {
      type: String,
      required: false,
      default: () => ''
    },
    buttonText: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data() {
    return {
      passwordConfirm: ''
    };
  },
  computed: {
    token: function () {
      return this.$route.params.token;
    },
    confirmMsgs: function () {
      return {
        400: {
          title: this.$t('zephr.reset.password.complete.400.title'),
          message: this.$t('zephr.reset.password.complete.400.description')
        },
        404: {
          title: this.$t('zephr.reset.password.complete.400.title'),
          message: this.$t('zephr.reset.password.complete.400.description')
        },
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.$t('reset.password');
      this.formSubHeading = this.$t('reset.password.complete.description');
    },
    async successWorkflow() {
      this.showConfirmBox = false;
      return this.$router.push({ name: LOGIN });
    },
    errorWorkflow() {
    },
    submit() {
      const app = this;
      return app.submitForm(
        () => app.zephrPublicClient.resetPasswordCompleteUsingLink(
          this.password,
          this.token,
        ),
        true
      );
    }
  }
};
</script>
