<template>
  <ValidationProvider
    :name="fieldName"
    v-slot="{ errors }"
    :rules="rules"
    slim>
    <GeneralTextField
      v-model="inputVal"
      :field-name="fieldName"
      :error="errors.length > 0"
      v-bind="$attrs">
      <template #append-icon>
        <slot name="append-icon"></slot>
      </template>
    </GeneralTextField>
    <transition
      name="expand"
      mode="out-in">
      <p
        v-show="errors.length > 0"
        v-html="errors[0]"
        class="error-msg"></p>
    </transition>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import GeneralTextField from '@elements/GeneralTextField';

export default {
  name: 'text-field',
  components: {
    GeneralTextField,
    ValidationProvider
  },
  props: {
    fieldName: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: String,
      default: '',
      required: false
    },
    rules: {
      default: () => {
      },
      type: Object,
      required: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
};
</script>
