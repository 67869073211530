<template>
  <AuthContainer>
    <template #form>
      <CorporateSSOLogin/>
    </template>
  </AuthContainer>
</template>

<script>

import AnonymousPage from '@pages/AnonymousPage';
import { AuthContainer, CorporateSSOLogin } from '@components';

export default {
  name: 'corporate-sso-login-page',
  components: {
    AuthContainer,
    CorporateSSOLogin,
  },
  extends: AnonymousPage,
  methods: {
    getTitle() {
      return `Corporate SSO login | ${this.publication.title}`;
    },
    getRedirectLocation() {
      return '/';
    }
  }

};
</script>
