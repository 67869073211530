import { UPDATE_SERVER_SIDE_CHECKING } from '@store';
import Utils from '@root/src/helpers/utils';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

export default {
  install(Vue) {
    Vue.prototype.$authWebapp = {
      decodeURIUntilOriginalFound(url) {
        let maxTries = 10;
        let curUrl = url;
        let newUrl = url;
        while (maxTries-- > 0) {
          newUrl = decodeURIComponent(curUrl);
          if (newUrl === curUrl) {
            break;
          }
          curUrl = newUrl;
        }
        return newUrl;
      },
      hasComeBackFromAnotherPageInThisApp() {
        if (isServer) {
          return false;
        }
        return Boolean(window.$vue.route.from.name);
      },
      goBack() {
        if (isServer || !this.hasComeBackFromAnotherPageInThisApp()) {
          return;
        }
        window.$vue.$router.go(-1);
      },
      getPreviousRouteInThisApp() {
        return window.$vue.$store.state.route.from || {};
      },
      gotoFrontPage() {
        window.top.location.href = this.getFrontPageUrl();
      },
      getFrontPageUrl() {
        return window.top.location.origin;
      },
      getPreviousRouteNameInThisApp() {
        return this.getPreviousRouteInThisApp().name;
      },
      getArticleId(articleUrl) {
        try {
          const url = new URL(articleUrl, window.location);
          let pathname = url.pathname;
          pathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
          return (pathname || '/').split('/').pop() || '';
        } catch (e) {
          console.error(
            `Error getting article id from url for article url ${articleUrl}, err: ${e}`
          );
          return '';
        }
      },
      isLoggedIn() {
        // we should not check this in server side as it will cause problem in caching
        // we are caching all vue pages currently
        if (isServer) {
          return false;
        }
        const oneidInfo = Utils.getCookie('oneid_info') || '';
        const loggedIn = Boolean(oneidInfo.trim());
        logger.info(`User logged in -> ${loggedIn}`);
        return loggedIn;
      },
      showLoader() {
        this.startServerSideCheckingForField(LOADING_TYPES.FULL_PAGE);
      },
      hideLoader() {
        this.finishServerSideCheckingForField(LOADING_TYPES.FULL_PAGE);
      },
      startServerSideCheckingForField(fieldName) {
        window.$store.commit(UPDATE_SERVER_SIDE_CHECKING, {
          fieldName,
          shouldAdd: true
        });
      },
      finishServerSideCheckingForField(fieldName) {
        window.$store.commit(UPDATE_SERVER_SIDE_CHECKING, {
          fieldName,
          shouldAdd: false
        });
      },
      isFieldBeingCheckedOnServerSide(fieldName) {
        return window.$store.state.serverSideCheckingFields.includes(fieldName);
      },
      isEmailBeingCheckedOnServerSide() {
        return this.isFieldBeingCheckedOnServerSide(LOADING_TYPES.EMAIL);
      },
      // initialize nhstDataLayer if it is not initialized yet
      initializeAnalyticsDataLayer() {
        window.nhstDataLayer = window.nhstDataLayer || {};
        window.nhstDataLayer.events = window.nhstDataLayer.events || [];
      },
      pushAnalyticsEvent(event) {
        this.initializeAnalyticsDataLayer();
        window.nhstDataLayer.events.push({
          ...event,
          timestamp: Math.floor(new Date().getTime() / 1000)
        });
      },
      fixUrl(urlToFix) {
        try {
          const url = new URL(urlToFix, window.location);
          // workaround for vev when it contains /paywall/paywall.html
          // This should have been fixed by frontline
          const urlsToRemove = [/\/paywall\/paywall.html\/?$/];
          urlsToRemove.forEach(urlToRemove => {
            if (urlToRemove.test(url.pathname)) {
              url.pathname = url.pathname.replace(urlToRemove, '');
            }
          });
          url.searchParams.forEach((value, key) => {
            // this auto encodes the value
            url.searchParams.set(
              key,
              this.decodeURIUntilOriginalFound(value)
            );
          });
          return url.href;
        } catch (e) {
          console.error(`Error fixing url: ${urlToFix}, err: ${e}`);
        }
        return urlToFix;
      },
      getTarget(defaultTarget = '/') {
        const route = window.$store.state.route;
        let target =
          route.query.target ||
          route.query.articleUrl ||
          route.query.article_url;
        if (!target) {
          target = Utils.readAndDeleteFromLocalStorage(
            EXTERNAL_CONFIGS.STORAGE_KEYS.TARGET
          );
        }
        target = target || defaultTarget;
        return this.fixUrl(this.decodeURIUntilOriginalFound(target));
      },
      pushAnalyticsTrackingEventForGlobalOrderflow() {
        const eventTitle = 'REGISTRATION_UPGRADE_ORDER_FLOW';
        const eventName = this.isLoggedIn()
          ? 'Confirmation'
          : 'Validate Code And Change Password';
        const paywallVariation =
          sessionStorage.getItem(
            EXTERNAL_CONFIGS.STORAGE_KEYS.PAYWALL_VARIATION
          ) || '';
        const registrationService = paywallVariation ? 'PAYWALL' : 'ORDERFLOW';
        const event = {
          event: eventTitle,
          eventName: `Order Flow | ${eventName}`,
          flowSourceUrl: this.getTarget('') || '',
          registrationService,
          paywallVariation,
          designTemplate: '',
          price: '',
          productCode: '',
          offerCode: '',
          paymentMethod: '',
          paymentTerm: '',
          campaignCode: '',
          marketActivityCode: ''
        };
        console.debug('[Pushing Analytics Event]', event);
        this.pushAnalyticsEvent(event);
      },
      getSource(url = '', defaultBaseUrl = '') {
        let currentUrl = url;
        let baseUrl = defaultBaseUrl;
        const sourceParamName = 'source';
        let isExternalView = false;
        let parentUrl = '';
        if (!Utils.isServerSide()) {
          const zephrCdn = window.$store.state.publication.zephrCDN;
          isExternalView = window.$store.state.isExternalView;
          if (isExternalView) {
            parentUrl = window.parent.location.href;
          }
          currentUrl = currentUrl || window.top.location.href;
          baseUrl = baseUrl || zephrCdn;
        }
        const getSource = url => {
          return url.searchParams.get('source');
        };
        const getTarget = url => {
          return url.searchParams.get('target');
        };
        const getSourceFromTarget = url => {
          const target = getTarget(url);
          if (!target) {
            return null;
          }
          const targetUrl = new URL(target, baseUrl);
          return targetUrl.searchParams.get(sourceParamName);
        };
        // Sources can be the following
        // (Note if a source is not present in the regex, then it needs to be specified in "source" param)
        //
        // Orderflow: Log in page when the user does not have a profile (new user).
        // Paywall: Plus articles
        // WCG (kampanje.dn.no?)
        // Get alerts page
        // Create "Leseliste" (Reading list)
        // Newsletter subscription page
        // PAYWALL Free user access: 5 gratis articles
        // Free user access: Access free article after receiving article share token
        // App usage
        // Fantasy fond
        // Investor
        // Voting for Lederstjerne (discontinued)
        // NOTE: this url should be without query params
        // otherwise will fail for some cases (unless you know what you are doing)
        const getSourceFromRegexMapping = urlWithoutQueryParams => {
          const regexMap = [
            {
              regex: /\/nyhetsbrev$/g,
              value: 'NEWSLETTER_SUBSCRIPTION_PAGE'
            },
            {
              regex: /\/alert$/g,
              value: 'ALERT'
            },
            {
              regex: /\/alert\/magasine$/g,
              value: 'ALERT'
            },
            {
              regex: /https:\/\/pulstest\.dn\.no/g,
              value: 'INVESTOR'
            },
            {
              regex: /https:\/\/pulsstage\.dn\.no/g,
              value: 'INVESTOR'
            },
            {
              regex: /https:\/\/investor\.dn\.no/g,
              value: 'INVESTOR'
            }
          ];
          const matches = regexMap.filter(rMap => {
            return Boolean(rMap.regex.test(urlWithoutQueryParams));
          });
          return matches.length > 0 ? matches[0].value : null;
        };
        const extractSourceUsingRegex = url => {
          const generatedUrl = new URL(url, baseUrl);
          const generatedUrlWithoutQueryParams = `${generatedUrl.origin}${generatedUrl.pathname}`;
          return getSourceFromRegexMapping(generatedUrlWithoutQueryParams);
        };
        const extractSourceFromTarget = url => {
          const target = getTarget(url);
          if (!target) {
            return null;
          }
          return extractSourceUsingRegex(target);
        };
        const defaultSource = 'FREE_USER_REGISTRATION';
        try {
          const url = new URL(currentUrl, baseUrl);
          let source =
            getSource(url) ||
            (parentUrl ? extractSourceUsingRegex(parentUrl) : null) ||
            getSourceFromTarget(url) ||
            extractSourceFromTarget(url);
          source = source ? source.toUpperCase() : source;
          console.log(`[Found Source] ${source}`);
          return source || defaultSource;
        } catch (e) {
          console.error(e);
          return defaultSource;
        }
      }
    };
  }
};
