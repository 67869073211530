<template>
  <AuthContainer>
    <template #form>
      <ActivateSubscription/>
    </template>
  </AuthContainer>
</template>
<script>

import {
  AuthContainer,
  ActivateSubscription
} from '../components';
import AnonymousPage from '@pages/AnonymousPage.js';

export default {
  name: 'activate-subscription-page',
  components: {
    ActivateSubscription,
    AuthContainer,
  },
  extends: AnonymousPage,
  methods: {
    getTitle() {
      return `Activate Subscription | ${this.publication.title}`;
    },
    getRedirectLocation() {
      return '/';
    }
  }
};

</script>
