<template>
  <div class="nhst-logos">
    <div class="container">
      <div class="row row--dense justify-content-between">
        <div class="col-sm-7 order-2 order-sm-1">
          <p v-if="!isFreeUserRegForm" v-html="$t('nhst.id.description')"></p>
        </div>
        <div class="col-sm-5 d-flex flex-row flex-sm-row-reverse nhst-logo order-1 order-sm-2 mb-4">
          <img
            :src="$cdn.resource('dn-media-group.svg')"
            class="align-self-center"
            alt="dn-media-group">
        </div>
      </div>
    </div>
    <p v-if="!isFreeUserRegForm"
      v-html="$t('nhst.id.popup.definition')"
      class="info-link mb-6"></p>
    <ClientOnly>
      <div
        v-if="showPublicationLogos && !isFreeUserRegForm"
        class="logo column-gap-10 column-gap-sm-0 row-sm d-flex flex-wrap justify-content-between">
        <div
          :key="`${pub.key}-monochrome-logo`"
          v-for="pub in renderPubs"
          class="mb-5 col-sm-4">
          <div class="logo flex-grow-1">
            <InlineSvg
              v-if="pub.name"
              :src="`${$cdn.resource(`${pub.name.toLowerCase()}/monochrome-logo.svg`)}`"
              :alt="`${pub.name}`"
              :style="pub.img"></InlineSvg>
            <div
              v-else
              class="empty-img"></div>
          </div>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script>

import { nhstLogos } from '@mixins';
import InlineSvg from 'vue-inline-svg';
import ClientOnly from '@components/ClientOnly.vue';

export default {
  name: 'nhst-logos',
  components: {
    ClientOnly,
    InlineSvg,
  },
  mixins: [nhstLogos]
};
</script>

<style scoped lang="scss">
@import "@assets/scss/variables.scss";

.row {
  margin: 0;
}

.nhst-logos {
  .info-link {
    text-align: left;
  }

  div {
    padding: 0;
  }

  font-size: 12px;
  line-height: 14.4px;
  text-align: left;
  font-weight: normal;

  .logo {
    .empty-img {
      width: 90px;
      height: 5px;
    }
  }

  p {
    padding: 0;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 18px;
  }

  .column-gap-sm-0 {
    @media screen and (min-width: $sm) {
      column-gap: 0 !important;
    }
  }

  .column-gap-10 {
    column-gap: 10px;
  }

}
</style>
