<template>
  <div class="site-header js-site-header">
    <iframe
      id="dn-header-iframe"
      :src="standaloneHeaderUrl"
      class="header-bar__iframe header-bar--sticky"
      width="100%"
      height="97"
      scrolling="no"
      frameborder="0"></iframe>
    <script :src="`${lanternStaticUrl}/dn/static/assets/scripts/lib/headroom.min.js`"></script>
  </div>
</template>
<script>

export default {
  name: 'dn-header',
  data: function () {
    return {
      standaloneHeaderUrl: dconf.DN_STANDALONE_HEADER_URL,
      lanternStaticUrl: dconf.LANTERN_STATIC_URL
    };
  },
  mounted() {
    if (appEnv !== 'dev') {
      this.standaloneHeaderUrl = `${this.publication.zephrCDN}${this.standaloneHeaderUrl}`;
    }
    console.log(`Header url: ${this.standaloneHeaderUrl}`);
    const app = this;
    function eventHandler(event) {
      if (event && event.data) {
        const data = event.data;
        if (data['header-height'] !== undefined) {
          app.setHeight(data);
        }
        if (data['toggle-body-class'] !== undefined) {
          app.setFlyout(data);
        }
      }
    }

    if (window.addEventListener) {
      window.addEventListener('message', eventHandler, false);
    } else {
      window.attachEvent('onmessage', eventHandler);
    }
  },
  methods: {
    setHeight(data) {
      const height = data['header-height'];
      const header = document.getElementById('dn-header-iframe');
      header.setAttribute('height', height);
    },
    setFlyout(data) {
      const isFlyoutOpen = data['toggle-body-class'];
      const root = document.getElementsByTagName('html')[0];

      if (isFlyoutOpen === 'is-flyout-open') {
        root.className += ' is-flyout-open';
      } else {
        root.className = root.className.replace(/(?:^|\s)is-flyout-open(?!\S)/g, '');
      }
    }
  }
};
</script>
