<!--Will render only on client side-->
<template>
  <div v-if="isClient">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'client-only',
  data() {
    return {
      isClient: false
    };
  },
  mounted() {
    this.isClient = true;
  }
};
</script>
