import deepmerge from 'deepmerge';
/* class: css classes
* type: button type, for example: button or submit
* recaptchaRequired: whether you need to click recaptcha before clicking the button
* disabled: whether button should be disabled
* value: the value to be emitted
* text: the text to show
* */

class CustomButton {
  constructor(obj) {
    const newObj = deepmerge({}, obj);
    Object.keys(newObj).forEach(key => {
      this[key] = newObj[key];
    });
  }
}

export default CustomButton;
