<script>
import { emailSync } from '@mixins';

export default {
  name: 'landing-page',
  mixins: [emailSync],
  props: {
    prop: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<template>
  <div class="page-content">
    <div class="text-content">
      <slot name="title">
        <h1>Du har fått gratis tilgang til DN</h1>
      </slot>
      <slot name="text">
        <p v-if="userExists">
          Å komme i gang er ikke vanskelig. Du trenger kun å logge deg inn for å
          få tilgang.
        </p>
        <p v-else>
          Å komme i gang er ikke vanskelig. Du trenger kun å opprette en gratis
          bruker. Det tar under ett minutt.
        </p>
      </slot>
      <slot name="default" />
    </div>
  </div>
</template>
