import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import {
  nhstAuthStore,
  nhstAuthPlugin,
  corporateStore,
  ssoStore,
  userStore,
  familySharingStore,
} from '../modules';
import { LOADING_TYPES } from '@root/src/helpers/AppConfigs';

const store = {
  state: {
    langConfig: {},
    isMobileView: false,
    isPopupView: false,
    isExternalView: false,
    externalClientName: '',
    showComponent: true,
    formDescription: {
      heading: '',
      subHeading: '',
    },
    // fields that are currently being checked/validated against server
    serverSideCheckingFields: [LOADING_TYPES.FULL_PAGE],
    showCloseIcon: false,
    redirectedFrom: {},
    showPublicationLogos: 0,
    currentStep: 0,

    // Fix for strange autocomplete behavior from Chrome
    // Needs to be synced across username and password fields
    autocomplete: '',
  },
  actions,
  mutations,
  getters,
  modules: {
    nhstAuth: nhstAuthStore,
    corporate: corporateStore,
    sso: ssoStore,
    user: userStore,
    familySharing: familySharingStore,
  },
  plugins: [nhstAuthPlugin],
};

export default store;
