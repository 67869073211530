<template>
  <transition
    name="expand"
    mode="out-in">
    <div
      v-show="showComponent"
      :class="['auth']">
      <div
        v-if="showCloseIcon"
        class="close-icon-container d-flex justify-content-end px-4">
        <div class="close-icon">
          <InlineSvg
            @click="closePopup"
            :src="$cdn.resource('close.svg')"
            alt="close"/>
        </div>
      </div>
      <div>
        <FormSteps
          v-if="isFreeUserRegForm"
          :active="currentStep"/>
        <div
          v-if="showPubLogo"
          class="pub-top-logo-wrapper">
          <div class="pub-top-logo">
            <InlineSvg
              :src="$cdn.resource(`${publication.name.toLowerCase()}/logo.svg`)"
              :alt="`${publication.name.toLowerCase()}-logo`"/>
          </div>
        </div>
        <ClientOnly>
          <div
            v-if="shouldShowFormDescriptionComponent"
            :class="['form-description-wrapper']">
            <FormDescription/>
          </div>
        </ClientOnly>
        <div
          :class="['form-wrapper']">
          <div class="form-section">
            <slot name="form"></slot>
          </div>
        </div>
        <div
          :class="['nhst-logos-wrapper']">
          <NhstLogos/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NhstLogos from './NhstLogos';
import InlineSvg from 'vue-inline-svg';
import FormDescription from '@elements/FormDescription';
import { closeIconController, formDescriptionHander } from '@mixins';
import ClientOnly from '@components/ClientOnly';
import FormSteps from '@elements/Step';

export default {
  name: 'auth-container',
  components: {
    ClientOnly,
    FormDescription,
    NhstLogos,
    InlineSvg,
    FormSteps,
  },
  mixins: [formDescriptionHander, closeIconController],
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    showPopupViewCloseIcon: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data() {
    return {
      skipFormDescriptionChanges: true,

    };
  },
  computed: {
    // dont change this logic if you dont know how computed property works
    showComponent() {
      return this.$store.state.showComponent;
    },
    showPubLogo() {
      return !this.isExternalView && !this.isFreeUserRegForm;
    },
    currentStep() {
      const currentStep = this.$store.state.currentStep;
      return currentStep + 1;
    },
  },
  methods: {
    closePopup() {
      this.clickedCloseIcon();
    },
    // Overriding default implementation so that it does not show any error in the console
    // It will be implemented by other components
    handleCloseIconClicked() {

    },
  },
};
</script>

<style lang="scss">

@import "@assets/scss/variables.scss";
@import "@assets/scss/colors.scss";

$gap: 30px;
$segment-border: .5px solid rgba(55, 78, 121, 0.2);

.popup {
  .auth {
    padding: 0;
    max-width: 520px;
    // add container (with relative attr) so that the elements do not take any extra space
    .close-icon-container {
      position: relative;

      .close-icon {
        svg {
          height: 16px;
          width: 16px;
        }

        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: $sm){
      max-height: 1120px;
    }

    //.nhst-logos-wrapper {
    //  margin-top: 32px;
    //  padding-bottom: 0;
    //}
  }
}

.external-view {
  .auth {
    margin: auto;
    max-width: 100%;

    .nhst-logos, .nhst-logos-short {
      margin: auto;
      height: 100%;
    }

    .form-description-wrapper {
      margin-top: 28px;
    }
  }
}

.auth {
  background-color: #FFFFFF;
  max-width: 520px;
  width: 100%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0;

  & > * {
    max-width: 100%;
  }

  .nhst-logos, .form-section, .form-description, .pub-top-logo {
    margin: auto;
    height: 100%;
    max-width: 440px;
    padding: 0 24px;
    @media screen and (min-width: $sm) {
      padding: 0;
    }
  }

  .pub-top-logo-wrapper {
    padding-bottom: $gap;
    margin-top: 40px;
    border-bottom: $segment-border;
  }

  .form-description-wrapper {
    margin-top: 28px;
  }

  .form-wrapper {
    padding-top: $gap;
    border-bottom: $segment-border;
    padding-bottom: $gap;
    //margin-top: $gap;

    //@media screen and (min-width: $sm) {
    //  margin-top: 48px;
    //}
  }

  .nhst-logos-wrapper {
    margin-top: $gap;
    padding-bottom: 0;
  }

  .form-section {
    height: 100%;

    & > * {
      height: 100%;
    }

    input {
      width: 100%;
      padding: $input-padding;

      &:disabled {
        color: $disabled-color;
        opacity: $disabled-opacity;
      }
    }

    .disabled {
      input {
        color: $disabled-color;
        opacity: $disabled-opacity;
      }
    }
  }

  .expand {
    &-enter-active,
    &-leave-active {
      transition: all .2s ease-in-out;
      overflow: hidden;
    }

    &-enter-to,
    &-leave {
      opacity: 1;
      max-height: 700px;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      max-height: 0;
    }
  }
}
</style>
