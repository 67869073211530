<template>
  <div
    :style="loaderStyle"
    class="loader">
    <font-awesome-icon
      :style="fontAwesomeStyle"
      size="9x"
      icon="spinner"
      spin
      pulse />
  </div>
</template>

<script>
export default {
  name: 'loader',
  computed: {
    loaderStyle() {
      const defaultStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        zIndex: '100',
        position: 'fixed',
        left: '0',
        top: '0',
        backgroundColor: 'white',
        opacity: '0.8'
      };
      if (isServer) {
        return defaultStyle;
      }
      if (!this.$store.state.showComponent) {
        return Object.assign(defaultStyle, {
          background: 'transparent',
          opacity: 1,
        });
      }
      return defaultStyle;
    },
    fontAwesomeStyle() {
      if (isServer) {
        return {
          maxHeight: '9em',
          maxWidth: '9em'
        };
      }
      return {};
    }
  }
};
</script>
<style lang="scss" scoped>

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
