const familySharing = {
  computed: {
    /**
     * Get sharing id from the store
     *
     * @return {string} Plain sharing id that can be used to accept invite
     */
    sharingId() {
      return this.$store.state.familySharing.id || '';
    },
    /**
     * Flag to determine if user existed or not when landing on family sharing
     * accept invite page. This will be used in analytics when pushing adobe events.
     *
     * @returns {boolean} Returns true if user existed before and false if user
     *                    is newly registered for family sharing
     */
    userExistsFS() {
      return window.localStorage.getItem('userExistsFS') === 'true';
    },
    /**
     * Share details that holds information on the product and more importantly
     * for us meta data that contains information on who this family share is
     * meant for
     *
     * @return {object} Details on family share invite
     */
    sharingDetails() {
      return this.$store.state.familySharing.details || {};
    },
    /**
     * Check if sharing id is correct or not.
     * We assume that if `meta_data` is present that sharing id is correct.
     *
     * @return {boolean} Returns true if sharing id is correct else false
     */
    sharingIdCorrect() {
      return !!this.sharingDetails.meta_data;
    },
    /**
     * Returns boolean saying if it is family sharing.
     * Basically copy of sharingIdCorrect but named more transparently
     *
     * @return {boolean} True if is family sharing otherwise false
     */
    isFamilySharing() {
      return this.sharingIdCorrect;
    },
    /**
     * Returns email of the person this invite is meant for.
     *
     * @return {string} Email of the recipient or empty string if not found
     */
    recepientEmail() {
      if (
        this.sharingIdCorrect &&
        this.sharingDetails.meta_data.recepient_email
      ) {
        return this.sharingDetails.meta_data.recepient_email;
      }

      return '';
    },
    /**
     * In case user is logged in check if user is one meant for invite
     *
     * @return {boolean} Returns true if user is not meant to receive this invite
     *                   or false if it is OK to proceed with invite
     */
    incorrectUser() {
      if (this.loggedIn) {
        return (
          this.recepientEmail.toLowerCase() !==
          this.getLoggedInUserEmail().toLowerCase()
        );
      }

      return false;
    },
    /**
     * Returns url to the on boarding page that is set up.
     * At the moment there is only one page so we just return url.
     * In the future it is possible that there could be more.
     *
     * @return {string} Url to the onboarding page
     */
    fsOnboardingUrl() {
      return 'https://www.dn.no/staticprojects/kommersielt/onboardingfamilie/';
    },
  },
  methods: {
    /**
     * This method is used to accept family share invite for the user.
     * User needs to be logged in for this to work as intended
     */
    async acceptShareInvite() {
      await this.authServices.acceptProductShare(this.sharingId);
    },
  },
};

export { familySharing };
