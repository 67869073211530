<template>
  <div :class="['confirm', 'd-flex', 'p-2', 'mb-3', 'mt-5', success ? 'success': 'error']">
    <div class="col-1 d-flex justify-content-center align-items-center">
      <img
        :src="$cdn.resource(image)"
        :alt="success? 'success': 'error'">
    </div>
    <div class="col-11 ps-3">
      <div
        v-if="title"
        v-html="title"
        class="title pb-1">

      </div>
      <div
        v-if="message"
        v-html="message"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirmation',
  props: {
    success: {
      type: Boolean,
      default: true,
      required: false
    },
    image: {
      type: String,
      required: false,
      default: function () {
        return this.success ? 'check.png' : 'error.png';
      }
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
.confirm {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-style: normal;

  .title {
    font-weight: bold;
  }

  &.success {
    border: 1px solid #76B474;
    border-radius: 4px;
  }
  &.error {
    border: 1px solid #CC2128;
    border-radius: 4px;
  }
}

</style>
