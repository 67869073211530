<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <EmailField
          :rules="{required: true, finish_server_side_email_checking: true}"
          :field-name="$t('username.label')"
          :placeholder="$t('email.placeholder')"
          :disabled="disabled"
          @input="showConfirmBox = false"
          name="email"/>
      </div>
      <div>
        <GoogleRecaptcha
          ref="grecaptcha"
          :show-captcha="!success"
          v-slot:default="{props}">
          <Confirmation
            v-if="showConfirmBox"
            :success="success"
            :message="confirmMsg.message"
            :title="confirmMsg.title"
            class="pb-2"/>
          <TermsAndConditionsView
            :title="$t('terms.and.conditions.accept')"
            :body="$t('terms.and.conditions.body', { pub: publication.title })"
            :show-terms-and-conditions="userLoggedInBefore === false || forCampaign === true"
            class="mt-0">
            <template #container>
              <LoadingButton
                :disabled="submitting || disabled || !props.recaptchaOk || Boolean(serverSideEmailChecking)"
                :loading="submitting"
                button-class="btn-standard mt-0">
                {{ $t('reset.password.start.button.text') }}
              </LoadingButton>
              <button
                :disabled="disabled || submitting"
                @click="handleBack"
                v-if="route.from && route.from.name"
                type="button"
                class="btn-secondary mt-3">
                {{ $t('go.back') }}
              </button>
            </template>
          </TermsAndConditionsView>
        </GoogleRecaptcha>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '../elements/LoadingButton';
import Confirmation from '../elements/Confirmation';
import EmailField from '../elements/EmailField';
import TermsAndConditionsView from '@root/src/components/elements/TermsAndConditionsView';
import { ajaxForm, emailSync } from '@mixins';
import GoogleRecaptcha from '@components/GoogleRecaptcha';
import { LOGIN } from '@router/constants';

export default {
  name: 'nhst-reset-password-start',
  components: {
    GoogleRecaptcha,
    EmailField,
    Confirmation,
    LoadingButton,
    ValidationObserver,
    TermsAndConditionsView
  },
  mixins: [ajaxForm, emailSync],
  props: {
    forCampaign: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      ncpEmail: '',
      usingNcp: false,
      loginRoute: LOGIN,
    };
  },
  computed: {
    confirmMsg: function () {
      if (this.usingNcp && this.success) {
        return {
          title: this.$t('reset.password.start.201.title'),
          message: this.$t('reset.password.start.201.description', { email: this.ncpEmail })
        };
      }
      return this.confirmMsgs[this.serverResponseCode] || { title: '', message: this.$t('auth.error.unexpected') };
    },
    confirmMsgs: function () {
      return {
        429: {
          title: '',
          message: this.$t('general.error.429.message')
        }
      };
    },
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    successWorkflow() {
      this.usingNcp = this.serverResponseMessage.usingNcp || false;
      if (this.usingNcp) {
        this.ncpEmail = this.serverResponseMessage.email;
        this.disabled = true;
        return;
      }
      this.$emit('success');
      this.showConfirmBox = false;
    },
    errorWorkflow() {
    },
    updateShouldValidateUserLoggedInBeforeWithServer() {
      this.shouldValidateUserLoggedInBeforeWithServer = true;
    },
    async submit() {
      const app = this;
      return app.submitForm(
        () => app.subAuthClient.resetPasswordStart(this.email),
        true,
        true
      );
    }
  },
};
</script>
