<template>
  <AuthContainer>
    <template #form>
      <NhstResetPassword :title="title"/>
    </template>
  </AuthContainer>
</template>
<script>

import {
  AuthContainer,
  NhstResetPassword
} from '../components';
import AnonymousPage from '@pages/AnonymousPage.js';

export default {
  name: 'reset-password-page',
  components: {
    AuthContainer,
    NhstResetPassword
  },
  extends: AnonymousPage,
  props: {
    title: {
      type: String,
      required: false,
      default: function () {
        return this.$t('reset.password');
      }
    }
  },
  data() {
    return {
      usesRecaptcha: true
    };
  },
  methods: {
    getTitle() {
      return `${ this.title } | ${ this.publication.title }`;
    },
    getRedirectLocation() {
      return '/';
    }
  }
};

</script>
