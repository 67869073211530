export default
[
  {
    code: 'AF',
    currency: 'USD',
    name: 'AFGHANISTAN'
  },
  {
    code: 'AL',
    currency: 'EUR',
    name: 'ALBANIA'
  },
  {
    code: 'DZ',
    currency: 'EUR',
    name: 'ALGERIA'
  },
  {
    code: 'AS',
    currency: 'USD',
    name: 'AMERICAN SAMOA'
  },
  {
    code: 'AD',
    currency: 'EUR',
    name: 'ANDORRA'
  },
  {
    code: 'AO',
    currency: 'EUR',
    name: 'ANGOLA'
  },
  {
    code: 'AI',
    currency: 'USD',
    name: 'ANGUILLA'
  },
  {
    code: 'AQ',
    currency: 'USD',
    name: 'ANTARCTICA'
  },
  {
    code: 'AG',
    currency: 'USD',
    name: 'ANTIGUA & BARBUDA'
  },
  {
    code: 'AN',
    currency: 'USD',
    name: 'ANTILLES (NL)'
  },
  {
    code: 'AR',
    currency: 'USD',
    name: 'ARGENTINA'
  },
  {
    code: 'AM',
    currency: 'EUR',
    name: 'ARMENIA'
  },
  {
    code: 'AW',
    currency: 'USD',
    name: 'ARUBA'
  },
  {
    code: 'AU',
    currency: 'USD',
    name: 'AUSTRALIA'
  },
  {
    code: 'AT',
    currency: 'EUR',
    name: 'AUSTRIA'
  },
  {
    code: 'AZ',
    currency: 'EUR',
    name: 'AZERBAIJAN'
  },
  {
    code: 'BS',
    currency: 'USD',
    name: 'BAHAMAS'
  },
  {
    code: 'BH',
    currency: 'USD',
    name: 'BAHRAIN'
  },
  {
    code: 'BD',
    currency: 'USD',
    name: 'BANGLADESH'
  },
  {
    code: 'BB',
    currency: 'USD',
    name: 'BARBADOS'
  },
  {
    code: 'BE',
    currency: 'EUR',
    name: 'BELGIUM'
  },
  {
    code: 'BZ',
    currency: 'USD',
    name: 'BELIZE'
  },
  {
    code: 'BY',
    currency: 'EUR',
    name: 'BELORUSSIA'
  },
  {
    code: 'BJ',
    currency: 'EUR',
    name: 'BENIN'
  },
  {
    code: 'BM',
    currency: 'USD',
    name: 'BERMUDA'
  },
  {
    code: 'BT',
    currency: 'USD',
    name: 'BHUTAN'
  },
  {
    code: 'BO',
    currency: 'USD',
    name: 'BOLIVIA'
  },
  {
    code: 'BA',
    currency: 'EUR',
    name: 'BOSNIA-HERCEGOVINA'
  },
  {
    code: 'BW',
    currency: 'EUR',
    name: 'BOTSWANA'
  },
  {
    code: 'BV',
    currency: 'EUR',
    name: 'BOUVET ISLAND'
  },
  {
    code: 'BR',
    currency: 'USD',
    name: 'BRAZIL'
  },
  {
    code: 'IO',
    currency: 'USD',
    name: 'BRITISH INDIAN OCEAN TERRITORY'
  },
  {
    code: 'BN',
    currency: 'USD',
    name: 'BRUNEI'
  },
  {
    code: 'BG',
    currency: 'EUR',
    name: 'BULGARIA'
  },
  {
    code: 'BF',
    currency: 'EUR',
    name: 'BURKINA FASO'
  },
  {
    code: 'BI',
    currency: 'EUR',
    name: 'BURUNDI'
  },
  {
    code: 'KH',
    currency: 'USD',
    name: 'CAMBODIA'
  },
  {
    code: 'CM',
    currency: 'EUR',
    name: 'CAMEROON'
  },
  {
    code: 'CA',
    currency: 'USD',
    name: 'CANADA'
  },
  {
    code: 'XB',
    currency: 'USD',
    name: 'CANARY ISLANDS'
  },
  {
    code: 'CV',
    currency: 'EUR',
    name: 'CAPE VERDE'
  },
  {
    code: 'KY',
    currency: 'USD',
    name: 'CAYMAN ISLANDS'
  },
  {
    code: 'CF',
    currency: 'EUR',
    name: 'CENTR AFRICAN REP'
  },
  {
    code: 'TD',
    currency: 'EUR',
    name: 'CHAD'
  },
  {
    code: 'CL',
    currency: 'USD',
    name: 'CHILE'
  },
  {
    code: 'CN',
    currency: 'USD',
    name: 'CHINA'
  },
  {
    code: 'CX',
    currency: 'USD',
    name: 'CHRISTMAS ISLAND'
  },
  {
    code: 'CC',
    currency: 'USD',
    name: 'COCOS (KEELING) ISLANDS'
  },
  {
    code: 'CO',
    currency: 'USD',
    name: 'COLOMBIA'
  },
  {
    code: 'KM',
    currency: 'EUR',
    name: 'COMOROS'
  },
  {
    code: 'CG',
    currency: 'EUR',
    name: 'CONGO'
  },
  {
    code: 'CK',
    currency: 'USD',
    name: 'COOK ISLANDS'
  },
  {
    code: 'CR',
    currency: 'USD',
    name: 'COSTA RICA'
  },
  {
    code: 'HR',
    currency: 'EUR',
    name: 'CROATIA'
  },
  {
    code: 'CY',
    currency: 'EUR',
    name: 'CYPRUS'
  },
  {
    code: 'CZ',
    currency: 'EUR',
    name: 'CZECH REPUBLIC'
  },
  {
    code: 'DK',
    currency: 'EUR',
    name: 'DENMARK'
  },
  {
    code: 'DJ',
    currency: 'EUR',
    name: 'DJIBOUTI'
  },
  {
    code: 'DM',
    currency: 'USD',
    name: 'DOMINICA'
  },
  {
    code: 'DO',
    currency: 'USD',
    name: 'DOMINICAN REPUBLIC'
  },
  {
    code: 'EC',
    currency: 'USD',
    name: 'ECUADOR'
  },
  {
    code: 'EG',
    currency: 'EUR',
    name: 'EGYPT'
  },
  {
    code: 'SV',
    currency: 'USD',
    name: 'EL SALVADOR'
  },
  {
    code: 'GQ',
    currency: 'EUR',
    name: 'EQUATORIAL-GUINEA'
  },
  {
    code: 'ER',
    currency: 'EUR',
    name: 'ERITREA'
  },
  {
    code: 'EE',
    currency: 'EUR',
    name: 'ESTONIA'
  },
  {
    code: 'ET',
    currency: 'EUR',
    name: 'ETHIOPIA'
  },
  {
    code: 'FK',
    currency: 'USD',
    name: 'FALKLAND ISLANDS'
  },
  {
    code: 'FO',
    currency: 'EUR',
    name: 'FAROE ISLANDS'
  },
  {
    code: 'FJ',
    currency: 'USD',
    name: 'FIJI'
  },
  {
    code: 'FI',
    currency: 'EUR',
    name: 'FINLAND'
  },
  {
    code: 'FR',
    currency: 'EUR',
    name: 'FRANCE'
  },
  {
    code: 'GF',
    currency: 'USD',
    name: 'FRENCH GUIANA'
  },
  {
    code: 'PF',
    currency: 'USD',
    name: 'FRENCH POLYNESIA'
  },
  {
    code: 'TF',
    currency: 'USD',
    name: 'FRENCH SOUTHERN TERRITORIES'
  },
  {
    code: 'GA',
    currency: 'EUR',
    name: 'GABON'
  },
  {
    code: 'GM',
    currency: 'EUR',
    name: 'GAMBIA'
  },
  {
    code: 'GE',
    currency: 'EUR',
    name: 'GEORGIA'
  },
  {
    code: 'DE',
    currency: 'EUR',
    name: 'GERMANY'
  },
  {
    code: 'GH',
    currency: 'EUR',
    name: 'GHANA'
  },
  {
    code: 'GI',
    currency: 'EUR',
    name: 'GIBRALTAR'
  },
  {
    code: 'GR',
    currency: 'EUR',
    name: 'GREECE'
  },
  {
    code: 'GL',
    currency: 'EUR',
    name: 'GREENLAND'
  },
  {
    code: 'GD',
    currency: 'USD',
    name: 'GRENADA'
  },
  {
    code: 'GP',
    currency: 'USD',
    name: 'GUADELOUPE'
  },
  {
    code: 'GU',
    currency: 'USD',
    name: 'GUAM'
  },
  {
    code: 'GT',
    currency: 'USD',
    name: 'GUATEMALA'
  },
  {
    code: 'GN',
    currency: 'EUR',
    name: 'GUINEA'
  },
  {
    code: 'GW',
    currency: 'EUR',
    name: 'GUINEA-BISSAU'
  },
  {
    code: 'GY',
    currency: 'USD',
    name: 'GUYANA'
  },
  {
    code: 'HT',
    currency: 'USD',
    name: 'HAITI'
  },
  {
    code: 'HM',
    currency: 'EUR',
    name: 'HEARD AND MCDONALD ISLANDS'
  },
  {
    code: 'HN',
    currency: 'USD',
    name: 'HONDURAS'
  },
  {
    code: 'HK',
    currency: 'USD',
    name: 'HONG KONG (POST)'
  },
  {
    code: 'HU',
    currency: 'EUR',
    name: 'HUNGARY'
  },
  {
    code: 'IS',
    currency: 'EUR',
    name: 'ICELAND'
  },
  {
    code: 'IN',
    currency: 'USD',
    name: 'INDIA'
  },
  {
    code: 'ID',
    currency: 'USD',
    name: 'INDONESIA'
  },
  {
    code: 'IQ',
    currency: 'USD',
    name: 'IRAQ'
  },
  {
    code: 'IE',
    currency: 'EUR',
    name: 'IRELAND'
  },
  {
    code: 'IL',
    currency: 'EUR',
    name: 'ISRAEL'
  },
  {
    code: 'IT',
    currency: 'EUR',
    name: 'ITALY'
  },
  {
    code: 'CI',
    currency: 'EUR',
    name: 'IVORY COAST'
  },
  {
    code: 'JM',
    currency: 'USD',
    name: 'JAMAICA'
  },
  {
    code: 'JP',
    currency: 'USD',
    name: 'JAPAN'
  },
  {
    code: 'JO',
    currency: 'USD',
    name: 'JORDAN'
  },
  {
    code: 'KZ',
    currency: 'EUR',
    name: 'KAZAKHSTAN'
  },
  {
    code: 'KE',
    currency: 'EUR',
    name: 'KENYA'
  },
  {
    code: 'KG',
    currency: 'EUR',
    name: 'KIRGHIZISTAN'
  },
  {
    code: 'KI',
    currency: 'USD',
    name: 'KIRIBATI'
  },
  {
    code: 'KR',
    currency: 'USD',
    name: 'KOREA SOUTH'
  },
  {
    code: 'KW',
    currency: 'USD',
    name: 'KUWAIT'
  },
  {
    code: 'LA',
    currency: 'EUR',
    name: 'LAOS'
  },
  {
    code: 'LV',
    currency: 'EUR',
    name: 'LATVIA'
  },
  {
    code: 'LB',
    currency: 'USD',
    name: 'LEBANON'
  },
  {
    code: 'LS',
    currency: 'EUR',
    name: 'LESOTHO'
  },
  {
    code: 'LR',
    currency: 'EUR',
    name: 'LIBERIA'
  },
  {
    code: 'LY',
    currency: 'EUR',
    name: 'LIBYA'
  },
  {
    code: 'LI',
    currency: 'EUR',
    name: 'LIECHTENSTEIN'
  },
  {
    code: 'LT',
    currency: 'EUR',
    name: 'LITHUANIA'
  },
  {
    code: 'LU',
    currency: 'EUR',
    name: 'LUXEMBOURG'
  },
  {
    code: 'MO',
    currency: 'USD',
    name: 'MACAU'
  },
  {
    code: 'MG',
    currency: 'EUR',
    name: 'MADAGASCAR'
  },
  {
    code: 'MK',
    currency: 'EUR',
    name: 'MAKEDONIA'
  },
  {
    code: 'MW',
    currency: 'EUR',
    name: 'MALAWI'
  },
  {
    code: 'MY',
    currency: 'USD',
    name: 'MALAYSIA'
  },
  {
    code: 'MV',
    currency: 'USD',
    name: 'MALDIVES'
  },
  {
    code: 'ML',
    currency: 'EUR',
    name: 'MALI'
  },
  {
    code: 'MT',
    currency: 'EUR',
    name: 'MALTA'
  },
  {
    code: 'MH',
    currency: 'USD',
    name: 'MARSHALL ISLANDS'
  },
  {
    code: 'MQ',
    currency: 'USD',
    name: 'MARTINIQUE'
  },
  {
    code: 'MR',
    currency: 'EUR',
    name: 'MAURITANIA'
  },
  {
    code: 'MU',
    currency: 'EUR',
    name: 'MAURITIUS'
  },
  {
    code: 'YT',
    currency: 'EUR',
    name: 'MAYOTTE'
  },
  {
    code: 'MX',
    currency: 'USD',
    name: 'MEXICO'
  },
  {
    code: 'FM',
    currency: 'USD',
    name: 'MICRONESIA, FEDERAL STATES OF'
  },
  {
    code: 'MD',
    currency: 'EUR',
    name: 'MOLDOVIA'
  },
  {
    code: 'MC',
    currency: 'EUR',
    name: 'MONACO POST'
  },
  {
    code: 'MN',
    currency: 'USD',
    name: 'MONGOLIA'
  },
  {
    code: 'MS',
    currency: 'USD',
    name: 'MONTSERRAT'
  },
  {
    code: 'MA',
    currency: 'EUR',
    name: 'MOROCCO'
  },
  {
    code: 'MZ',
    currency: 'EUR',
    name: 'MOZAMBIQUE'
  },
  {
    code: 'MM',
    currency: 'USD',
    name: 'MYANMAR'
  },
  {
    code: 'NA',
    currency: 'EUR',
    name: 'NAMIBIA'
  },
  {
    code: 'NR',
    currency: 'USD',
    name: 'NAURU'
  },
  {
    code: 'NP',
    currency: 'USD',
    name: 'NEPAL'
  },
  {
    code: 'NL',
    currency: 'EUR',
    name: 'NETHERLANDS'
  },
  {
    code: 'NC',
    currency: 'USD',
    name: 'NEW CALEDONIA'
  },
  {
    code: 'NZ',
    currency: 'USD',
    name: 'NEW ZEALAND'
  },
  {
    code: 'NI',
    currency: 'USD',
    name: 'NICARAGUA'
  },
  {
    code: 'NE',
    currency: 'EUR',
    name: 'NIGER'
  },
  {
    code: 'NG',
    currency: 'EUR',
    name: 'NIGERIA'
  },
  {
    code: 'NU',
    currency: 'USD',
    name: 'NIUE'
  },
  {
    code: 'NF',
    currency: 'USD',
    name: 'NORFOLK ISLAND'
  },
  {
    code: 'NB',
    currency: 'USD',
    name: 'NORTHERN IRELAND'
  },
  {
    code: 'MP',
    currency: 'USD',
    name: 'NORTHERN MARIANA ISLANDS'
  },
  {
    code: 'NO',
    currency: 'NOK',
    name: 'NORWAY'
  },
  {
    code: 'OM',
    currency: 'USD',
    name: 'OMAN'
  },
  {
    code: 'PK',
    currency: 'USD',
    name: 'PAKISTAN'
  },
  {
    code: 'PW',
    currency: 'USD',
    name: 'PALAU'
  },
  {
    code: 'PA',
    currency: 'USD',
    name: 'PANAMA'
  },
  {
    code: 'PG',
    currency: 'USD',
    name: 'PAPUA-NEW GUINEA'
  },
  {
    code: 'PY',
    currency: 'USD',
    name: 'PARAGUAY'
  },
  {
    code: 'PE',
    currency: 'USD',
    name: 'PERU'
  },
  {
    code: 'PN',
    currency: 'USD',
    name: 'PITCAIRN'
  },
  {
    code: 'PL',
    currency: 'EUR',
    name: 'POLAND'
  },
  {
    code: 'PT',
    currency: 'EUR',
    name: 'PORTUGAL'
  },
  {
    code: 'PR',
    currency: 'USD',
    name: 'PUERTO RICO'
  },
  {
    code: 'QA',
    currency: 'USD',
    name: 'QATAR'
  },
  {
    code: 'RE',
    currency: 'EUR',
    name: 'REUNION'
  },
  {
    code: 'RO',
    currency: 'EUR',
    name: 'ROMANIA'
  },
  {
    code: 'RU',
    currency: 'EUR',
    name: 'RUSSIA'
  },
  {
    code: 'RW',
    currency: 'EUR',
    name: 'RWANDA'
  },
  {
    code: 'WS',
    currency: 'USD',
    name: 'SAMOA'
  },
  {
    code: 'SM',
    currency: 'EUR',
    name: 'SAN MARINO'
  },
  {
    code: 'ST',
    currency: 'EUR',
    name: 'SAO TOME PRINCIPE'
  },
  {
    code: 'SA',
    currency: 'USD',
    name: 'SAUDI ARABIA'
  },
  {
    code: 'SF',
    currency: 'USD',
    name: 'SCOTLAND'
  },
  {
    code: 'SN',
    currency: 'EUR',
    name: 'SENEGAL'
  },
  {
    code: 'YU',
    currency: 'USD',
    name: 'SERBIA'
  },
  {
    code: 'SC',
    currency: 'EUR',
    name: 'SEYCHELLES'
  },
  {
    code: 'SL',
    currency: 'EUR',
    name: 'SIERRA LEONE'
  },
  {
    code: 'SG',
    currency: 'USD',
    name: 'SINGAPORE'
  },
  {
    code: 'SK',
    currency: 'EUR',
    name: 'SLOVAK REPUBLIC'
  },
  {
    code: 'SI',
    currency: 'EUR',
    name: 'SLOVENIA'
  },
  {
    code: 'SB',
    currency: 'USD',
    name: 'SOLOMON ISLANDS'
  },
  {
    code: 'SO',
    currency: 'EUR',
    name: 'SOMALIA'
  },
  {
    code: 'ZA',
    currency: 'EUR',
    name: 'SOUTH AFRICA'
  },
  {
    code: 'GS',
    currency: 'EUR',
    name: 'SOUTH GEORGIA & SANDWICH ISLANDS'
  },
  {
    code: 'ES',
    currency: 'EUR',
    name: 'SPAIN'
  },
  {
    code: 'LK',
    currency: 'USD',
    name: 'SRI LANKA'
  },
  {
    code: 'SH',
    currency: 'EUR',
    name: 'ST HELENA'
  },
  {
    code: 'LC',
    currency: 'USD',
    name: 'ST LUCIA'
  },
  {
    code: 'PM',
    currency: 'USD',
    name: 'ST PIERRE MIQUELON'
  },
  {
    code: 'VC',
    currency: 'USD',
    name: 'ST VINCENT/GRENADI'
  },
  {
    code: 'KN',
    currency: 'USD',
    name: 'ST:KITTS-NEVIS'
  },
  {
    code: 'SR',
    currency: 'USD',
    name: 'SURINAM'
  },
  {
    code: 'SJ',
    currency: 'NOK',
    name: 'SVALBARD AND JAN MAYEN ISLANDS'
  },
  {
    code: 'SZ',
    currency: 'EUR',
    name: 'SWAZILAND'
  },
  {
    code: 'SE',
    currency: 'EUR',
    name: 'SWEDEN'
  },
  {
    code: 'CH',
    currency: 'EUR',
    name: 'SWITZERLAND'
  },
  {
    code: 'TJ',
    currency: 'EUR',
    name: 'TADZHIKISTAN'
  },
  {
    code: 'TW',
    currency: 'USD',
    name: 'TAIWAN'
  },
  {
    code: 'TZ',
    currency: 'EUR',
    name: 'TANZANIA'
  },
  {
    code: 'TH',
    currency: 'USD',
    name: 'THAILAND'
  },
  {
    code: 'TP',
    currency: 'USD',
    name: 'TIMOR-LESTE'
  },
  {
    code: 'TG',
    currency: 'EUR',
    name: 'TOGO'
  },
  {
    code: 'TK',
    currency: 'USD',
    name: 'TOKELAU'
  },
  {
    code: 'TO',
    currency: 'USD',
    name: 'TONGA'
  },
  {
    code: 'TT',
    currency: 'USD',
    name: 'TRINIDAD & TOBAGO WEST INDIES'
  },
  {
    code: 'TN',
    currency: 'EUR',
    name: 'TUNISIA'
  },
  {
    code: 'TR',
    currency: 'EUR',
    name: 'TURKEY'
  },
  {
    code: 'TM',
    currency: 'EUR',
    name: 'TURKMENISTAN'
  },
  {
    code: 'TC',
    currency: 'USD',
    name: 'TURKS & CAICOS ISL'
  },
  {
    code: 'TV',
    currency: 'USD',
    name: 'TUVALU'
  },
  {
    code: 'UG',
    currency: 'EUR',
    name: 'UGANDA'
  },
  {
    code: 'UA',
    currency: 'EUR',
    name: 'UKRAINE'
  },
  {
    code: 'AE',
    currency: 'USD',
    name: 'UNITED ARAB EMIRATES'
  },
  {
    code: 'GB',
    currency: 'GBP',
    name: 'UNITED KINGDOM'
  },
  {
    code: 'US',
    currency: 'USD',
    name: 'UNITED STATES'
  },
  {
    code: 'UY',
    currency: 'USD',
    name: 'URUGUAY'
  },
  {
    code: 'UZ',
    currency: 'EUR',
    name: 'UZBEKISTAN'
  },
  {
    code: 'VU',
    currency: 'USD',
    name: 'VANUATU'
  },
  {
    code: 'VA',
    currency: 'EUR',
    name: 'VATICAN CITY STATE'
  },
  {
    code: 'VE',
    currency: 'USD',
    name: 'VENEZUELA'
  },
  {
    code: 'VN',
    currency: 'USD',
    name: 'VIETNAM'
  },
  {
    code: 'VI',
    currency: 'USD',
    name: 'VIRGIN ISLANDS (US)'
  },
  {
    code: 'VG',
    currency: 'USD',
    name: 'VIRGIN ISLANDS GB'
  },
  {
    code: 'WA',
    currency: 'USD',
    name: 'WALES'
  },
  {
    code: 'WF',
    currency: 'USD',
    name: 'WALLIS AND FUTUNA ISLANDS'
  },
  {
    code: 'EH',
    currency: 'EUR',
    name: 'WESTERN SAHARA'
  },
  {
    code: 'YD',
    currency: 'USD',
    name: 'YEMEN'
  },
  {
    code: 'YE',
    currency: 'USD',
    name: 'YEMEN'
  },
  {
    code: 'CD',
    currency: 'EUR',
    name: 'ZAIRE'
  },
  {
    code: 'ZM',
    currency: 'EUR',
    name: 'ZAMBIA'
  },
  {
    code: 'ZW',
    currency: 'EUR',
    name: 'ZIMBABWE'
  }
];
