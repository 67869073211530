export default {
  install(Vue) {
    Vue.prototype.$postMessage = {
      send(type, message = {}) {
        const isExternalView = window.$store.state.isExternalView;
        const clientName = window.$store.state.externalClientName || '';
        const frame = isExternalView ? window.parent : window.top;
        const msgToSend = {
          type,
          client_name: clientName,
          ...message
        };
        frame.postMessage(msgToSend, frame.location.origin);
      },
    };
  }
};
