<!--
  This component is used for users that are not logged in.
  Based on the share recepient email we will decide if user should be sent
  to login page or to registration.
 -->
<script>
import * as routes from '@router/constants';
import LandingPage from './LandingPage';
import { emailSync } from '@mixins';

export default {
  name: 'anon-user',
  components: {
    LandingPage,
  },
  mixins: [emailSync],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    /**
     * Button text depending on whether user exists in the system or not.
     * This relies on emailSync mixin to determine if user exists or not
     * based on the `this.email` value.
     *
     * @return {string} CTA button text
     */
    buttonText() {
      if (this.userExists) {
        return 'Logg deg inn nå';
      }
      return 'Opprett bruker nå';
    },
    /**
     * Query parameters that need to be added to login/register urls.
     * Target points to where user should be redirected to
     * Source is used to differentiate flows for analytics
     *
     * @return {object} Object containing url parameters
     */
    queryParams() {
      let path = window.location.href;
      const separator = path.indexOf('?') !== -1 ? '&' : '?';
      path += separator + 'auth_complete=true';

      return {
        target: path,
        source: 'FAMILY_SHARING',
        username: this.email,
      };
    },
  },
  methods: {
    handleRegister() {
      this.loading = true;
      if (this.userExists) {
        this.$router.push({
          name: routes.LOGIN,
          query: this.queryParams,
        });
      } else {
        this.$router.push({
          name: routes.REGISTRATION,
          query: this.queryParams,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <LandingPage>
    <p>
      <button
        v-text="buttonText"
        @click="handleRegister"
        :disabled="loading"
        type="submit"
        class="btn-standard" />
    </p>
  </LandingPage>
</template>
