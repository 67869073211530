export const REDIRECTED_FROM = 'REDIRECTED_FROM';
export const UPDATE_FORM_DESCRIPTION = 'UPDATE_FORM_DESCRIPTION';
export const UPDATE_SHOW_COMPONENT = 'UPDATE_SHOW_COMPONENT';
export const UPDATE_SHOW_CLOSE_ICON = 'UPDATE_SHOW_CLOSE_ICON';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_SERVER_SIDE_CHECKING = 'UPDATE_SERVER_SIDE_CHECKING';
export const UPDATE_USER_EXISTS_CHECK_ERROR = 'UPDATE_USER_EXISTS_CHECK_ERROR';
export const UPDATE_USER_EXISTS = 'UPDATE_USER_EXISTS';
// prettier-ignore
export const UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER = 'UPDATE_SHOULD_VALIDATE_USER_WITH_SERVER';
export const UPDATE_USER_LOGGED_IN_BEFORE = 'UPDATE_USER_LOGGED_IN_BEFORE';
// prettier-ignore
export const UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER = 'UPDATE_SHOULD_VALIDATE_USER_LOGGED_IN_BEFORE_WITH_SERVER';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_VALID_EMAILS = 'UPDATE_COMPANY_VALID_EMAILS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_SSO_STATUS = 'UPDATE_SSO_STATUS';
export const UPDATE_SSO_META = 'UPDATE_SSO_META';
export const UPDATE_SSO_DATA_FETCHED = 'UPDATE_SSO_DATA_FETCHED';
// prettier-ignore
export const UPDATE_HAS_CONCURRENCY_LIMIT_EXCEEDED = 'UPDATE_HAS_CONCURRENCY_LIMIT_EXCEEDED';
export const UPDATE_CORPORATE_SSO_CONFIG = 'UPDATE_CORPORATE_SSO_CONFIG';
export const UPDATE_CORPORATE_SSO_USER = 'UPDATE_CORPORATE_SSO_USER';

export const UPDATE_SHOW_PUBLICATION_LOGOS = 'UPDATE_SHOW_PUBLICATION_LOGOS';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';

export const UPDATE_FAMILY_SHARING_ID = 'UPDATE_FAMILY_SHARING_ID';
export const UPDATE_INVITE_DETAILS = 'UPDATE_INVITE_DETAILS';

export const UPDATE_AUTOCOMPLETE = 'UPDATE_AUTOCOMPLETE';
