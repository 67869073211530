<template>
  <ValidationObserver
    v-slot="{handleSubmit, invalid}"
    slim>
    <form
      @submit.prevent="handleSubmit(submit)"
      :class="['d-flex', 'flex-column', 'justify-content-between', disabled? 'disabled' : '']"
      method="post">
      <div>
        <div class="prev-username-outer">
          <TextField
            :value="prevUsername"
            :rules="{required: true}"
            :field-name="$t('username.label')"
            :placeholder="$t('username.placeholder')"
            disabled="true"
            name="prevUsername"/>
        </div>
        <div class="choose-username-outer">
          <div class="choose-username">
            <div class="title mb-3">
              {{ $t('choose.username.title') }}
            </div>
            <div class="mb-4">
              {{ $t('choose.username.instruction.1') }}
            </div>
            <div class="mb-4">
              <RadioButton
                v-for="choice in choices"
                :key="choice"
                :value="choice"
                :label="choice"
                v-model="email"/>
            </div>
            <div>
              {{ $t('choose.username.instruction.2') }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Confirmation
          v-if="!success && showConfirmBox"
          :success="success"
          :message="confirmMsg.message"
          :title="confirmMsg.title"
          class="pb-2"/>
        <LoadingButton
          :disabled="invalid || disabled"
          :loading="submitting">
          {{ $t('log.in') }}
        </LoadingButton>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>

import { ValidationObserver } from 'vee-validate';

import LoadingButton from '@root/src/components/elements/LoadingButton.vue';
import Confirmation from '@root/src/components/elements/Confirmation.vue';
import TextField from '@root/src/components/elements/TextField.vue';
import RadioButton from '@root/src/components/elements/RadioButton.vue';

import { ajaxForm, emailSync } from '@mixins';

export default {
  name: 'nhst-login-choose-username',
  components: {
    TextField,
    RadioButton,
    Confirmation,
    LoadingButton,
    ValidationObserver
  },
  mixins: [ajaxForm, emailSync],
  props: {
    prevUsername: {
      type: String,
      default: '',
      required: true
    },
    choices: {
      type: Array,
      default: () => [],
      required: true
    },
  },
  data() {
    return {};
  },
  computed: {
    confirmMsgs: function () {
      return {
        401: {
          title: '',
          message: this.$t('wrong.credential')
        },
        409: {
          title: '',
          message: this.$t('reset.username.409')
        },
      };
    },
  },
  methods: {
    successWorkflow() {
      this.$emit('success', this.email);
    },
    errorWorkflow() {
      console.log(`Failed to reset username => serverResponseCode: ${ this.serverResponseCode }`);
    },
    submit() {
      const app = this;
      return app.submitForm(
        () => app.nhstAuthClient.resetUsername(
          this.prevUsername,
          this.email,
          this.password
        ),
        true
      );
    }
  },
};

</script>
<style lang="scss">
  .choose-username {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    border: 1px solid #76B474;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 10px 7px 10px;

    .title {
      font-weight: 650;
    }
  }
</style>
