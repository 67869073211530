import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

const login = {
  methods: {
    async loginSuccessWorkflow(shouldRedirect = true) {
      this.$postMessage.send(
        EXTERNAL_CONFIGS.EVENTS.LOGIN_SUCCESS
      );
      if (!this.isExternalView && shouldRedirect) {
        window.top.location.href = this.getPreviousPageUrl();
      }
    }
  }
};

export { login };
