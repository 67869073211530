<template>
  <div class="verify-email">
    <NhstValidateCodeAndChangePassword
      :description="description" />
  </div>
</template>

<script>

import { NhstValidateCodeAndChangePassword } from '@components/reset-password';

export default {
  name: 'nhst-verify-username',
  components: { NhstValidateCodeAndChangePassword },
  computed: {
    description() {
      return this.$t(
        'reset.password.validate.code.activate.subscription.description',
        { email: this.username }
      );
    }
  }
};
</script>
