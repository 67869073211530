<template>
  <div class="add-extra-info">
    <ValidationObserver
      v-slot="{handleSubmit}"
      slim>
      <form
        @submit.prevent="handleSubmit(submit)"
        :class="['d-flex', 'flex-column', 'justify-content-between', (submitting || disabled) ? 'disabled' : '']"
        method="post">
        <div>
          <TextField
            :field-name="$t('name')"
            :placeholder="$t('ip.registration.name.placeholder')"
            :disabled="disabled || submitting"
            v-model="user.name"
            :rules="{required: true, max: 40}"/>
          <TextField
            :disabled="disabled || submitting"
            v-model="user.mobile_phone"
            :field-name="$t('mobile.number')"
            :placeholder="$t('mobile.number.placeholder')"
            :rules="{required: true, min:8, max: 20}"/>
          <SimpleMultiSelectField
            :disabled="disabled || submitting"
            v-if="Array.isArray(company.departments) && company.departments.length > 0"
            :placeholder="$t('department')"
            :field-name="$t('department')"
            :rules="{required: true}"
            v-model="user.department"
            :options="company.departments"/>
          <DateOfBirthField
            v-if="company.for_student"
            v-model="user.birth_date"
            :rules="{ required: true, date: true,min_age:16, }"
            :field-name="$t('birth.date')"
            :disabled="disabled || submitting"
            :format="serverAcceptedFormat"/>
          <TextField
            :disabled="disabled || submitting"
            v-if="company.employee_number"
            v-model="user.employee_number"
            :field-name="$t('employee.number')"
            :placeholder="$t('employee.number.placeholder')"
            :rules="{required: true}"/>
          <SimpleMultiSelectField
            :disabled="disabled || submitting"
            v-if="company.for_student"
            :placeholder="$t('register.are.you.student')"
            :rules="{required: true}"
            :field-name="`${$t('graduate.student')}?`"
            v-model="user.senior_student"
            :options="company.senior_students"/>
          <SimpleMultiSelectField
            :disabled="disabled || submitting"
            v-if="company.for_student"
            :placeholder="$t('register.what.is.your.study.field')"
            :field-name="$t('study.field')"
            :rules="{required: true}"
            v-model="user.field_of_study"
            :options="company.study_fields"/>
          <div
            v-if="ipProducts.length > 0"
            class="pb-3">
            <p>{{ $t('register.access.outside.network') }}</p>
            <RadioButtons
              :disabled="disabled || submitting"
              :rules="{required: true}"
              v-model="userIpProduct"
              :choices="ipProducts"/>
          </div>
          <transition
            name="expand"
            mode="out-in">
            <TextField
              :disabled="disabled || submitting"
              v-if="user.ip_plus && company.has_registration_code"
              v-model="user.ip_plus_code"
              :field-name="$t('register.enter.code')"
              :rules="{required: true}"/>
          </transition>
          <CheckboxField
            :disabled="disabled || submitting"
            :field-name="$t('terms.conditions')"
            v-model="user.agreement_accepted"
            :rules="{required: true}"
            :text="ipRegTerms"/>
        </div>
        <div>
          <Confirmation
            v-if="!success && showConfirmBox"
            :success="success"
            :message="confirmMsg.message"
            :title="confirmMsg.title"
            class="pb-2 mb-6"/>
          <LoadingButton
            :disabled="submitting || disabled"
            :loading="submitting"
            button-class="btn-standard mt-0">
            {{ loggedIn ? $t('register.button.text.logged.in') : $t('register.button.text') }}
          </LoadingButton>
          <button
            :disabled="disabled || submitting"
            @click="$emit('back')"
            type="button"
            class="btn-secondary mt-3">
            {{ $t('go.back') }}
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import Confirmation from '@components/elements/Confirmation';
import LoadingButton from '@components/elements/LoadingButton';
import { ajaxForm, corporateIp, formDescriptionHander, userInfo } from '@mixins';
import TextField from '@components/elements/TextField';
import SimpleMultiSelectField from '@components/elements/SimpleMultiSelectField';
import Utils from '@root/src/helpers/utils';
import CheckboxField from '@components/elements/CheckboxField';
import deepmerge from 'deepmerge';
import RadioButtons from '@components/elements/RadioButtons';
import DateOfBirthField from '@elements/DobField';

export default {
  name: 'add-extra-info',
  components: {
    RadioButtons,
    CheckboxField,
    SimpleMultiSelectField,
    TextField,
    ValidationObserver,
    Confirmation,
    LoadingButton,
    DateOfBirthField,
  },
  mixins: [ajaxForm, corporateIp, userInfo, formDescriptionHander],
  data() {
    return {
      dateFormat: Utils.getDefaultDateFormat(),
      serverAcceptedFormat: 'DD-MM-YYYY',
      token: '',
      user: {
        name: '',
        mobile_phone: '',
        department: '',
        ip_plus: false,
        ip_plus_code: '',
        product: '',
        date_of_birth: '',
        employee_number: '',
        senior_student: '',
        field_of_study: '',
        agreement_accepted: false,
      }
    };
  },
  computed: {
    company() {
      return this.$store.state.corporate.company;
    },
    ipProducts() {
      let products = [];
      const company = this.company;
      if (Array.isArray(company.ip_products) && company.ip_products.length > 0) {
        products = company.ip_products;
        products = products.map(product => {
          return {
            ...product,
            value: String(product.value)
          };
        });
      }
      return products;
    },
    // We convert to string as Boolean false causes problems with emiting events
    userIpProduct: {
      get() {
        return String(this.user.ip_plus);
      },
      set(val) {
        this.user.ip_plus = val === 'true';
      }
    },
    ipRegTerms() {
      if (this.company.terms_n_conditions) {
        return this.$t('ip.registration.additional.terms.conditions', { name: this.capitalize(this.company.sponsor) });
      }
      return this.$t('ip.registration.terms.conditions');
    },
  },
  mounted() {
    this.token = this.$route.query.token || '';
  },
  methods: {
    customFormDescriptionHandler() {
      if (isServer) {
        return false;
      }
      this.formHeading = this.$t('ip.registration.page.title');
      this.formSubHeading = '';
      return true;
    },
    successWorkflow() {
      const response = this.serverResponseMessage || {};
      this.$authWebapp.pushAnalyticsEvent(response.analytics_info || {});
      this.$emit('success');
    },
    errorWorkflow() {
    },
    submit() {
      const app = this;
      const data = deepmerge({}, this.user);
      data.username = this.username;
      if (this.token) {
        data.token = app.$route.query.token;
      }
      const slug = this.$route.params.slug || '';
      app.submitForm(() => app.subscriptionClient.registerForIpSubscription(slug, data), true);
    }
  }
};
</script>
