import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import { CDNPlugin, PostMessagePlugin, AuthWebappPlugin } from '@plugins';

import { createClientStore } from '@store';
import { createRouter } from '@router';
import App from './App.vue';
import './configs/vee-validate';
import './configs/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@assets/scss/pub.scss';
import { sync } from 'vuex-router-sync';
import Inputmask from 'inputmask'; // js package for input masking : https://github.com/RobinHerbots/Inputmask
import {
  DnSubscriptionClient,
  SubscriptionClient,
  NhstAuthClient,
  SubAuthClient,
  ZephrPublicClient,
  AuthServices
} from '@nhst/nhst-sub-auth-services';
import { general, sso } from '@mixins';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.mixin(general);
Vue.mixin(sso);

// eslint-disable-next-line
__webpack_public_path__ = window.publicPath;

Vue.use(VueCookies);
Vue.use(CDNPlugin, {
  CDN: dconf.CDN,
  assetsManifest: global.assetsManifest,
});
Vue.use(PostMessagePlugin);
Vue.use(AuthWebappPlugin);
Vue.use(VueI18n);
Vue.config.errorHandler = (err, vm, info) => {
  console.log(vm);
  console.log(err);
  console.log(info);
};
Vue.config.warnHandler = (msg, vm, trace) => {
  console.log(vm);
  console.log(msg);
  console.log(trace);
};

window.Inputmask = Inputmask;
const _attachVariables = (store) => {
  const clientConfig = {
    headers: {
      'X-Publication': store.state.publication.name
    }
  };
  const subscriptionClient = new SubscriptionClient(clientConfig);
  const nhstAuthClient = new NhstAuthClient(clientConfig);
  const nhstAuthClientIdDomain = new NhstAuthClient({ baseUrl: '/auth/', BASE_URL_PREFIX: 'https://id.nhst-test.tech', ...clientConfig });
  const zephrPublicClient = new ZephrPublicClient(clientConfig);
  const subAuthClient = new SubAuthClient({ baseUrl: `${dconf.BASE_PATH}/api/`, ...clientConfig });
  const authServices = new AuthServices({ nhstAuthClientConfig: clientConfig });
  const dnSubscriptionClient = new DnSubscriptionClient(clientConfig);

  window.$store = store;
  window.dnSubscriptionClient = dnSubscriptionClient;
  window.globalSubscriptionClient = subscriptionClient;
  if (window.$store.state.publication.name === 'dn') {
    window.subscriptionClient = dnSubscriptionClient;
  } else {
    window.subscriptionClient = subscriptionClient;
  }

  window.nhstAuthClient = nhstAuthClient;
  window.nhstAuthClientIdDomain = nhstAuthClientIdDomain;
  window.subAuthClient = subAuthClient;
  window.zephrPublicClient = zephrPublicClient;
  window.authServices = authServices;
};

export function createApp() {
  const store = createClientStore();
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }
  _attachVariables(store);
  const router = createRouter();
  sync(store, router);
  const app = new Vue({
    router,
    store,
    i18n: new VueI18n(store.state.langConfig),
    render: h => h(App)
  });
  window.$vue = app;
  return { app, router, store };
}
