import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

const workFlowHelper = {
  data() {
    return {
      curComponent: undefined,
      steps: [],
      curStep: 0,
      pushAnalyticsEvents: false,
      componentVerbalName: '',
    };
  },
  created() {
    this.curComponent = this.steps[0];
    this.doForwardWorkflow();
  },
  watch: {
    curComponent: {
      immediate: true,
      handler() {
        const app = this;
        app.pushComponentChangeEventToAnalytics();
        if (!isServer) {
          app.$nextTick(() => {
            app.scrollToComponent();
          });
        }
      },

    },
  },
  methods: {
    analyticsEventHandler() {
    },
    pushComponentChangeEventToAnalytics(ref) {
      const app = ref || this;
      if (isServer) {
        return;
      }
      if (!app.curComponent) return;
      app.analyticsEventHandler();
    },
    goForward() {
      if (this.curStep + 1 >= this.steps.length) {
        return;
      }
      this.curStep += 1;
      this.curComponent = this.steps[this.curStep];
      this.doForwardWorkflow();
    },
    goBack() {
      this.curStep -= 1;
      if (this.curStep < 0) {
        if (!this.isExternalView) {
          // If it is the first page/first landing page of this app, then name is null
          if (this.$authWebapp.hasComeBackFromAnotherPageInThisApp()) {
            this.redirectedFrom = {};
            this.$authWebapp.goBack(-1);
          } else {
            window.location.href = this.previousPageUrl;
          }
        } else {
          this.$postMessage.send(EXTERNAL_CONFIGS.EVENTS.GO_BACK);
        }
        this.curStep = 0;
      } else {
        this.curComponent = this.steps[this.curStep];
        this.doBackWorkflow();
      }
    },
    scrollToComponent() {
      const elementToScrollInto = document.querySelector('.auth') || this.$el;
      window.scrollTo({ top: elementToScrollInto.offsetTop, behavior: 'smooth' });
    },
    doForwardWorkflow() {

    },
    doBackWorkflow() {
    }
  },
};

export { workFlowHelper };
