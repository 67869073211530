<script>
import LandingPage from './LandingPage';
import { familySharing, adobeAnalytics } from '@mixins';

export default {
  name: 'logged-in-user',
  components: {
    LandingPage,
  },
  mixins: [familySharing, adobeAnalytics],
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    acceptProductShare: async function () {
      const app = this;
      app.submitting = true;
      app
        .acceptShareInvite()
        .then(() => {
          app.pushAdobeEvent({
            event: 'MY_PAGE',
            eventName: 'Auth Webapp Product Share | Invite accepted',
            eventInfo: {
              context1: 'existing',
              context2: 'Already logged in',
            },
          });
          setTimeout(() => {
            window.top.location.href = app.fsOnboardingUrl;
          }, 1000);
        })
        .catch((e) => {
          console.error('Error accepting share invite for logged in user', e);
          alert('Error accepting invite');
        })
        .finally(() => {
          app.submitting = false;
        });
    },
  },
};
</script>

<template>
  <LandingPage>
    <template #text>
      <p>
        Å komme i gang er ikke vanskelig. Du trenger kun å aktivere tilgangen
        din
      </p>
    </template>
    <template #default>
      <p>
        <button
          @click="acceptProductShare"
          :disabled="submitting"
          type="submit"
          class="btn-standard">
          Opprett tilgang nå
        </button>
      </p>
    </template>
  </LandingPage>
</template>
