const notLoggedIn = {
  created() {
    if (isServer) {
      // do nothing
      // we should not have any user specific (including logged in/non logged in) logic on server side
      // as we want to cache pages. Those logics should be handled on client side
    } else if (this.isLoggedIn()) {
      window.location.href = this.getRedirectLocation();
    }
  },
  methods: {
    getRedirectLocation() {
      throw Error('You need to implement this yourself!');
    },
    getScript() {
      // add script in server side. This will be executed in client side.
      // dont use cookies to take
      return `<script>
const cookies = document.cookie;
const oneid_info = document.cookie.match(/(^| )oneid_info=(?<oneid_info>[^;]+);?/);
if (oneid_info && oneid_info.groups.oneid_info) {
  window.location.href = "${ this.getRedirectLocation() }";
}
</script>
`;
    }
  }
};

export { notLoggedIn };
