import { UPDATE_COMPANY, UPDATE_COMPANY_VALID_EMAILS } from '@store/mutation-types';

const mutations = {
  [UPDATE_COMPANY](state, company) {
    state.company = company;
  },
  [UPDATE_COMPANY_VALID_EMAILS](state, validEmails) {
    state.validEmails = validEmails || [];
  }
};

const actions = {};

const store = {
  namespaced: true,
  // publication and product of the company
  state: {
    company: {

    },
    validEmails: []
  },
  mutations,
  actions,
};

export { store as corporateStore };
