<template>
  <div
    id="app"
    class="app-container">
    <Loader v-if="shouldShowLoader"/>
    <HeaderBar v-if="showHeaderAndFooter"/>
    <router-view></router-view>
    <FooterBar v-if="showHeaderAndFooter"/>
  </div>
</template>
<script>
import Loader from '@root/src/components/elements/Loader';
import HeaderBar from './HeaderBar.vue';
import FooterBar from './FooterBar.vue';
import { RETRIEVE_SSO_STATUS, UPDATE_CORPORATE_SSO_CONFIG } from '@store';
import { localEnvSetup } from '@mixins';
import { EXTERNAL_CONFIGS } from '@root/src/helpers/ExternalConfigs';

export default {
  name: 'app',
  components: {
    Loader,
    HeaderBar,
    FooterBar,
  },
  mixins: [localEnvSetup],
  computed: {
    showHeaderAndFooter() {
      return !this.isMobileView && !this.isPopupView && !this.isExternalView;
    },
    shouldShowLoader() {
      return this.serverSideChecking;
    }
  },
  watch: {
    '$route'(to, from) {
      function hasQueryParams(route) {
        return !!Object.keys(route.query).length;
      }

      if (!hasQueryParams(to) && hasQueryParams(from)) {
        this.$router.replace({
          ...to,
          query: from.query
        });
      }
    },
  },
  async created() {
    if (!isServer) {
      this.$store.dispatch(`sso/${RETRIEVE_SSO_STATUS}`);
    } else {
      this.showLoader();
    }
  },
  async mounted() {
    this.addResizeObserverOnView();
    document.body.classList.remove('hide-component');
    this.hideLoader();
    await this.fetchCorporateSsoConfig();
  },
  methods: {
    async fetchCorporateSsoConfig() {
      let configResponse = await this.authServices.getCorporateSsoConfig();
      configResponse = configResponse.data || {};
      this.$store.commit(`nhstAuth/${UPDATE_CORPORATE_SSO_CONFIG}`, configResponse);
    },
    addResizeObserverOnView() {
      if (!this.isExternalView) {
        return;
      }
      const observer = new ResizeObserver(entries => {
        console.log(entries);
        for (const entry of entries) {
          const height = Math.ceil(entry.contentRect.height);
          const width = Math.ceil(entry.contentRect.width);
          console.log(`[NHST Auth Size] Width: ${width}, Height: ${height}`);
          this.$postMessage.send(EXTERNAL_CONFIGS.EVENTS.SIZE, { width, height });
        }
      });
      observer.observe(document.querySelector('#app'));
    }
  },
};
</script>
