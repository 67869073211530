import {
  REDIRECTED_FROM,
  UPDATE_SHOW_COMPONENT,
  UPDATE_FORM_DESCRIPTION,
  UPDATE_SHOW_CLOSE_ICON,
  UPDATE_SERVER_SIDE_CHECKING,
  UPDATE_SHOW_PUBLICATION_LOGOS,
  UPDATE_CURRENT_STEP,
  UPDATE_AUTOCOMPLETE,
} from '@store';

export default {
  [REDIRECTED_FROM](state, val) {
    state.redirectedFrom = val;
  },
  [UPDATE_CURRENT_STEP](state, val) {
    state.currentStep = val;
  },
  [UPDATE_FORM_DESCRIPTION](state, val) {
    state.formDescription = val || {};
  },
  [UPDATE_SHOW_COMPONENT](state, val) {
    state.showComponent = val;
  },
  [UPDATE_SHOW_CLOSE_ICON](state, val) {
    state.showCloseIcon = val;
  },
  [UPDATE_SERVER_SIDE_CHECKING](state, val) {
    const shouldAdd = val.shouldAdd || false;
    const fieldName = val.fieldName;
    if (shouldAdd) {
      state.serverSideCheckingFields.push(fieldName);
    } else {
      const fields = state.serverSideCheckingFields.filter(
        (f) => f === fieldName
      );
      state.serverSideCheckingFields = state.serverSideCheckingFields.filter(
        (f) => f !== fieldName
      );
      // remove only one field
      state.serverSideCheckingFields = [
        ...state.serverSideCheckingFields,
        ...fields.splice(0, fields.length - 1),
      ];
    }
  },
  [UPDATE_SHOW_PUBLICATION_LOGOS](state, val) {
    if (isServer) {
      return;
    }
    state.showPublicationLogos += val;
    state.showPublicationLogos = Math.max(state.showPublicationLogos, 0);
  },
  [UPDATE_AUTOCOMPLETE](state, val) {
    state.autocomplete = val;
  },
};
