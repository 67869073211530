<template>
  <div class="nhst-login">
    <transition
      name="expand"
      mode="out-in">
      <NhstLoginStart
        :ref="NHST_LOGIN_START"
        v-if="curStep === NHST_LOGIN_START"
        :redirected-from="redirectedFrom"
        @success="onSuccess"
        @chooseUsername="onChooseUsername"
        @enterNewUsername="onEnterNewUsername"
        @chooseUsernameOrEnterNew="onChooseUsernameOrEnterNew"
        class="component"/>
    </transition>
    <transition
      name="expand"
      mode="out-in">
      <NhstLoginChooseUsername
        ref="NHST_LOGIN_CHOOSE_USERNAME"
        v-if="curStep === NHST_LOGIN_CHOOSE_USERNAME"
        :choices="choices"
        :prev-username="prevUsername"
        @success="onSuccess"
        class="component"/>
    </transition>
    <transition
      name="expand"
      mode="out-in">
      <NhstLoginEnterNewUsername
        ref="NHST_LOGIN_ENTER_NEW_USERNAME"
        v-if="curStep === NHST_LOGIN_ENTER_NEW_USERNAME"
        :prev-username="prevUsername"
        @success="onSuccess"
        class="component"/>
    </transition>
    <transition
      name="expand"
      mode="out-in">
      <NhstLoginChooseUsernameOrEnterNew
        ref="NHST_LOGIN_CHOOSE_USERNAME_OR_ENTER_NEW"
        v-if="curStep === NHST_LOGIN_CHOOSE_USERNAME_OR_ENTER_NEW"
        :choices="choices"
        :prev-username="prevUsername"
        @success="onSuccess"
        class="component"/>
    </transition>
  </div>
</template>

<script>

import NhstLoginStart from '@components/login/NhstLoginStart';
import NhstLoginChooseUsername from '@components/login/NhstLoginChooseUsername';
import NhstLoginEnterNewUsername from '@components/login/NhstLoginEnterNewUsername';
import NhstLoginChooseUsernameOrEnterNew from '@components/login/NhstLoginChooseUsernameOrEnterNew';
import { formDescriptionHander } from '@mixins';

export default {
  name: 'nhst-login',
  components: {
    NhstLoginStart,
    NhstLoginChooseUsername,
    NhstLoginEnterNewUsername,
    NhstLoginChooseUsernameOrEnterNew
  },
  mixins: [formDescriptionHander],
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    subTitle: {
      type: String,
      default: '',
      required: false
    },
    autoLogin: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data() {
    const NHST_LOGIN_START = 'NHST_LOGIN_START';
    const NHST_LOGIN_CHOOSE_USERNAME = 'NHST_LOGIN_CHOOSE_USERNAME';
    const NHST_LOGIN_ENTER_NEW_USERNAME = 'NHST_LOGIN_ENTER_NEW_USERNAME';
    const NHST_LOGIN_CHOOSE_USERNAME_OR_ENTER_NEW = 'NHST_LOGIN_CHOOSE_USERNAME_OR_ENTER_NEW';
    return {
      NHST_LOGIN_START,
      NHST_LOGIN_CHOOSE_USERNAME,
      NHST_LOGIN_ENTER_NEW_USERNAME,
      NHST_LOGIN_CHOOSE_USERNAME_OR_ENTER_NEW,
      curStep: NHST_LOGIN_START,
      choices: [],
      prevUsername: '',
      alwaysShowCurrentComponent: true,
    };
  },
  async mounted() {
    if (this.autoLogin) {
      await this.$refs[this.NHST_LOGIN_START].submit();
    }
  },
  methods: {
    customFormDescriptionHandler() {
      this.formHeading = this.title;
      this.formSubHeading = this.subTitle;
    },
    async onSuccess(username) {
      const app = this;
      this.curStep = this.NHST_LOGIN_START;
      this.$nextTick(() => app.$refs[this.NHST_LOGIN_START].submit());
    },
    onChooseUsername(username, choices = []) {
      this.title = '';
      this.choices = choices;
      this.prevUsername = username;
      this.curStep = this.NHST_LOGIN_CHOOSE_USERNAME;
    },
    onEnterNewUsername(username) {
      this.title = '';
      this.prevUsername = username;
      this.curStep = this.NHST_LOGIN_ENTER_NEW_USERNAME;
    },
    onChooseUsernameOrEnterNew(username, choices = []) {
      this.title = '';
      this.choices = choices;
      this.prevUsername = username;
      this.curStep = this.NHST_LOGIN_CHOOSE_USERNAME_OR_ENTER_NEW;
    },
  }
};
</script>
