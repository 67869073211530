<template>
  <div class="nhst-ip-registration">
    <transition name="expand" mode="out-in">
      <component
        :is="curComponent"
        :title="title"
        :description="description"
        :is-success="isSuccess"
        @success="goForward"
        @back="goBack"/>
    </transition>
  </div>
</template>

<script>
import { ajaxForm, corporateIp, workFlowHelper } from '@mixins';
import VerifyCorporateUsername from '@components/ip-registration/VerifyCorporateUsername';
import AddExtraInfo from '@components/ip-registration/AddExtraInfo';
import ConfirmationPage from '@components/ip-registration/ConfirmationPage';
import { NhstValidateCodeAndChangePassword } from '@components';
import { UPDATE_COMPANY, UPDATE_COMPANY_VALID_EMAILS } from '@store';

export default {
  name: 'nhst-ip-registration',
  // will use component dynamically
  // eslint-disable-next-line vue/no-unused-components
  components: { VerifyCorporateUsername, AddExtraInfo, NhstValidateCodeAndChangePassword, ConfirmationPage },
  mixins: [workFlowHelper, ajaxForm, corporateIp],
  data() {
    return {
      verifyCorporateUsername: VerifyCorporateUsername,
      addExtraInfo: AddExtraInfo,
      nhstValidateCodeAndChangePassword: NhstValidateCodeAndChangePassword,
      confirmationPage: ConfirmationPage,
      isSuccess: false,
      steps: [
        VerifyCorporateUsername,
        AddExtraInfo,
        NhstValidateCodeAndChangePassword
      ],
      title: '',
      description: '',
      token: '',
      companyFound: false,
      tokenValid: false,
    };
  },
  computed: {
    confirmationPageConfig() {
      if (this.isSuccess) {
        return {
          title: this.$t('ip.registration.success.title'),
          message: this.$t('ip.registration.success.description')
        };
      }
      return this.confirmMsg;
    }
  },
  watch: {
    tokenValid: async function (newVal) {
      if (newVal && this.isMounted) {
        return await this.getIpCompany();
      }
    }
  },
  async mounted() {
    const app = this;
    app.token = app.$route.query.token || '';
    if (app.loggedIn) {
      app.steps.pop();
      app.steps.push(app.confirmationPage);
    }
    await app.validateToken();
  },
  methods: {
    async getIpCompany() {
      const app = this;
      const slug = app.$route.params.slug || '';
      app.showLoader();
      return await app.submitFormWithHandlers(
        () => app.subscriptionClient.getIpCompany(slug),
        false,
        () => app.ipCompanyFoundWorkflow(),
        () => app.ipCompanyNotFoundWorkflow()
      );
    },
    async validateToken() {
      const app = this;
      if (!app.token) {
        app.tokenValid = true;
        return;
      }
      app.showLoader();
      return await app.submitFormWithHandlers(
        () => app.subscriptionClient.validateIpLoginToken(app.token),
        false,
        () => app.ipTokenValidWorkflow(),
        () => app.ipTokenInvalidWorkflow()
      );
    },
    ipCompanyFoundWorkflow() {
      this.$store.commit(
        `corporate/${UPDATE_COMPANY}`,
        this.serverResponseMessage
      );
      document.title = this.capitalize(
        this.$store.state.corporate.company.registration_screen_title || ''
      );
      this.hideLoader();
      this.companyFound = true;
    },
    ipCompanyNotFoundWorkflow() {
      this.hideLoader();
      this.curComponent = this.confirmationPage;
      this.success = false;
      this.isSuccess = false;
      this.title = this.confirmationPageConfig.title;
      this.description = this.confirmationPageConfig.message;
    },
    ipTokenValidWorkflow() {
      this.tokenValid = true;
      this.$store.commit(
        `corporate/${UPDATE_COMPANY_VALID_EMAILS}`,
        this.serverResponseMessage.email_list
      );
      this.hideLoader();
    },
    ipTokenInvalidWorkflow() {
      this.hideLoader();
      this.tokenValid = false;
      this.success = false;
      this.isSuccess = false;
      this.curComponent = this.confirmationPage;
      this.title = this.$t('ip.registration.token.invalid.title');
      this.description = this.$t('ip.registration.token.invalid.description');
    },
    doForwardWorkflow() {
      if (this.curComponent === this.confirmationPage) {
        if (this.loggedIn) {
          this.isSuccess = true;
          this.title = this.confirmationPageConfig.title;
          this.description = this.confirmationPageConfig.message;
        }
      } else if (this.curComponent === this.nhstValidateCodeAndChangePassword) {
        this.title = this.$t('reset.password.validate.code.ip.user.title');
        this.description = this.$t(
          'reset.password.validate.code.ip.user.description',
          { email: this.username }
        );
      }
    }
  }
};
</script>

<style scoped></style>
