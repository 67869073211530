import { UPDATE_USER } from '@store/mutation-types';
import { FETCH_USER } from '@store/action-types';

const mutations = {
  [UPDATE_USER](state, user) {
    state.data = user;
  },
};

const actions = {
  async [FETCH_USER]({ commit }) {
    try {
      let user = await window.subscriptionClient.fetchUser();
      user = user.data;
      commit(UPDATE_USER, user);
    } catch (e) {
      console.error(`[Failed to Fetch User]`, e);
      commit(UPDATE_USER, {});
    }
  }
};

const store = {
  namespaced: true,
  // publication and product of the company
  state: {
    data: {},
  },
  mutations,
  actions,
};

export { store as userStore };
