import * as routes from '@router/constants';
import deepmerge from 'deepmerge';
import { login } from '@root/src/mixins/Login';

const autoLogin = {
  mixins: [login],
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    async autoLogin(shouldRedirect = true, shouldCallRefreshUser = false) {
      const app = this;
      try {
        app.submitting = true;
        await app.authServices.login(app.username, app.password, app.publication.name);
        if (shouldCallRefreshUser) {
          await app.authServices.refreshUser();
        }
        app.loginSuccessWorkflow(shouldRedirect);
        app.submitting = false;
        return true;
      } catch (e) {
        console.error('Error in autologin', e);
      }
      app.submitting = false;
      const newRouteState = {
        name: routes.LOGIN,
        params: {
          autoLogin: true
        }
      };
      app.$router.push(deepmerge(app.route, newRouteState));
      return false;
    },
  }
};

export { autoLogin };
